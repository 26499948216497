import React, { useState, useEffect, useContext } from "react";
import { useHistory } from "react-router-dom";
import Container from "@material-ui/core/Container";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import { ImageContainer } from "../shared/ImageContainer";
import { CurrencyValue } from "../shared/TextMask";
import Button from "@material-ui/core/Button";
import { NavLink as RouterLink } from "react-router-dom";
import Link from "@material-ui/core/Link";
import AddIcon from "@material-ui/icons/Add";
import VehicleController from "./VehicleController";
import { DealerContext } from "../shared/contexts/DealerContext";
import { MakeContext } from "../shared/contexts/MakeContext";
import { width } from "@material-ui/system";

import { FinanceContext } from "../shared/contexts/FinanceContext";

const useStyles = makeStyles((theme) => ({
  content: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(10, 0, 6),
    "@media (max-width: 1024px)": {
      padding: theme.spacing(6, 0, 6),
    },
  },
  testParam: {
    backgroundColor: ({ primaryColor }) => `${primaryColor} !important`,
  },
  dividerTitle: {
    backgroundColor: ({ secondaryColor }) => `${secondaryColor} !important`,
    width: "33%",
    marginBottom: "30px",
    height: "3px",
    margin: "20px auto",
  },
  bold: {
    fontWeight: "100",
  },
  title: {
    fontSize: "1.5rem",
  },
  vehicleContainer: {
    "& h5, & p": {
      color: "#7c7c7c",
    },
    "&:hover img, &:hover p, &:hover h5": {
      color: "#1f2532",
      cursor: "pointer",
      textDecoration: "none",
    },
  },
  btn: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    "& :hover": {
      backgroundColor: ({ primaryColor }) => `${primaryColor} !important`,
    },
  },
  image: {
    "& > div > div": {
      backgroundColor: "#ffffff !important",
    },
  },
  Carimage: {
    maxWidth: "350px",
    maxHeight: "350px",
    width: "350px",
    height: "350px",
    display: "block",
    margin: "0 auto",
    cursor: "pointer",
    "@media (max-width: 780px)": {
      flexDirection: "100%",
    },
  },
  card: {
    textAlign: "center",
    fontWeight: "bold",
    cursor: "pointer",
    "&:hover ": {
      boxShadow:
        "0px 16px 16px rgba(33, 61, 143, 0.05), 0px 8px 8px rgba(33, 61, 143, 0.05), 0px 2px 2px rgba(33, 61, 143, 0.05), 0px 4px 4px rgba(33, 61, 143, 0.04)",
    },
  },
  boxHolder: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  flexMobile: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexWrap: "inherit",
  },

  modelHolder: {
    textAlign: "left",
    background: "white",
    borderRadius: "10px",
    maxWidth: "32%",
    margin: "0.5%",
    padding: "20px",
    boxShadow:
      "0px 16px 16px rgba(33, 61, 143, 0.05), 0px 8px 8px rgba(33, 61, 143, 0.05), 0px 2px 2px rgba(33, 61, 143, 0.05), 0px 4px 4px rgba(33, 61, 143, 0.04)",
    "@media (max-width: 426px)": {
      width: "80%",
      maxWidth: "unset",
    },
  },
  imgHolder: {
    background: "white",
    padding: "10px",
    borderRadius: "4px",
  },
}));

const OurVehicles = () => {
  const { make, setActiveGlobalMake, makeColors } = useContext(MakeContext);
  let [colors, setColors] = useState();
  let classes = useStyles({ ...makeColors });

  const OUR_VEHICLES_PAGE_PATH = "/our-vehicles";
  const { setGlobalModel } = useContext(FinanceContext);
  const { loading, error, vehicles, total } = VehicleController();
  const [groupedMakes, setGroupedMakes] = useState();
  const [makeLogo, setMakeLogo] = useState();
  const [models, setModels] = useState([]);
  const { globalDealer } = useContext(DealerContext);
  const history = useHistory();
  const [activeDealer, setActiveDealer] = useState("");

  useEffect(() => {
    let makes = groupBy(
      vehicles?.filter((itm) => findMake(itm.make)),
      "make"
    );

    setGroupedMakes(makes);
  }, [vehicles, globalDealer]);

  // this is causing a error useEffect(() => {
  //     if (window.location.pathname === OUR_VEHICLES_PAGE_PATH) {
  //         setActiveGlobalMake(null);
  //     } else if (make != null) {
  //         console.log('arived1')
  //         window.location.href = '/make/' + make?.name.toLowerCase() + '/our-vehicles';
  //     }
  // }, [])

  const handleMakeClick = (make, makeName) => {
    setActiveGlobalMake(make);
  };

  const handleMakeLoad = (make, makeName) => {
    if (Object.keys(groupedMakes).length < 2) {
      setActiveGlobalMake(make);
    }
  };

  const findMake = (make) => {
    if (!globalDealer.makes) {
      return;
    }

    let foundMakes = globalDealer.makes.filter(
      (itm) => itm.name.toLowerCase() == make.toLowerCase()
    );

    return foundMakes.length > 0;
  };

  const cleanModel = (vehicle) => {
    const url = `models/${vehicle?.model
      .toLowerCase()
      .replace(/\//g, "-")
      .replace(/ /g, "-")}/${vehicle.id}`;
    return url;
  };

  const groupBy = (xs, key) => {
    return xs?.reduce(function (rv, x) {
      (rv[x[key]] = rv[x[key]] || [])?.push(x);
      return rv;
    }, {});
  };

  const gotoPage = (make) => {
    window.location.href = "/make/" + make.toLowerCase() + "/our-vehicles";
  };
  const getActiveMake = () => {
    let logo = JSON.parse(localStorage.getItem("activeMake"));
    if (logo !== null) {
      setMakeLogo(logo.logo);
    }
  };

  useEffect(() => {
    setGlobalModel({});
  }, []);
  return (
    <Container maxWidth="lg">
      <div className={classes.content}>
        {!make && (
          <>
            <Box mb={2}>
              <Typography
                gutterBottom
                variant="h5"
                align="center"
                color="textPrimary"
                fontWeight="500"
              >
                NEW VEHICLES
              </Typography>
            </Box>
            <Divider className={classes.dividerTitle} />
          </>
        )}
        {make && (
          <>
            <Grid item xs={12} sm={6} md={12}>
              <Box mb={2}>
                <Typography
                  gutterBottom
                  variant="h5"
                  align="center"
                  color="textPrimary"
                  fontWeight="500"
                >
                  <img src={makeLogo} width="10%" />
                </Typography>
                <Typography
                  gutterBottom
                  variant="h5"
                  align="center"
                  color="textPrimary"
                  fontWeight="500"
                >
                  NEW MODELS
                </Typography>
              </Box>
            </Grid>
          </>
        )}
        <Grid
          container
          spacing={2}
          style={{ display: "flex", justifyContent: "center" }}
        >
          {!make && (
            <>
              {groupedMakes &&
                Object.keys(groupedMakes)
                  .sort()
                  .map((makeName, index) => {
                    let models = groupedMakes[makeName];
                    let tmpMake = globalDealer?.makes?.find((m) => {
                      return m?.name?.toLowerCase() === makeName?.toLowerCase();
                    });

                    return (
                      <>
                        <Grid
                          item
                          xs={12}
                          sm={6}
                          md={3}
                          key={index}
                          className={classes.card}
                          onClick={() => {
                            handleMakeClick(tmpMake, makeName);
                          }}
                        >
                          {tmpMake?.logo && (
                            <Box
                              className={classes.boxHolder}
                              style={{
                                backgroundImage: `url(${tmpMake.heroImage})`,
                                backgroundPosition: "center",
                                backgroundSize: "100% ",
                                backgroundBlendMode: "overlay",
                                backgroundColor: "#00000075",
                                height: "250px",
                                backgroundRepeat: "no-repeat",
                              }}
                            >
                              <span className={classes.imgHolder}>
                                <img width="100px" src={tmpMake?.logo} />
                              </span>{" "}
                            </Box>
                          )}

                          <p>
                            {models.length} models from{" "}
                            <span style={{ textTransform: "capitalize" }}>
                              {makeName.toLowerCase()}
                            </span>
                          </p>
                        </Grid>
                      </>
                    );
                  })}
            </>
          )}

          {make && (
            <div className={classes.flexMobile}>
              {groupedMakes &&
                groupedMakes[make.name] &&
                groupedMakes[make.name].map((vehicle, index) => {
                  return (
                    <Grid
                      item
                      spacing={2}
                      xs={12}
                      sm={6}
                      md={4}
                      key={index}
                      className={classes.modelHolder}
                      onClick={() =>
                        history.push(`${cleanModel(vehicle)}`, {
                          ...vehicle,
                          leadTypeId: 1,
                        })
                      }
                    >
                      <Box className={classes.vehicleContainer}>
                        <Box className={classes.image}>
                          <ImageContainer
                            aspectRatio={16 / 8}
                            height="100%"
                            src={vehicle.image}
                            alt={vehicle.title}
                          />
                        </Box>
                        <Box></Box>
                        <Box
                          mt={2}
                          mb={0}
                          textAlign="left"
                          component="h5"
                          className={`${classes.title} ${classes.bold}`}
                        >
                          {vehicle.title}
                        </Box>
                        <Box
                          mt={0}
                          mb={0}
                          textAlign="center"
                          component="h5"
                          className={`${classes.title} ${classes.bold}`}
                        >
                          {vehicle?.price > 0 ? (
                            <>
                              <Box component="span">From</Box>{" "}
                              <CurrencyValue value={vehicle.price} />
                            
                            </>
                          ) : (
                            <>{"POA"}</>
                          )}
                        </Box>

                        <Box mb={3} className={classes.btn}>
                          <Button
                            className={classes.testParam}
                            variant="contained"
                            gutterBottom
                            color="primary"
                            endIcon={<AddIcon />}
                          >
                            Explore
                          </Button>
                        </Box>
                      </Box>
                    </Grid>
                  );
                })}
            </div>
          )}
        </Grid>
      </div>
    </Container>
  );
};

export default OurVehicles;
