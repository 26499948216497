import React, { useState, useCallback, useRef } from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import { CurrencyValue } from '../shared/TextMask'
import { QUALIFYING_FINANCE_PERCENTAGE } from '../shared/Constants'
import Link from '@material-ui/core/Link';
import ContactComponent from '../shared/ContactComponent'
import PQContainer from '../shared/PQContainer'

const useStyles = makeStyles((theme) => ({
    card: {
        backgroundColor: '#f0f3f5',
        padding: '1.5rem 1rem !important',
        border: '1px solid #9eb3c1',
        margin: '.5rem 0'
    }
}));



export default function FinanceOptionsComponent({ model, financeOptions }) {
    const classes = useStyles();
    const [contactMe, setContactMe] = useState(false)
    const [cashBuyer, setCashBuyer] = useState(false)
    const [finance, setFinance] = useState(false)

    return (
        <Grid container>
            <Grid item container spacing={2}
                className={classes.card} xs={12}>
                <Grid item xs={12} md={3}>
                    <Typography style={{ textTransform: 'uppercase' }} variant="h5" color="textPrimary">
                        Enquire
              </Typography>
                </Grid>
                <Grid item xs={12} md={6}>
                    <Typography align='center' color="textPrimary">
                        Not ready to buy and you need more information? <br />
                We are here for you and we will assist you in choosing the perfect <strong>New {model ? model : ''}</strong>
                    </Typography>
                </Grid>
                <Grid item xs={12} md={3}>
                    <Box display='flex' justifyContent='center'>
                        <Button fullWidth variant="contained" color="primary" onClick={() => {
                            setContactMe(true);
                        }
                        }>
                            Contact Me
                </Button>
                    </Box>
                </Grid>
                {contactMe && <ContactComponent variantId={financeOptions[0].variantId} />}
            </Grid>


            <Grid item container spacing={2}
                className={classes.card} xs={12}>
                <Grid item xs={12} md={3}>
                    <Typography style={{ textTransform: 'uppercase' }} variant="h5" color="textPrimary">
                        Cash Buyer
            </Typography>
                </Grid>
                <Grid item xs={12} md={6}>
                    <Typography align='center' color="textPrimary">
                        Not interested in finance? You can buy your <strong>New {model ? model : ''}</strong> cash.<br />
              Simply leave your details and our qualified agents will contact you.
            </Typography>
                </Grid>
                <Grid item xs={12} md={3}>
                    <Box display='flex' justifyContent='center'>
                        <Button fullWidth variant="contained" color="primary" onClick={() => {
                            setCashBuyer(true);
                        }}>
                            Order Now
              </Button>
                    </Box>
                </Grid>
                {cashBuyer && <ContactComponent isCashBuyer={true} variantId={financeOptions[0].variantId} />}
            </Grid>

            {
                (financeOptions && financeOptions.length > 0) &&
                financeOptions.map((option) => {
                    let salaryEstimate = Math.floor((option.monthlyInstallment / QUALIFYING_FINANCE_PERCENTAGE) / 100) * 100

                    return (
                        <Grid item spacing={2} container key={option.key} className={classes.card} xs={12}>
                            <Grid item xs={12} md={3}>
                                <Typography style={{ textTransform: 'uppercase' }} variant="h5" color="textPrimary">
                                    Do you qualify
                  </Typography>
                                <Typography style={{ textTransform: 'uppercase', fontSize: '18px' }} color="textSecondary">
                                    {option.variant}
                                </Typography>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <Typography gutterBottom align='center' color="textPrimary">
                                    Apply for finance online. Quick, instant approval!
                  </Typography>
                                <Typography gutterBottom align='center' color="textSecondary">
                                    For this offer, you should earn in the region of <br /><strong><CurrencyValue
                                        value={salaryEstimate} /></strong>pm
                  </Typography>
                            </Grid>
                            <Grid item xs={12} md={3}>
                                <Box display='flex' justifyContent='center'>
                                    <Button fullWidth variant="contained" color="primary" onClick={() => {
                                        setFinance(true);
                                    }}>
                                            Check Now <br />
                        Monthly <CurrencyValue value={option.monthlyInstallment} />
                                        </Button>
                                </Box>
                            </Grid>
                            {finance && <PQContainer variantId={financeOptions[0].variantId} />}
                        </Grid>
                    )
                })
            }

        </Grid>
    );
}