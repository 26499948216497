import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Renault1 from '../shared/assets/MY-Renault-1.jpg';
import Renault2 from '../shared/assets/MY-Renault-2.jpg';
import Renault3 from '../shared/assets/MY-Renault-3.jpg';
import Renault4 from '../shared/assets/MY-Renault-4.jpg';

import Banner from '../shared/assets/service-banner.jpg'

const useStyles = makeStyles((theme) => ({
    bannerHeader: {
        backgroundColor: '#39679f',
        fontWeight: 700
    },
    info: {
        paddingBottom: '1rem'
    },
    listStyle: {
        listStylePosition:'inside',
    },
    flex: {
        display:'flex',
        '@media (max-width: 626px) ': {
            flexDirection:'column'
        },
    },
}));

const ServicePlansPage = () => {
    const classes = useStyles();

    return (
        <React.Fragment>
            <Container maxWidth="lg">
                <Typography align="center" paragraph gutterBottom style={{ fontSize: '1.0rem' }}>
                    maintain your Renault
                </Typography>
                <Typography gutterBottom variant="h5" align="center" color="textPrimary" fontWeight="500" style={{ fontSize: '2.25rem' }}>
                   MY RENAULT
                </Typography>
                <Typography align="center" paragraph gutterBottom style={{ fontSize: '1.3rem' }}>
                your mobile customer portal
                </Typography>
                <Typography align="center" paragraph gutterBottom style={{ fontSize: '1.0rem' }}>
                Designed to make your life a litter bit easier by putting everything you need to manage your Renault in one place. 
                <br/><br/>
                Once you have downloaded the app, you can login and update all your vehicle information to suit your needs. 
                </Typography>
                <Typography gutterBottom variant="h5" align="center" color="textPrimary" fontWeight="500" style={{ fontSize: '2.25rem' }}>
                   <img src={Renault1} style={{ margin: '0.3rem' }}/>
                   <img src={Renault2} style={{ margin: '0.3rem' }}/>
                   <img src={Renault3} style={{ margin: '0.3rem' }}/>
                   <img src={Renault4} style={{ margin: '0.3rem' }}/>
                </Typography>
                <Typography align="center" paragraph gutterBottom style={{ fontSize: '1.0rem' }}>
                Designed for you, imagined to ease your daily driving experience, wherever and whenever you want. <br/>
                What are MY Renault advantages?
                </Typography>
                <Typography align="center" paragraph gutterBottom>
                    <ul>
                        <li className={classes.listStyle}>
                            Add your Renault vehicle to your virtual garage
                        </li>
                        <li className={classes.listStyle}>
                         Keep informed of all the key moments of your Renaults maintenance schedule and find your maintenance history               
                        </li>
                        <li className={classes.listStyle}>
                            Prepare your trips by creating a route within the app
                        </li>
                        <li className={classes.listStyle}>
                            Enjoy benefits and personalised offers for your vehicle
                        </li>
                        <li className={classes.listStyle}>
                            Get advice: contact our Customer Relations team directly from the app
                        </li>
                        <li className={classes.listStyle}>
                            Looking to book an appointment with your dealer? It takes just a few clicks
                        </li>
                        <li className={classes.listStyle}>
                            Roadside Assistance at the touch of a button
                        </li>
                    </ul>
                </Typography>
                <Typography align="center" paragraph gutterBottom style={{ fontSize: '1.0rem' }}>
                     Download the app today and register your Renault vehicle thanks to its serial number (VIN) or registration number, and we will take care of your request.
                </Typography>
                <Typography align="center" paragraph gutterBottom style={{ fontSize: '1.0rem' }}>
                    Pamper your Renault vehicle from your app and discover the whole range, from personal to city cars, including hatchbacks, EV and SUV vehicles :
                </Typography>
                <Typography align="center" paragraph gutterBottom style={{ fontSize: '1.0rem',}}>
                    Twingo, Clio, Captur, Kangoo, Kadjar, Megane Hatchm Megane Sport Tourer, Scenic, Grand Scenic, Koleos, ZOE
                </Typography>
                <Container className={classes.flex} sm={12} md={12}>
                    <Grid item xs={12} sm={2} md={1}/>
                    <Grid item xs={12} sm={8} md={10}>
                        <Typography align="center" paragraph gutterBottom style={{ fontSize: '1.0rem', borderBottom:'4px solid rgb(239, 223, 0)',marginTop:'-11px', }}>
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sm={2} md={1}/>
                </Container>
                <Typography align="center" paragraph gutterBottom style={{ fontSize: '1.0rem',marginBottom:'0px',marginTop:'-15px', }}>
                maintain your Renault 
                </Typography>
                <Typography gutterBottom variant="h5" align="center" color="textPrimary" fontWeight="500" style={{ fontSize: '2.25rem', }}>
                MAINTENANCE & SERVICE PLANS
                </Typography>
                <Container className={classes.flex} sm={12} md={6}>
                    <Grid item xs={12} sm={6} md={6} style={{ margin: '1.5rem', }}>
                        <Typography variant="h5" align="center" fontWeight="500" paragraph gutterBottom style={{ fontSize: '1.3rem', }}>
                            What is a Maintenance Plan?
                        </Typography>
                        <Typography align="center" paragraph gutterBottom style={{ fontSize: '1.0rem' }}>
                            A Maintenance Plan is a comprehensive package that covers all services, maintenance, normal wear and tear requirements and labour. This means that you will never have to worry about unexpected parts and labour costs again.
                        </Typography>
                        <Typography variant="h5" align="center" fontWeight="500" paragraph gutterBottom style={{ fontSize: '1.5rem' }}>
                        Maintenance Plan: What is covered?
                        </Typography>
                        <Typography align="center" paragraph gutterBottom style={{ fontSize: '1.0rem' }}>
                        Warranty related items, Top-Up Oils, Driver abuse, Windscreen & all glass, Punctures, Paintwork, Body damage, Tyres.
                        </Typography>
                        <Typography variant="h5" align="center" fontWeight="500" paragraph gutterBottom style={{ fontSize: '1.5rem' }}>
                            Maintenance Plan: The Benefits
                        </Typography>
                        <Typography align="center" paragraph gutterBottom>
                        <ul>
                            <li className={classes.listStyle}>
                            Extensions can be “tailor-made” to suit your requirements
                            </li>
                            <li className={classes.listStyle}>
                            You’ll be paying for tomorrow’s motoring costs at today’s prices.               
                            </li>
                            <li className={classes.listStyle}>
                            We’ll keep your full service history, which will assist in maximizing the resale value of your value
                            </li>
                            <li className={classes.listStyle}>
                            Any unexpected maintenance your vehicle may need in the future will be taken care of.
                            </li>
                        </ul>
                    </Typography>
                    </Grid>
                    <Grid item xs={12} sm={6} md={6} style={{ margin: '1.5rem', }}>
                    <Typography variant="h5" align="center" fontWeight="500" paragraph gutterBottom style={{ fontSize: '1.5rem' }}>
                    What is a Service Plan?
                        </Typography>
                        <Typography align="center" paragraph gutterBottom style={{ fontSize: '1.0rem' }}>
                            Service Plans are designed to provide the client with the assurance that all scheduled services will be covered as per their Owners Manual, by an approved Renault dealer, utilizing approved parts fitted by highly qualified service technicians.                    </Typography>
                        <Typography variant="h5" align="center" fontWeight="500" paragraph gutterBottom style={{ fontSize: '1.5rem' }}>
                        Service Plans: What is Covered?
                        </Typography>
                        <Typography align="center" paragraph gutterBottom style={{ fontSize: '1.0rem' }}>
                        Service plans cover all scheduled services as specified in the Owners Manual. <br/><br/>
                        This includes the replacement of:<br/>
                        Engine and transmission oil, spark plugs. All serviceable belts. Most importantly cambelt*, Brake fluid, Oil filters, Air filters, Sump plug gaskets, Other lubricants and serviceable items required to perform the scheduled service, All labour costs involved in the scheduled service.
                        </Typography>
                        <Typography variant="h5" align="center" fontWeight="500" paragraph gutterBottom style={{ fontSize: '1.5rem' }}>
                        Service Plans: What is not covered?
                        </Typography>
                        <Typography align="center" paragraph gutterBottom style={{ fontSize: '1.0rem' }}>
                        Top up lubricants, such as oil, replacement of all wear and tear items, such as: brake pads, brake disks, shock absorbers, exhausts, tyres, wheel alignment, and balancing. Other market related exclusions: glass, towbars, punctures, radio / tape / cd, aircon regassing, alloy wheels.
                        </Typography>
                        <Typography variant="h5" align="center" fontWeight="500" paragraph gutterBottom style={{ fontSize: '1.5rem' }}>
                        The benefits of Service Plans
                        </Typography>
                        <Typography align="center" paragraph gutterBottom>
                        <ul>
                            <li className={classes.listStyle}>
                                Clients can purchase a Service Plan at any time.
                            </li>
                            <li className={classes.listStyle}>
                                You don’t feel the pinch of regular servicing costs.            
                            </li>
                            <li className={classes.listStyle}>
                                Your servicing costs are fixed. No matter how high prices rise in the future, you won’t have to worry about inflation, parts or labour cost increases.
                            </li>
                            <li className={classes.listStyle}>
                                Your vehicle’s warranty will never be in jeopardy.
                            </li>
                        </ul>
                    </Typography>
                    </Grid>
                </Container>

                
                <Container className={classes.flex} sm={12} md={12}>
                    <Grid item xs={12} sm={2} md={1}/>
                    <Grid item xs={12} sm={8} md={10}>
                        <Typography align="center" paragraph gutterBottom style={{ fontSize: '1.0rem', borderBottom:'4px solid rgb(239, 223, 0)',marginTop:'-11px', }}>
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sm={2} md={1}/>
                </Container>
                <Typography align="center" paragraph gutterBottom style={{ fontSize: '1.0rem',marginTop:'-15px', }}>
                    peace of mind 
                </Typography>
                <Typography gutterBottom variant="h5" align="center" color="textPrimary" fontWeight="500" style={{ fontSize: '2.25rem' }}>
                24 HOUR ROADSIDE ASSISTANCE 
                </Typography>
                <Typography align="center" paragraph gutterBottom style={{ fontSize: '1.3rem' }}>
                    Simply dial 0861 736 2858 for assistance
                </Typography>
                <Typography align="center" paragraph gutterBottom style={{ fontSize: '1rem' }}>
                This number is available 24 hours a day, 365 days a year. You will either be assisted on the scene or your vehicle will be towed to the nearest Renault dealership. Our 24 hour Roadside Assistance Plan covers all RENAULT vehicles for a period of 5 years or 150 000km with an option for extension. 
                </Typography>

                </Container>
        </React.Fragment>
    )
}

export default ServicePlansPage;