import React, { useEffect, useState,useContext } from 'react'
import { makeStyles } from '@material-ui/core/styles';
import { NavLink as RouterLink } from 'react-router-dom'
import Link from '@material-ui/core/Link';
import { BASE_URI, DEALERID,MASTER_DEALER_NAME,MASTER_DEALERID } from "./Constants"
import axios from "axios";
import { useSnackbar } from "notistack";
import { Formik, Form, Field } from "formik";
import { DealerContext } from './contexts/DealerContext'
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import MenuItem from "@material-ui/core/MenuItem";
import Image from '../shared/assets/home-slide-1.jpg';
import Button from '@material-ui/core/Button';
import { textAlign } from '@material-ui/system';
import Star from '../shared/assets/Picture1.svg';

const useStyles = makeStyles((theme) => ({
    background: {
        backgroundColor: 'black',
        padding: theme.spacing(1, 15, 1),
        display: 'flex',
        alignItems: 'center',
        position:'fixed',
        zIndex:'99',
        '@media (max-width: 426px)': {
            padding: theme.spacing(1, 1, 1 ,1),
            flexDirection:'column',
        },
        '@media (min-width: 426px) and (max-width: 1024px)': {
            padding: theme.spacing(1, 1, 1 ,1),
            flexDirection:'column',
        },
    },
    holder:{
        minHeight:'80vh',
        color:'white',
        textAlign:'center',
        backgroundBlendMode: 'overlay',
        backgroundColor: '#00000059',
        textAlign:'center',
        padding:'40px ',
        display:'flex ',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundPosition: 'center',
        backgroundSize: 'cover!important',
        '@media (max-width: 425px)': {
            minHeight:'20vh',
            backgroundSize: 'cover!important',
            backgroundPosition: 'center',
        },
        '@media (min-width: 426px) and (max-width: 1024px)': {
            minHeight:'40vh',
            backgroundSize: 'cover!important',
            backgroundPosition: 'center',
        },
       
    },
    holderText:{
       padding:'0px '
        
    },
    input:{
        padding:'20px ',
         
     },
     starHolder:{
         margin:'50px auto',

        
     },
     white:{
        color:'white'
    }

    
  
}));




export default function CarProfileBanner(props) {
    const { globalDealer } = useContext(DealerContext);
    const [activeDealer, setActiveDealer] = useState('');
     const cleanName = (dealerName) => {
        return dealerName ? dealerName.toLowerCase().replace(/\//g, '-').replace(/ /g, '-') : ""
    }
  
 
   function removeMake(path) {
        
    localStorage.removeItem("activeMake")
   


    if (path !== "/" && activeDealer !== MASTER_DEALER_NAME){
        if(globalDealer.name !== MASTER_DEALER_NAME){

            window.location.href=`/dealer/${cleanName(globalDealer.name)}/${path}`
             
         }else{
            window.location.href=`/${path}`
         }
    }
}


function removeMake(path) {
        
    localStorage.removeItem("activeMake")


    if (path !== "/" && activeDealer !== MASTER_DEALER_NAME){
        if(globalDealer.name !== MASTER_DEALER_NAME){

            window.location.href=`/dealer/${cleanName(globalDealer.name)}/${path}`
             
         }else{
            window.location.href=`/${path}`
         }
    }
}

useEffect(() => {

if(globalDealer){
if(globalDealer.name !== MASTER_DEALER_NAME){
    setActiveDealer(cleanName(globalDealer.name))
   
}else{
    setActiveDealer(cleanName(MASTER_DEALER_NAME))
}
}



}, [removeMake])
 

    const classes = useStyles();
    const [periodList, setPeriodList] = useState([84,72,60,48,36,]);
   



    return (
        <div style={{backgroundImage:`url(${props.image})`,backgroundSize:'100% 100%'}} className={classes.holder}>
            
        
              
            
            
               

             
               
           
         
        </div>
    )
}