import React, { useEffect, useState,useContext } from 'react'
import { makeStyles } from '@material-ui/core/styles';
import { NavLink as RouterLink } from 'react-router-dom'
import Link from '@material-ui/core/Link';
import { BASE_URI, DEALERID } from "./Constants"
import axios from "axios";
import { useSnackbar } from "notistack";
import { Formik, Form, Field } from "formik";
import { DealerContext } from './contexts/DealerContext'
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import MenuItem from "@material-ui/core/MenuItem";
import Slider from '@mui/material/Slider';

const useStyles = makeStyles((theme) => ({
    background: {
        backgroundColor: 'black',
        padding: theme.spacing(1, 15, 1),
        display: 'flex',
        alignItems: 'center',
        position:'fixed',
        zIndex:'99',
        '@media (max-width: 426px)': {
            padding: theme.spacing(1, 1, 1 ,1),
            flexDirection:'column',
        },
        '@media (min-width: 426px) and (max-width: 1024px)': {
            padding: theme.spacing(1, 1, 1 ,1),
            flexDirection:'column',
        },
    },
    holder:{
        width:"25%",
  
            
       height: '70vh',
       overflowX: 'hidden',
       overflowY: 'auto',
        backgroundColor:"white",
        margin:'0 auto',
   
        '@media (max-width: 1024px) ': {
           height:'90vh',
           width:"80%",
           overflow:'auto',
       }
       },
    answerHolder:{
        width:"100%",
        padding:"20px",
        background:'white',
        textAlign:'right',
        fontSize:'1.2rem',
        background:'#f9fafb'
       },
       header:{
        display: 'flex',
        padding:"10px 10px",
        justifyContent: 'space-between',
        background:'#d9d9d6',
        marginBottom:'15px',
        textTransform: 'initial!important'
       },
       input:{
        padding:"5px 10px",
       },
       close:{
        color:"red",
       }
}));




export default function FinanceCalulator(props) {
 
    const Open = () =>{
        props.openCalc(false)
 
    }

    const classes = useStyles();
    const [periodList, setPeriodList] = useState([84,72,60,48,36,]);
    const [amount, setAmount] = useState("");
    const [period, setPeriod] = useState(periodList[2]);
    const [balloon, setBalloon] = useState("");
    const [balloonPercent, setBalloonPercent] = useState("");
    const [depositAmount, setDepositAmount] = useState("");
    const [depositPercent, setDepositPercent] = useState("");
    const [interestRatePercent, setInterestRatePercent] = useState(7);
    const [totalAmount, setTotalAmount] = useState();
    const { enqueueSnackbar } = useSnackbar();
    const [loader, setLoader] = useState(false)


    const values =
    {
        amount: `${amount}`,
        period : `${period}`,
        balloon: `${balloon}`,
        balloonPercent: `${balloonPercent}`,
        depositAmount: `${depositAmount}`,
        depositPercent: `${depositPercent}`,
        interestRatePercent: `${interestRatePercent}`,
    };
   
    const handleSelectChange = (e, value, setFieldValue, fieldName, fieldId) => {
        setPeriod(e.target.value)
        console.log(period)
        setFieldValue(fieldName, e.target.value)
    }

    const handleAmountChange = (e, value, setFieldValue, fieldName, fieldId) => {
        setAmount(e.target.value)
        console.log( "Amount",e.target.value)
        setFieldValue(fieldName, e.target.value)
    }

    const handleInterestChange = (e, value, setFieldValue, fieldName, fieldId) => {
        setInterestRatePercent(e.target.value)
        console.log( "interestRatePercent",e.target.value)
        setFieldValue(fieldName, e.target.value)
    }
   

    const handleBalloonChange = (e, value, setFieldValue, fieldName, fieldId) => {
        setBalloon(e.target.value)
        console.log( "depositPercent",e.target.value / values.amount *100)
        setBalloonPercent(e.target.value / values.amount *100)
    }

 const handleDepositChange = (e, value, setFieldValue, fieldName, fieldId) => {
        setDepositAmount(e.target.value)
        console.log( "depositPercent",e.target.value / values.amount *100)
        setDepositPercent(e.target.value / values.amount *100)
    }

    const handleBalloonPercent = (e, value, setFieldValue, fieldName, fieldId) => {
        setBalloonPercent(e.target.value)
        setFieldValue(fieldName, e.target.value)

        setBalloon(  values.amount*e.target.value/100 )
        setFieldValue("balloon", values.amount* e.target.value/100  )
    }
    const handleDepositPercent = (e, value, setFieldValue, fieldName, fieldId) => {
        setDepositPercent(e.target.value)
        setFieldValue(fieldName, e.target.value)

        setDepositAmount(  values.amount*e.target.value/100 )
        setFieldValue("depositAmount", values.amount* e.target.value/100  )
    }

 

   
useEffect(() => {
    
    let onTheRoad = 5137;       // Eastvaal's static fee
    let admin = 1207.50;        // Eastvaal's static fee
    
       let sum = values.amount + onTheRoad + admin;
        var interestPM = interestRatePercent / 100 / 12;
        let months = values.period 
        let Total =""
    if(values.amount !== "" && balloon < 0){
    
         Total = (sum - depositAmount) * interestPM * (Math.pow(1 + interestPM, (months))) /(Math.pow(1 + interestPM, (months))-1) ;
        let installmentPM = Math.round(Total)
        setTotalAmount(installmentPM.toFixed(0))

    }else{
     
         Total = (sum - depositAmount - balloon) * interestPM * (Math.pow(1 + interestPM, (months))) /(Math.pow(1 + interestPM, (months))-1) ;
        let installmentPM = Math.round(Total)
        setTotalAmount(installmentPM.toFixed(0))
    }
    
  
  
    

}, [values])
    
    return (
        <div>
              <Formik
                    isInitialValid={false}
                    initialValues={values}
                   enableReinitialize={true}
                >
                {(props) => {  
                    let {
                    values: {
                        amount,
                        period,
                        balloon,
                        balloonPercent,
                        depositAmount,
                        depositPercent,
                        interestRatePercent,
                        
                    },
                    touched,
                    errors,
                    isValid,
                    submitForm,
                    handleBlur,
                    setFieldTouched,
                    setFieldValue,
                   
                } = props;
                return (

            <Form className={classes.holder}>
               
                <div className={classes.header}>
                <Typography gutterBottom variant="h5" color="textPrimary" fontWeight="500" >
                finance calculator                
                </Typography>
                <Typography gutterBottom variant="h5" fontWeight="500"  className={classes.close} onClick={()=>{Open()}}>
                X              
                </Typography>
                </div>
                <Grid item className={classes.input} xs={12} md={12}
                    lg={12}>
                    <TextField
                        id="amount"
                        name="amount"
                        type="text"
                        label="Amount"
                        fullWidth
                        value={amount}
                        variant="outlined"
                        helperText={touched.amount ? errors.amount : ""}
                        error={touched.amount && Boolean(errors.amount)}
                        onChange={(e, child) => handleAmountChange(e, child, setFieldValue, 'amount', 'amount')}
                    />
                </Grid>

                <Grid item className={classes.input} xs={12}>
                    <TextField
                     variant="outlined"
                     fullWidth
                     id="period"
                     select
                     label="Period"
                     value={period}
                     helperText={errors.period && touched.period ? errors.period : ''}
                     error={errors.period && touched.period}
                     onBlur={handleBlur("dealerId")}
                     onChange={(e, child) => handleSelectChange(e, child, setFieldValue, 'period', 'period')}
                 >
                     {periodList.map((period) => (
                         <MenuItem key={period.id} id={period} value={period}>
                             {period} Months
                         </MenuItem>
                     ))}
                 </TextField>
                </Grid>

                <Grid container spacing={2} className={classes.input}>
                <Grid item className={classes.stepPaddingButton} xs={12} md={12}
                    lg={6}>
                    <TextField
                        id="balloon"
                        name="balloon"
                        type="text"
                        label="Balloon (Rands)"
                        fullWidth
                        value={balloon}
                        variant="outlined"
                        helperText={touched.balloon ? errors.balloon : ""}
                        error={touched.balloon && Boolean(errors.balloon)}
                        onChange={(e, child) => handleBalloonChange(e, child, setFieldValue, 'balloon', 'balloon')}
                    />
                </Grid>
                <Grid item className={classes.stepPaddingButton} xs={12} md={12}
                    lg={6}>
                    <TextField
                        id=" balloonPercent"
                        name="balloonPercent"
                        type="text"
                        label="Balloon %"
                        fullWidth
                        value={balloonPercent}
                        variant="outlined"
                        helperText={touched.balloonPercent ? errors.balloonPercent : ""}
                        error={touched.balloonPercent && Boolean(errors.balloonPercent)}
                        onChange={(e, child) => handleBalloonPercent(e, child, setFieldValue, 'balloonPercent', 'balloonPercent')}
                      
                    />
                </Grid>
                <Grid item className={classes.stepPaddingButton} xs={12} md={12}
                    lg={6}>
                    <TextField
                        id=" depositAmount"
                        name="depositAmount"
                        type="text"
                        label="Deposit Amount"
                        fullWidth
                        value={depositAmount}
                        variant="outlined"
                        helperText={touched.depositAmount ? errors.depositAmount : ""}
                        error={touched.depositAmount && Boolean(errors.depositAmount)}
                        onChange={(e, child) => handleDepositChange(e, child, setFieldValue, 'depositAmount', 'depositAmount')}
                    />
                </Grid>
                <Grid item className={classes.stepPaddingButton} xs={12} md={12}
                    lg={6}>
                    <TextField
                        id=" depositPercent"
                        name="depositPercent"
                        type="text"
                        label="Deposit %"
                        fullWidth
                        value={depositPercent}
                        variant="outlined"
                        helperText={touched.depositPercent ? errors.depositPercent : ""}
                        error={touched.depositPercent && Boolean(errors.depositPercent)}
                        onChange={(e, child) => handleDepositPercent(e, child, setFieldValue, 'depositPercent', 'depositPercent')}
                    />
                </Grid>
                </Grid>
                <Grid item className={classes.input} xs={12} md={12}
                    lg={12}>
                        Interest Rate
                <Slider
                
                aria-label="Temperature"
                defaultValue={interestRatePercent }
                valueLabelDisplay="auto"
                step={0.5}
                marks
                min={7}
                max={14}
                onChange={(e, child) => handleInterestChange(e, child, setFieldValue, 'interestRatePercent', 'interestRatePercent')}
                />
                 </Grid>
                <div className={classes.answerHolder}>
                Monthly Installment: R{totalAmount}
                </div>
                <Grid item className={classes.input} xs={12} md={12}
                    lg={12}>
                <Typography gutterBottom variant="h6" color="white" style={{textAlign:'center'}}>
                        T's & C's
                           
                    </Typography>
                    <Typography gutterBottom variant="h4" color="white" style={{fontSize:'0.8rem',fontWeight:'normal',textAlign:'center'}}>
                    All calculations made on calculators supplied on this site, together with rates quoted, are guidelines only and are subject to confirmation at the time of finalising any transactions. All information regarding the products, fees and/or costs which are included in and form a funddamental basis of the calculations are subject to change at any time prior to a final pre-arrangement quote being handed to the User.
                           
                    </Typography>
<br/>
                    <Typography gutterBottom variant="h4" color="white" style={{fontSize:'0.8rem',fontWeight:'normal',textAlign:'center'}}>
                    The User indemnifies the dealer against any loss or liability, which the User may suffer as a result of the use of any calculator. The site and the information provided on this site and the services provided on this site, are provided "as is". The information provided on this site should not be treated as professional advice of any kind.
                           
                    </Typography>
                    <br/>
                    </Grid>
            </Form>
                );
            }}
            </Formik>
        </div>
    )
}