import React, { useEffect, useState,useContext } from 'react'
import { makeStyles } from '@material-ui/core/styles';
import { NavLink as RouterLink } from 'react-router-dom'
import Link from '@material-ui/core/Link';

import axios from "axios";
import { useSnackbar } from "notistack";
import { Formik, Form, Field } from "formik";

import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import MenuItem from "@material-ui/core/MenuItem";
import Slider from '@mui/material/Slider';
import { Container } from '@material-ui/core';
import Career from '../shared/assets/Careers.jpg' 
import Doc from '../shared/assets/APPLICATIONFOREMPLOYMENT.pdf' 

const useStyles = makeStyles((theme) => ({
    mainHolder:{
        padding: theme.spacing(10, 0, 1),
    },
    background: {
        backgroundColor: 'black',
        padding: theme.spacing(1, 15, 1),
        display: 'flex',
        alignItems: 'center',
        position:'fixed',
        zIndex:'99',
        '@media (max-width: 426px)': {
            padding: theme.spacing(1, 1, 1 ,1),
            flexDirection:'column',
        },
        '@media (min-width: 426px) and (max-width: 1024px)': {
            padding: theme.spacing(1, 1, 1 ,1),
            flexDirection:'column',
        },
    },
    button:{
        background:"#ed3131",
        border:'0px',
        padding:'10px 20px',
        borderRadius:'5px',
        color:'white',
        display:'block',
        margin:'0 auto'
    },
    flex:{
        display:"flex",
        '@media (max-width: 426px)': {
 
            flexDirection:'column',
        },
    },
    flexholder:{
     
        justifyContent:'flex-end'
    },
    flexbtnholder:{
        display:"flex",
        justifyContent:'flex-end'
    },
    imgHolder:{
        marginRight:'2.5rem',
        '@media (max-width: 426px)': {
            marginRight:'0rem'
        },
    },
    heading:{
        padding:'0px 20px',
    }
}));




export default function CareerPage(props) {
    const classes = useStyles();
   
   
useEffect(() => {
      

}, [])
    
    return (
        <Container className={classes.mainHolder}  xs={12} md={12}>
                 <Typography variant="h4" marked="left" gutterBottom className={classes.heading}>
                 Join our Team
                </Typography>
                    <Container  className={classes.flex} xs={12} md={12}>

                <Grid  className={classes.imgHolder} xs={12} md={12} lg={4}>
                    <img src={Career} width="100%"/>
                  
     
                    <a href={Doc} target="_blank">
            <button className={classes.button} >Download Employment Application Form</button>
            </a>
                </Grid>

                <Grid  xs={12} md={12} lg={6} className={classes.flexholder}>
                    <p>We are always looking for talented people across various departments, particularly Technicians and Sales Executives</p>
                    <p>If you would like to find out more about a career with us, please email us now at careers@Eastvaal.co.za alternatively you could pop into your local Eastvaal dealership.</p>
                    <p>“I believe that success is perseverance and perseverance lies within the right people within the dealerships”</p>
                    <p>- Heinro Schamrel,CEO, Eastvaal Motor Holdings</p>

              
                
            
                </Grid>
                </Container> 
         </Container> 
    )
}