import React,{useState,useEffect,useContext} from 'react'
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import FinanceCalculator from '../shared/FinanceCalulator';
import axios from 'axios';
import FacebookSocial from '../shared/assets/facebook.png';
import TwitterSocial from '../shared/assets/twitter.png';
import InstagramSocial from '../shared/assets/instagram.png';
import { BASE_URI,DEALERID} from '../shared/Constants'
import { NavLink as RouterLink } from 'react-router-dom';
import { DealerContext } from '../shared/contexts/DealerContext'

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        justifyContent:'center',
        padding:'2rem 2rem 2rem 2rem ',
        background: '#9095a0a8',
        '@media (max-width: 1024px) ': {
         
            flexDirection:'column'
        }
    },
    sectionHolder: {
        display: 'flex',
    
        flexDirection:'column',
    
    },
    heading:{
        fontWeight:'bold!important'
    },
    btn:{
        margin:'5px 0px!important',
        textDecoration:'none!important',
        color:'black!important',
    },
    calcHolder:{
        position:'fixed',
        top: '0',
        left:'0',
        background: '#9095a0a8',
        zIndex:'50',
        width:"100%",
        height:"100%",
        display: 'flex',
      
        justifyContent: 'center',
        alignItems: 'center',
    },
    footerIcon:{
        width: '40px',
        margin: '0.5rem',
        '@media (max-width: 425px)': {
           width: '15%',
       },
       '@media  (min-width: 769px) and (max-width: 1440px)': {
           width: '15%',
       }
      
    },
    sectionFlex:{
        display:'flex',
        flexDirection:'row',
        padding: '0px',
        '@media (max-width: 426px) ': {
           
            flexDirection:'column'
        }
    }
}))

export default function Footer() {
    const classes = useStyles();
    const [open, setOpen] = useState(false)
    let { dealerSocialMedia } = useContext(DealerContext);
    let openCalc = () =>{
        if (open === false) {
            setOpen(true)
            console.log(open)
        } else {
            setOpen(false)
            console.log(open)
        }
    }

    return (
        <div className={classes.root}>
              {open &&  
            <div className={classes.calcHolder}>
               <FinanceCalculator openCalc={setOpen}/>
            </div>}
            <Container className={classes.sectionFlex}>

             <Grid item xs={12} md={3} className={classes.sectionHolder}>
             <Typography gutterBottom variant="h6" component="div" className={classes.heading}>
             Legal
            </Typography>
         
             
                    <Link  color="textSecondary"
                       
                       component={RouterLink} to='/requestergws'
                       className={classes.btn}
                      >
                    popia
                   </Link>
    
                    <Link  color="textSecondary"
                       
                       component={RouterLink} to='/terms'
                       className={classes.btn}
                      >
                    terms and conditions
                   </Link>
                   <Link  color="textSecondary"
                       
                       component={RouterLink} to='/cookies'
                       className={classes.btn}
                      >
                   cookie policy
                   </Link>
         
            </Grid>

            <Grid item xs={12} md={3} className={classes.sectionHolder}>
            <Typography gutterBottom variant="h6" component="div" className={classes.heading}>
             Tools
            </Typography>
         
            <Link  color="textSecondary"
                       
                       component={RouterLink} to='/our-vehicles'
                       className={classes.btn}
                      >
                      new vehicles
                   </Link>
                   <Link  color="textSecondary"
                       
                       component={RouterLink} to='/used-vehicles'
                       className={classes.btn}
                      >
                       search pre-owned vehicles
                   </Link>
                   <Link  color="textSecondary"
                       
                       component={RouterLink} to='/special-deals'
                       className={classes.btn}
                      >
                       special deals
                   </Link>
         
            </Grid>

            <Grid item xs={12} md={3} className={classes.sectionHolder}>
            <Typography gutterBottom variant="h6" component="div" className={classes.heading}>
            Financial Services
            </Typography>
         
            <Link  color="textSecondary"
                       
                       component={RouterLink} to='/apply-for-finance'
                       className={classes.btn}
                      >
                apply for finance
                   </Link>
            <Link   className={classes.btn} onClick={()=>{openCalc()}}>
            finance calculator
            </Link>
            </Grid>

            <Grid item xs={12} md={3} >
            <Typography gutterBottom variant="h6" component="div" className={classes.heading}>
            Connect Socially
            </Typography>
                 
                     
                       {dealerSocialMedia?.facebook !== "" &&
                       
                        <Link className={classes.link} target="_blank" href={dealerSocialMedia?.facebook}>
                            <img src={FacebookSocial} className={classes.footerIcon} alt="Facebook"/>
                        </Link>
                     
                          }
                        {dealerSocialMedia?.twitter !== "" &&
                          
                        <Link className={classes.footerLink} target="_blank" href={dealerSocialMedia?.twitter}>
                            <img src={TwitterSocial} className={classes.footerIcon} alt="twitter"/>
                        </Link>
                       
                        }
                        {dealerSocialMedia?.instagram !== "" &&
              
                        <Link className={classes.footerLink} target="_blank" href={dealerSocialMedia?.instagram}>
                            <img src={InstagramSocial} className={classes.footerIcon} alt="instagram"/>
                        </Link>
                          
                          }
                      
            </Grid>
            </Container>
        </div>
    )
}
