import React from 'react'
import { makeStyles } from '@material-ui/core/styles';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import Card from '../shared/Card';

const useStyles = makeStyles({
  root: {
    color: 'white',
    height: "100vh",
  },
  card:{
    padding: '17px',
    textAlign:'left!important'
  }
});


export default function CardCarousel(props) {

  const classes = useStyles();
  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 5
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1
    }
  };
  return (
    <Carousel 
    responsive={responsive}
    infinite={true}
    autoPlay={ true }
    autoPlaySpeed={3000}
    itemClass={classes.card}
    >
      {props.vehicles.map((v,index)=>{
        return(
          
          <Card key={index} height={props.align} align={props.align} image={v.image} brand={v.brand} model={v.model} year={v.year} mileage={v.mileage} newUsed={v.newUsed} price={v.price} dealershipName={v.dealershipName} ctatext="ENQUIRE NOW" ctatextlink={`${props.ctatextlink}/${v.stockId}`}  cardType={props.cardType}  />
        )
      })}
 
    </Carousel>
  );
}
