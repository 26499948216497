import React, { useContext, useEffect } from 'react';
import Container from '@material-ui/core/Container';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import { NavLink as RouterLink, useParams } from 'react-router-dom';
import Link from '@material-ui/core/Link';
import { DealerContext } from '../shared/contexts/DealerContext'
import axios from 'axios';
import { ImageContainer } from '../shared/ImageContainer'
import ContactComponentPopi from '../shared/ContactComponentPopi'
import Manual from '../shared/assets/Eastvaal PAIA Manual3.docx'
const useStyles = makeStyles((theme) => ({
    content: {
        backgroundColor: theme.palette.background.paper,
        padding: theme.spacing(10, 0, 6),
        '@media (max-width: 1024px)': {
            padding: theme.spacing(10, 0, 6),
        },
    },
    dividerTitle: {
        backgroundColor: '#39679f',
        width: '33%',
        marginBottom: '0px',
        height: '3px',
        margin: '20px auto'
    },
    bold: {
        fontWeight: 700
    },
    listItem: {
        listStyle: 'lower-alpha',
    },
    url: {
        color: '#7c7c7c',
        textDecoration: 'none',
        '&:hover': {
            color: '#1f2532',
            textDecoration: 'none'
        }
    },
    link: {
        backgroundColor: '#E20A17',
        marginBottom: '1rem',
        color: '#ffffff',
        fontWeight: '700',
        '&:hover': {
            textDecoration: 'none',
            opacity: 0.8
        }
    },
    btnb:{
        background:'#39679f',
        padding:'10px',
        color:'white',
        marginTop:'50px',
        display:'block',
        width:'fit-content'
    }
}));

const Popia = () => { 
    const classes = useStyles();

    return (
        <Container maxWidth="lg">
            <div className={classes.content}>
        
                <Grid container spacing={6}>
             
                    <Grid item xs={12} md={4}>
                    <Typography gutterBottom variant="h4" color="textPrimary" fontWeight="500">
                        POPI REQUEST
                    </Typography>
                        <ContactComponentPopi leadTypeId={3} />
                    </Grid>

                    <Grid item xs={12} md={7}>
                    <Typography gutterBottom variant="h5" color="textPrimary" fontWeight="500">
                        Section 11 of PROTECTION OF PERSONAL INFORMATION ACT (POPIA)
                    </Typography>
                    <ol>
                        <li>
                        Processing of personal information 
                        </li>
                        
                        <>
                        Eastvaal Motors takes the privacy and protection of personal information very seriously and will only process personal information in accordance with the current South African privacy. Accordingly, the relevant personal information privacy principles relating to the processing thereof (including, but not limited to, the collection, handling, transfer, sharing, correction, storage, archiving and deletion) will be applied to any personal information processed by Eastvaal Motors.
                        </>

                        <li>
                        Eastvaal Motors processes personal information for variety of purposes including but not limited to the following:
                        </li>
                       
                            <ul className={classes.listItem}>
                                <li>
                                Fulfilling its statutory obligations in terms of applicable legislation;
                                </li>
                                <li>
                                Verifying information provided to Eastvaal Motors;
                                </li>
                                <li>
                                Obtaining information necessary to provide contractually agreed services to a customer;
                                </li>
                                <li>
                                Monitoring, maintaining, and managing Eastvaal Motors’s’ contractual obligations to customers, clients, suppliers, service providers, employees, directors and other third parties;
                                </li>
                                <li>
                                for travel purposes 
                                </li>
                                <li>
                                for recruitment purposes;
                                </li>
                                <li>
                                for employment purposes;
                                </li>
                                <li>
                                Marketing and advertising;
                                </li>
                                <li>
                                for health and safety purposes;
                                </li>
                                <li>
                                Resolving and tracking complaints;
                                </li>
                                <li>
                                Monitoring and securing the assets, employees, and visitors to the premises of Eastvaal Motors;
                                </li>
                                <li>
                                to help us improve the quality of our products and services;
                                </li>
                                <li>
                                to help us detect and prevent fraud and money laundering
                                </li>
                                <li>
                                Historical record keeping, research and recording statistics necessary for fulfilling Eastvaal Motors’s’ business objectives.
                                </li>
                            </ul>
                            <br/>
                            <li>
                    Categories of data subjects and personal information processed by Eastvaal Motors
                            </li>

                            <li>
                            Eastvaal Motors may process the personal information of the following categories of data subjects, which includes current, past, and prospective data subjects: 
                            </li>
                          
                            <ul className={classes.listItem}>
                                <li>
                                Customer and employees, representatives, agents, contractors, and service providers of such customers;
                                </li>
                        
                                <li>
                                Suppliers, service providers to and vendors of Eastvaal Motors and employees, representatives, agents, contractors and service providers of such suppliers and service providers;
                                </li>

                                <li>
                                Directors and officers of Eastvaal Motors;
                                </li>

                                <li>
                                Shareholders;
                                </li>

                                <li>
                                Existing and former employees (including contractors, agents, temporary and casual employees);
                                </li>

                                <li>
                                Visitors to any premises of Eastvaal Motors; 
                                </li>

                                <li>
                                Complaints, correspondents, and enquiries; and
                                </li>

                                <li>
                                to monitor access, secure and manage our premises and facilities.
                                </li>
                            </ul>
                            <br/>
                            <li>
                            The nature of personal information processed in respect of the above data subjects may include, as may be applicable: 
                            </li>
                        
                            <ul className={classes.listItem}>
                                <li>
                                Name, identifying number, symbol, email address, physical address, telephone number, location information, online identifier, or other particular assignment to the person;
                                </li>
                                <li>
                                Biometric information;
                                </li>
                               
                                <li>
                                Information relating to the education or the medical, financial, criminal or employment history of the data subject;
                                </li>
                                <li>
                                Information relating to the rate, gender, marital status, national origin, age disability, language, and birth of the data subject;
                                </li>
                                <li>
                                The personal opinions, views, or preferences of the data subject;
                                </li>
                                <li>
                                Confidential correspondence sent by the data subject;
                                </li>
                                <li>
                                The views of opinions of another individual about the data subject.
                                </li>
                               
                            </ul>
                            <br/>
                            <li>
                            Recipients or categories of recipients with whom personal information is shared
                            </li>
                            <li>
                            Regulatory, statutory and government bodies;    
                            </li>

                            <ul>
                            <li>
                            Suppliers, service providers, vendors, agents, and representatives of Eastvaal Motors;
                            </li>

                            <li>
                            Employees of Eastvaal Motors;
                            </li>

                            <li>
                            Shareholders and other stakeholders;
                            </li>

                            <li>
                            Third party verification agencies and credit bureau;
                            </li>

                            <li>
                            Banks and other financial institutions.
                            </li>

                            <li>
                            Eastvaal Motors does not share the personal information of our data subjects with any third parties, except if:
                            </li>

                            <li>
                            Eastvaal Motors is obliged to provide such information for legal or regulatory purposes;
                            </li>

                            <li>
                            Eastvaal Motors is required to do so for purposes of existing or future legal proceedings,
                            </li>

                            <li>
                            Eastvaal Motors is selling one or more of our businesses to someone to whom we may transfer our rights under any customer agreement we have with you;
                            </li>

                            <li>
                            Eastvaal Motors is involved in the prevention of fraud, loss, bribery, or corruption;
                            </li>

                            <li>
                            they perform services and process personal information on our behalf;
                            </li>

                            <li>
                            this is required to provide or manage any information, products and/or services to data subjects; or
                            </li>

                            <li>
                            needed to help Eastvaal Motors improve the quality of our products and services.
                            </li>


                            </ul>

                            <li>
                            Eastvaal Motors shall send our data subjects notifications or communications if we are obliged by law, or in terms of our contractual relationship with them.
                            </li>
                            <li>
                            Eastvaal Motors shall only disclose personal information to government authorities if we are required to do so by law.
                            </li>
                            <li>
                            Eastvaal Motors employees, contractors, and suppliers, are required to adhere to data privacy and confidentiality principles. 
                            </li>

                    </ol>
                    <a href={Manual} className={classes.btnb}>
                    Download Manual
                    </a>
                    </Grid>
                </Grid>
            </div>
           
        </Container>
    );
}

export default Popia;