import React, { useEffect, useState, useContext } from "react";
import Container from "@material-ui/core/Container";
import { makeStyles } from "@material-ui/core/styles";
import MenuItem from "@material-ui/core/MenuItem";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import { ImageContainerUsedVehicle } from "../shared/ImageContainerUsedVehicle";
import { CurrencyValue } from "../shared/TextMask";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import { NavLink as RouterLink } from "react-router-dom";
import Link from "@material-ui/core/Link";
import VehicleController from "./VehicleController";
import { useSnackbar } from "notistack";
import { CircularProgress } from "material-ui";
import MuiThemeProvider from "material-ui/styles/MuiThemeProvider";
import { Modal } from "@material-ui/core";
import CarProfileContainer from "../carProfile/UsedCar";
import { useHistory } from "react-router-dom";
import { FinanceContext } from "../shared/contexts/FinanceContext";
import ComingSoon from "../shared/assets/comingsoon.jpg";
import { CompareContext } from "../shared/contexts/CompareContext";
import { FavouriteContext } from "../shared/contexts/FavouriteContext";
import AddIcon from "@material-ui/icons/Add";
import Mail from "../shared/assets/mail.png";
import { padding } from "@mui/system";
import { ConstructionOutlined } from "@mui/icons-material";

const useStyles = makeStyles((theme) => ({
  filterLoadingContainer: {
    height: "576px",
    position: "absolute",
    width: "210px",
    backgroundColor: "rgba(0, 0, 0, 0.1)",
    zIndex: "10",
    boxShadow: "inset 0 0 0 200px rgb(255 255 255 / 5%)",
    filter: "blur(10px)",
  },

  content: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(8, 0, 6),
  },
  dividerTitle: {
    backgroundColor: "#5490d9",
    width: "60%",
    marginBottom: "30px",
    height: "3px",
    margin: "0px auto",
  },
  carddividerTitle: {
    backgroundColor: "#5490d9",
    width: "100%",
    marginBottom: "10px",
    height: "3px",
    margin: "5px auto",
  },
  bold: {
    fontWeight: 700,
  },
  lighter: {
    fontWeight: "lighter",
  },
  flex: {
    display: "flex",
    flexDirection: "row!important",
    justifyContent: "space-between",
  },
  clear: {
    padding: "0px!important",
    fontSize: "14px",

    textAlign: "center",
  },
  price: {
    fontSize: "1.8rem",
    color: "black",
    textAlign: "center",
    margin: "10px",
    "&>span": {
      fontWeight: 400,
    },
  },
  pricepm: {
    fontSize: "0.8rem",
    "&>span": {
      fontWeight: 400,
    },
  },
  title: {
    fontSize: "1.0rem",
    "&>span": {
      fontWeight: 400,
    },
  },
  vehicleContainer: {
    "& h5, & p": {
      color: "#7c7c7c",
    },
    "&:hover img, &:hover p, &:hover h5": {
      color: "#1f2532",

      textDecoration: "none",
    },
  },
  btn: {
    display: "flex",
    flexDirection: "column",
    alignItems: "start",
    marginTop: "2%",
    width: "45%",
    cursor: "pointer",
  },
  btn1: {
    display: "flex",
    flexDirection: "column",
    alignItems: "start",
    marginTop: "2%",
    width: "45%",
    background: "white",
    border: "1px solid black",
    cursor: "pointer",
  },
  btn2: {
    minWidth: "100%",
    cursor: "pointer",
  },
  image: {
    padding: "0px 0px",
    position: "relative",

    "&:hover": {
      transition: "all 0.2s",
      mixBlendMode: "darken",
    },
  },
  afterHolder: {
    color: "white",
    padding: "10px",
    position: "absolute",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-evenly",
    top: "50%",
    right: "50%",
    width: "100%",
    height: "100%",
    alignSelf: "center",
    transform: "translate(50%, -50%)",
    background: "#ffffff4d  ",
    zIndex: "50",
    alignItems: "center",
    justifyContent: "center",
  },
  after: {
    color: "white",
    padding: "10px",
    width: "fit-content",
    margin: "0px 0px",
    background: "black",
    zIndex: "50",
    display: "flex",
    width: "100%",
    justifyContent: "center",
  },
  afterred: {
    color: "black",
    padding: "10px",
    width: "fit-content",
    margin: "30px 0px",
    background: "#5490d9",
    zIndex: "50",
  },
  carsLoadContainer: {
    display: "flex",
    justifyContent: "center",
  },
  CarsLoad: {
    margin: "auto",
  },
  carHolder: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    boxShadow:
      " 0 10px 15px -3px rgba(0,0,0,0.1),0 4px 6px -2px rgba(0,0,0,0.05)",
    padding: "0px 10px",
    paddingBottom: "10px",
    paddingTop: "20px",

    textAlign: "center",
    "@media (max-width: 426px)": {
      maxWidth: "100%",
    },
    "@media (min-width: 427px) and (max-width: 768px)": {
      maxWidth: "calc(100%/2)",
    },
    "&:hover > .image ": {
      display: "none",
    },
  },
  flexHolder: {
    display: "flex",
    background: "#f2f2f2",
    color: "black",
  },
  flexWrap: {
    display: "flex",
    flexWrap: "wrap",
    marginTop: "20px",
  },
  flexbtn: {
    display: "flex",
    justifyContent: "center",
    textAlign: "center",
    padding: "10px 0px",
    cursor: "pointer",
    width: "50%",
    "&:hover": {
      backgroundColor: "#5490d9",
      opacity: 0.8,
    },
  },
  unset: {
    flexWrap: "unset",
    "@media (max-width: 959px)": {
      flexWrap: "wrap",
    },
  },
  mainTitle: {
    padding: "0px",
    margin: "0px",
    fontWeight: "normal",
    fontSize: "1.3rem",
    textTransform: "uppercase",
  },
  location: {
    display: "flex",
    justifyContent: "center",
  },
  warrantyHolder: {
    background: "#3e3f40",
    color: "white",
    display: "flex",
    padding: "10px",
    fontSize: "0.8rem",
    "@media (max-width: 426px)": {
      fontSize: "1rem",
    },
    "@media (min-width: 427px) and (max-width: 768px)": {
      fontSize: "0.8rem",
    },
  },
  yellow: {
    color: "#5490d9",
  },
  warrantyYearHolder: {
    borderLeft: "2px #535455 solid",
    paddingLeft: "10px",
    display: "none",
  },
  warrantyTextHolder: {
    paddingRight: "5px",
  },
}));

const UsedVehicles = ({ onSelectView, vehicles }) => {
  const { setCompareVal } = useContext(CompareContext);
  const { compareVal } = useContext(CompareContext);
  const [active, setActive] = useState(false);
  const { setFavouriteVal } = useContext(FavouriteContext);
  const { favouriteVal } = useContext(FavouriteContext);
  const history = useHistory();
  const classes = useStyles();
  const [activeVehicle, setActiveVehicle] = useState(null);
  const [val, setVal] = useState(null);

  let { setGlobalModel } = useContext(FinanceContext);

  const buildName = (vehicle) => {
    if (!vehicle) return "";

    return vehicle.brand + " " + vehicle.model;
  };

  const applyOnline = (vehicle) => {
    setGlobalModel(vehicle);

    history.push(`/apply-for-finance`);
  };
  return (
    <MuiThemeProvider>
      <Container container md={12} className={classes.flexWrap}>
        {!vehicles ||
          (vehicles.length <= 0 && (
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <Typography
                gutterBottom
                variant="h6"
                color="textPrimary"
                fontWeight="500"
                style={{ textAlign: "center", margin: "15rem 0px" }}
              >
                No stock available
              </Typography>
            </Grid>
          ))}

        {vehicles &&
          vehicles.map((vehicle, index) => {
            return (
              <Grid
                item
                xs={12}
                sm={6}
                md={4}
                lg={4}
                key={index}
                className={classes.carHolder}
              >
                <Box className={classes.vehicleContainer}>
                  <Link
                    underline="none"
                    component={RouterLink}
                    to={"/stock/" + vehicle.stockId}
                  >
                    <Box
                      className={`${classes.image} image`}
                      onClick={() => onSelectView(vehicle)}
                    >
                      <ImageContainerUsedVehicle
                        aspectRatio="auto auto"
                        height="222px"
                        position="relative"
                        src={vehicle.image ? vehicle.image : ComingSoon}
                        alt={buildName(vehicle.title)}
                        style={{ maxHeight: "200px" }}
                      />
                    </Box>

                    <Grid
                      item
                      md={12}
                      mt={0}
                      mb={0}
                      className={` ${classes.bold}`}
                    >
                      <Grid item xs={12} sm={12} md={12}>
                        <p className="clear" className={classes.mainTitle}>
                          {vehicle.brand}
                          <br />
                          {vehicle.model}
                        </p>
                        <Box
                          mt={0}
                          mb={0}
                          textAlign="center"
                          component="p"
                          className={`${classes.title} ${classes.lighter} `}
                        >
                          <p className="clear">
                            {vehicle.year} &nbsp;|&nbsp; {vehicle.mileage} km
                            &nbsp;| &nbsp;{vehicle.newUsed}
                          </p>
                        </Box>
                        <div className={classes.location}>
                          <img src="https://img.icons8.com/external-flatart-icons-outline-flatarticons/20/000000/external-location-twitter-flatart-icons-outline-flatarticons.png" />
                          <p className="clear">{vehicle.dealershipName}</p>
                        </div>
                      </Grid>

                      <Grid
                        item
                        xs={12}
                        sm={12}
                        md={12}
                        justify="center"
                        className={`${classes.price} ${classes.bold}`}
                      >
                        {vehicle?.price > 0 ? (
                          <><Box component="span">From</Box>{" "}
                            <CurrencyValue value={vehicle.price} />
                          </>
                        ) : (
                          <>{"POA"}</>
                        )}
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        sm={12}
                        md={12}
                        style={{
                          color: "black",
                          fontSize: "0.8rem",
                          padding: "0px 10px",
                          paddingBottom: "20px",
                          fontWeight: "lighter",
                        }}
                        justify="center"
                      >
                        R {Math.round(vehicle.price / 60).toFixed(0)} pm -10%
                        deposit, 60 months, <br />
                        11% interest
                      </Grid>
                    </Grid>
                  </Link>
                </Box>

                <div>
                  <Box mb={0} className={classes.flexHolder}>
                    <div
                      variant="contained"
                      gutterBottom
                      className={classes.flexbtn}
                      endIcon={<AddIcon />}
                      onClick={() => {
                        setFavouriteVal([...favouriteVal, vehicles[index]]);
                      }}
                    >
                      favourites &nbsp;
                      <img
                        src="https://img.icons8.com/material-outlined/24/000000/plus--v1.png"
                        width="20px"
                        height="20px"
                      />
                    </div>
                    <div
                      style={{
                        background: "black",
                        width: "1px",
                        margin: "10px 0px",
                      }}
                    ></div>
                    {compareVal.length <= 2 && (
                      <div
                        variant="contained"
                        gutterBottom
                        className={classes.flexbtn}
                        endIcon={<AddIcon />}
                        onClick={() => {
                          setCompareVal([...compareVal, vehicles[index]]);
                        }}
                      >
                        compare &nbsp;{" "}
                        <img
                          src="https://img.icons8.com/material-outlined/24/000000/plus--v1.png"
                          width="20px"
                          height="20px"
                        />
                      </div>
                    )}

                    {compareVal.length > 2 && (
                      <div
                        variant="contained"
                        gutterBottom
                        className={classes.flexbtn}
                      >
                        compare
                      </div>
                    )}
                  </Box>
                  <Box mb={0} className={classes.warrantyHolder}>
                    <Grid
                      item
                      xs={12}
                      sm={12}
                      md={12}
                      className={classes.warrantyTextHolder}
                    >
                      Balance of <br />
                      <span className={classes.yellow}>
                        Manufacturer Warranty *
                      </span>
                    </Grid>
                    <Grid
                      item
                      xs={2}
                      sm={2}
                      md={2}
                      className={classes.warrantyYearHolder}
                    >
                      4 <br />
                      Years
                    </Grid>
                  </Box>
                  <Link
                    underline="none"
                    component={RouterLink}
                    to={"/contact-us"}
                    className={classes.after}
                  >
                    <img src={Mail} width="20px" />
                    ENQUIRE NOW
                  </Link>
                </div>
              </Grid>
            );
          })}
      </Container>
    </MuiThemeProvider>
  );
};

export default UsedVehicles;
