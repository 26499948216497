import React,{useEffect,useState,useContext} from 'react'
import { makeStyles } from '@material-ui/core/styles';
import axios from 'axios';
import { BASE_URI, DEALERID,MASTER_DEALER_NAME } from '../shared/Constants'
import Link from '@mui/material/Link';
import { NavLink as RouterLink } from 'react-router-dom';
import { DealerContext } from '../shared/contexts/DealerContext'
import { MakeContext } from '../shared/contexts/MakeContext'
import { useHistory } from "react-router-dom";
import TopBar from './TopBar';
import Button from '@mui/material/Button';

const useStyles = makeStyles({
    root:{
     display:'flex',
     flexDirection:'column',
     position:'sticky',
     top:'0rem',
     zIndex:30,
     width:'100%',
     '@media (max-width: 1024px)': {
        position:'sticky',
        top:'0rem',
    },
    },
    menu:{
        margin:'0 auto',
        marginTop:'0rem',
        background:'white',
        width:'100%',
        padding:'1rem',
        borderRadius:'10px',
        display:'flex',
        justifyContent:'space-between',
        boxShadow: '#00000033 0px 16px 22px -2px',
        alignItems: 'center',
        '@media (max-width: 1024px)': {
            width:'100%',
            marginTop:'0rem',
            borderRadius:'0px',
        },
    },
    logo:{
        alignItems: 'center',
        display:'flex',
     
    },
    logoHolder:{
        alignItems: 'center',
        display:'flex',
        justifyContent: 'space-between',
        width: '100%',
    },
    hide:{
        '@media (max-width: 768px)': {
            display:'none',
         
        },
    },
    btn:{
        margin:'0px 10px!important',
        textDecoration:'none!important',
        cursor:'pointer',
        textAlign:'center',
        textTransform:'uppercase',
        color:'#00000099',
        fontSize:'14px'
    },
    btnback:{
        margin:'0px 0px!important',
        textDecoration:'none!important',
        cursor:'pointer',
        fontSize:'14px',
    },
    linkholder:{
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        '@media (max-width: 1024px)': {
            display: 'none'
        }
    },
    burgerholder:{
        position:'relative',
        '@media (min-width: 1025px)': {
            display: 'none'
        }
    },
 
    burgermenu:{
        position:'absolute',
        top:'45px',
        right:'-16px',
        width:'200px',
        display:'flex',
        flexDirection:'column',
        textAlign:'right',
        background:'white',
        borderRadius:'0px 0px 10px 10px',
        
        boxShadow: '#00000033 0px 16px 22px -2px',
      
    },
    burger:{
        minHeight:'3px',
        background:'black',
        minWidth:'25px',
        margin:'6px 0px'
    },
    btnMenuItem:{
      
        margin:'10px 10px!important',
        textDecoration:'none!important'
    },
    img:{
        display:'flex',
        flexDirection:'column',
    },
    logoHS:{
        padding:"10px 0px",
    }
    
})

export default function NavBar(props) {
    const { make, setActiveGlobalMake, makeColors,setMake } = useContext(MakeContext)
    const { websiteColors } = useContext(DealerContext)
    const [navBarData, setNavBarData] = useState(false);
    const [Logo, setLogo] = useState('');
    const [activeDealer, setActiveDealer] = useState('');
    const [mobile, setMobile] = useState(false);
    let getDealer = localStorage.getItem("globalDealer")
    let dealer = JSON.parse(getDealer)
    const [DealerId, setDealerId] = useState(false);
    let { globalDealer } = useContext(DealerContext);
    let history = useHistory();
     useEffect(() => {
 
        axios({
            method: 'GET',
            url: `${BASE_URI}/dealerWebsites/${DEALERID}`
        }).then((response) => {

            setNavBarData({ title: response.data.title, subTitle: response.data.subTitle, logo: response.data.image });
            
            document.title = `${response.data.title}`
        });
    
    }, []);

 
    
    const cleanName = (dealerName) => {
        return dealerName ? dealerName.toLowerCase().replace(/\//g, '-').replace(/ /g, '-') : ""
    }
    useEffect(() => {
 
if(getDealer){
  if(dealer.name !== MASTER_DEALER_NAME){
        setActiveDealer(cleanName(dealer.name))
       
    }else{
        setActiveDealer(cleanName(MASTER_DEALER_NAME))
    }
}
  
    
    
    }, [removeMake])

    function removeMake(e,make) {
     
        localStorage.removeItem("activeMake")

        let dealer = localStorage.getItem('globalDealer')
        let dealerParsed = JSON.parse(dealer)
    
        setMake("")
          
        
    }




    useEffect(() => {
        setDealerId(globalDealer.id)

    
         

            axios({
                method: 'GET',
                url: `${BASE_URI}/DealerWebsites/${globalDealer.id} `
            }).then((response) => {
                setLogo(response.data.image)
            });

    }, [globalDealer]);
    const classes = useStyles();
    return (
        <div className={classes.root}>
         
              <div className={classes.menu}>
     
              <div className={classes.logoHolder}>
        
                <div className={classes.logo}>
                <div className={classes.img}>
                {globalDealer.id === 480 ? 
             <div className={classes.logoHS}>
              <a href="https://auto-italia.co.za/">
                              <img src={websiteColors?.logo ? websiteColors?.logo : Logo}  alt="Eastvaal Motor Group" />
                              </a>
                             </div>
                           :   <Link component={RouterLink} to='/' >
                              <img src={websiteColors?.logo ? websiteColors?.logo : Logo}  alt="Eastvaal Motor Group" />
                          </Link>
                          }

                          {globalDealer.id !== DEALERID && 
                           <p variant="contained" className={classes.btnback} onClick={()=>{window.location.replace("/")}}> &lt; Back to Eastvaal Group </p>
                      }
                </div>
                          
                          <div className={classes.linkholder}>
                          {globalDealer.id === 480 ? 
                          <>
                                  <a href="https://auto-italia.co.za/"   className={classes.btn}> {props.link1Text}</a> 

                                  <Link variant="button" color="textSecondary"
                       
                       component={RouterLink} to='/used-vehicles'
                       className={classes.btn}
                      >
                      {props.link2Text}
                   </Link>
                                  </>
                   :
                <>
                   <Link variant="button" color="textSecondary"
                   onClick={(e) => {removeMake(`our-vehicles`)}}
                component={RouterLink} to='/our-vehicles'
                className={classes.btn}
               >
               {props.link1Text}
            </Link>  
             
                </>
                  }  
           
           {globalDealer.id === 480 ? <></> : <>
                
                    <Link variant="button" color="textSecondary"
                       
                        component={RouterLink} to='/sell-vehicle'
                        className={classes.btn}
                       >
                       {props.link3Text}
                    </Link>
                    <Link variant="button" color="textSecondary"
                      
                        component={RouterLink} to='/special-deals'
                        className={classes.btn}
                       >
                       {props.link4Text}
                    </Link>
                    <Link variant="button" color="textSecondary"
                    
                        component={RouterLink} to='/apply-for-finance'
                        className={classes.btn}
                       >
                       {props.link5Text}
                    </Link>
                    <Link variant="button" color="textSecondary"
                      
                        component={RouterLink} to='/our-dealers'
                        className={classes.btn}
                       >
                       {props.link6Text}
                    </Link>
                    </>
}
                    </div >
                </div>
                {globalDealer.id === 480 ? <></> :
      <div className={classes.hide}><TopBar link1Text='Contact Us' link2Text='Book A Service' link3Text='Parts'/></div>
                }
                
                </div>
                
                <div >
                <div className={classes.burgerholder} onClick={()=>{setMobile(mobile ? false:true)}}>
                <div className={classes.burger}>
                </div>
                <div className={classes.burger}>
                </div>
                <div className={classes.burger}>
                </div>

                 {mobile &&
                <div className={classes.burgermenu}>
          
                    {globalDealer.id === 480 ? 
                          <>
                                  <a href="asdasdasd"> {props.link1Text}</a> 
                                  </>
                   :
                   <Link variant="button" color="textSecondary"
                   onClick={(e) => {removeMake(`our-vehicles`)}}
                   component={RouterLink} to='/our-vehicles'
                   className={classes.btnMenuItem}
                  >
                  {props.link1Text}
               </Link>   
                  }  
           
                    <Link variant="button" color="textSecondary"
                       
                        component={RouterLink} to='/used-vehicles'
                        className={classes.btnMenuItem}
                       >
                       {props.link2Text}
                    </Link>
                    <Link variant="button" color="textSecondary"
                       
                        component={RouterLink} to='/sell-vehicle'
                        className={classes.btnMenuItem}
                       >
                       {props.link3Text}
                    </Link>
                    <Link variant="button" color="textSecondary"
                      
                        component={RouterLink} to='/special-deals'
                        className={classes.btnMenuItem}
                       >
                       {props.link4Text}
                    </Link>
                    <Link variant="button" color="textSecondary"
                    
                        component={RouterLink} to='/apply-for-finance'
                        className={classes.btnMenuItem}
                       >
                       {props.link5Text}
                    </Link>
                    <Link variant="button" color="textSecondary"
                      
                        component={RouterLink} to='/our-dealers'
                        className={classes.btnMenuItem}
                       >
                       {props.link6Text}
                    </Link>
                    {globalDealer.id === 480 ? <></> :
                    <TopBar link1Text='Contact Us' link2Text='Book A Service' link3Text='Parts'/>
                }
                </div >
                 }
                </div >

              
             </div>
              </div>
        </div>
    )
}
