import React, { useContext, useEffect } from 'react';
import Container from '@material-ui/core/Container';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import { NavLink as RouterLink, useParams } from 'react-router-dom';
import Link from '@material-ui/core/Link';
import { ImageContainer } from '../shared/ImageContainer'
import ContactComponent from '../shared/ContactComponentParts'
import { DealerContext } from '../shared/contexts/DealerContext'
import { FinanceContext } from '../shared/contexts/FinanceContext'
import axios from 'axios';

const useStyles = makeStyles((theme) => ({
    content: {
        backgroundColor: theme.palette.background.paper,
        padding: theme.spacing(10, 0, 6),
        '@media (max-width: 1024px)': {
            padding: theme.spacing(10, 0, 6),
        },
    },
    dividerTitle: {
        backgroundColor: ({ primaryColor }) => `${primaryColor} `,
        width: '33%',
        marginBottom: '0px',
        height: '3px',
        margin: '20px auto'
    },
    bold: {
        fontWeight: 700
    },
    url: {
        color: '#7c7c7c',
        textDecoration: 'none',
        '&:hover': {
            color: '#1f2532',
            textDecoration: 'none'
        }
    },
    link: {
        backgroundColor: '#E20A17',
        marginBottom: '1rem',
        color: '#ffffff',
        fontWeight: '700',
        '&:hover': {
            textDecoration: 'none',
            opacity: 0.8
        }
    }
}));

const Service = () => {
    const { websiteColors } = useContext(DealerContext)
    const classes = useStyles({ ...websiteColors });
    const {setGlobalModel } = useContext(FinanceContext)
    useEffect(() => {
        setGlobalModel({})
    }, [])
    
    return (
        <Container maxWidth="lg">
            <Box>
                {/*<ImageContainer aspectRatio={(16/6)} height='100%' src={contactUsImage} alt='Contact Us' />*/}
            </Box>
            <div className={classes.content}>
                <Typography gutterBottom variant="h5" align="center" color="textPrimary" fontWeight="500">
                    Genuine Parts Available
                </Typography>
                <Divider className={classes.dividerTitle} />

                <Grid item xs={12} md={2}></Grid>
                <Grid container spacing={2}>
                    <Grid item xs={12} md={1}></Grid>
                    <Grid item xs={12} md={4}>
                        <Typography gutterBottom variant="h5" align="center" color="textPrimary" fontWeight="500">
                            Parts

                        </Typography>
                        <p textAlign="left" fontSize={'1.8rem'}>
                            With dedicated teams of qualified part sales executives at our dealerships, <strong>Eastvaal Motor Group</strong>  is committed to keeping you on the road for longer. Their job is to make sure we can supply you or fit your vehicle with only the best – keeping your vehicle in pristine condition.
                            <br />
                            <br />
                            We only fit and supply authentic and authorized <strong>Eastvaal Motor Group</strong> certified replacement parts and <strong>Eastvaal Motor Group</strong> accessories to your car, bakkie or commercial vehicle.
                            <br />
                            <br />
                            Whatever part you're looking for – we always strive to have all parts available – but if we don’t have the required part in stock – we will do our very best to source the part, and have it available for collection or delivery as soon as possible.
                            <br />
                            <br />
                            We look forward to hearing from you.
                        </p>

                    </Grid>
        
                    
                    <Grid item xs={12} md={1}></Grid>
                    <Grid item xs={12} md={5}>
                        <ContactComponent leadTypeId={6} />
                    </Grid>

                </Grid>
            </div>
        </Container>
    );
}

export default Service;