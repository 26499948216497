import React,{useEffect} from 'react';
import './App.css';
import { Route } from 'react-router-dom';
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import { BrowserRouter as Router } from 'react-router-dom';
import DashboardContainer from './components/MainContainer'
import RenaultLifeWebRegular from './fonts/RenaultLifeWeb-Regular.woff'
import RenaultLifeWebBold from './fonts/RenaultLifeWeb-Bold.woff'
import CssBaseline from '@material-ui/core/CssBaseline';
import { SnackbarProvider } from 'notistack';
import Cookie from './components/shared/CookiePopUp'
import ScrollToTop from './components/shared/ScrollToTop'
import { DealerProvider } from "./components/shared/contexts/DealerContext";
import { MakeProvider } from "./components/shared/contexts/MakeContext";
import { CompareProvider } from './components/shared/contexts/CompareContext';
import { FavouriteProvider } from './components/shared/contexts/FavouriteContext';
import { useHistory } from "react-router-dom";
const renaultRegualar = {
    fontFamily: 'Renault',
    fontStyle: 'normal',
    fontDisplay: 'swap',
    fontWeight: 400,
    src: `
    local('Renault'),
    local('Renault-Regular'),
    url(${RenaultLifeWebRegular}) format('woff')
  `
}

const renaultBold = {
    fontFamily: 'Renault',
    fontStyle: 'normal',
    fontDisplay: 'swap',
    fontWeight: 700,
    src: `
    local('Renault'),
    local('Renault-Bold'),
    url(${RenaultLifeWebBold}) format('woff')
  `
}

const theme = createMuiTheme({
    typography: {
        fontFamily: [
           
            'NanumGothicBBold',
        
        ].join(','),
    },
    overrides: {
        MuiTextField: {
            root: {
                marginBottom: '1rem'
            }
        },
        MuiTypography: {
            h4: {
                fontWeight: 700
            },
            h5: {
                textTransform: 'capitalize',
                fontWeight: 700
            },
            h6: {
                fontWeight: 700
            },
            subtitle2: {
                fontSize: '1rem'
            }
        },
        MuiDialogActions: {
            root: {
                padding: '1.5rem 1rem'
            }
        },
        MuiButton: {
            containedPrimary: {
                backgroundColor: '#39679f',
                color: '#000',
                borderRadius: '0px',
                '&:hover': {
                    backgroundColor: '#39679f',
                    opacity: 0.8
                }
            },
            contained: {
                borderRadius: '0px'
            }
        },
        MuiCssBaseline: {
            '@global': {
                '@font-face': "arial",
                body: {
                    backgroundColor: '#ffffff',
                    fontSize: '100%'
                }
            }
        }
    }
});

function App() {
    let history = useHistory();

    const parsedData = window.location.pathname.split("/");
    var domain = "/"

    if (parsedData.length > 1 && parsedData[1] == "dealer") {
        domain = "/dealer/" + parsedData[2];

        if (parsedData.length > 3 && parsedData[3] == "make") {
            domain += "/make/" + parsedData[4];
        }
    }

    
    /*  if (parsedData.length > 1 && parsedData[1] == "make") {
          domain = "/make/" + parsedData[2];
      }*/
      let currentPathname = window.location.pathname;
      useEffect(() => {
      
        switch (window.location.href) {

            case "https://www.eastvaalsecunda.co.za/":
            
                window.location.replace("https://www.eastvaal.co.za/our-dealers/Eastvaal%20Secunda");
                break;

                case " https://www.eastvaalpotchefstroom.co.za/":
                
                    window.location.replace("https://www.eastvaal.co.za/dealer/eastvaal-potchefstroom-toyota");
                    break;

                    case "https://www.eastvaalmiddelburgford.co.za/":
                       
                        window.location.replace("https://www.eastvaal.co.za/dealer/eastvaal-middelburg-ford");
                        break;

                        case "https://eastvaalbethal.co.za":
                           
                            window.location.replace("https://eastvaal.co.za/our-dealers/Eastvaal%20Bethal");
                            break;
               
                            case "https://www.eastvaalwitbank.co.za/":
                               
                                window.location.replace("https://www.eastvaal.co.za/our-dealers/Eastvaal%20Witbank");
                                break;
                                
                        case "https://www.kohlerauto.com/":
                           
                            window.location.replace("https://www.eastvaal.co.za/dealer/köhler-auto-witbank");
                            break;
                            
                        case "https://www.eastvaaltrucks.co.za/":
                           
                            window.location.replace("https://www.eastvaal.co.za/dealer/eastvaal-witbank-trucks-(tata)");
                            break;

                            case "https://www.eastvaaltrucks.co.za/":
                           
                                window.location.replace("https://www.eastvaal.co.za/dealer/eastvaal-witbank-trucks-(tata)");
                                break;

                                case "https://www.eastvaal.co.za/dealer/eastvaal-motor-city-witbank-hyundai":
                           
                                    window.location.replace("https://www.hyundai.co.za/dealers/witbank");
                                    break;

                                    case "https://www.eastvaal.co.za/dealer/eastvaal-middelburg-hyundai":
                           
                                        window.location.replace("https://www.hyundai.co.za/dealers/middelburg");
                                        break;
                                       
                                        
                                        
            default:
                break;
        }
       

        
      }, [currentPathname])

      useEffect(()=>{
        let currentHost = window.location.host;
        let currentPathname = window.location.pathname;
        const eastvaalHost = "www.eastvaal.co.za";

        const domains = [
            { oldDomain: "eastvaal.co.za", redirectUrl: "https://www.eastvaal.co.za" },
            { oldDomain: "eastvaal.com", redirectUrl: "https://www.eastvaal.co.za" },
            { oldDomain: "eastvaalauto.co.za", redirectUrl: "https://www.eastvaal.co.za" },
            { oldDomain: "eastvaalmotors.co.za", redirectUrl: "https://www.eastvaal.co.za" },
            { oldDomain: "eastvaalstanderton.co.za", redirectUrl: "https://www.eastvaal.co.za" },
            { oldDomain: "eastvalmotors.co.za", redirectUrl: "https://www.eastvaal.co.za" },
            { oldDomain: "middelburgtata.co.za", redirectUrl: "https://www.eastvaal.co.za" },
            { oldDomain: "udbethal.co.za", redirectUrl: "https://www.eastvaal.co.za" },
            { oldDomain: "witbankhonda.co.za", redirectUrl: "https://www.eastvaal.co.za" },
            { oldDomain: "bethalnissan.co.za", redirectUrl: "https://www.eastvaal.co.za/dealer/eastvaal-bethal-nissan" },
            { oldDomain: "bethaldatsun.co.za", redirectUrl: "https://www.eastvaal.co.za/dealer/eastvaal-bethal-datsun" },
            { oldDomain: "bethalrenault.co.za", redirectUrl: "https://www.eastvaal.co.za/dealer/eastvaal-bethal-renault" },
            { oldDomain: "eastvaalbethal.co.za", redirectUrl: "https://www.eastvaal.co.za/dealer/eastvaal-bethal" },
            { oldDomain: "eastvaalmiddelburg.co.za", redirectUrl: "https://www.eastvaal.co.za/dealer/eastvaal-middelburg" },
            { oldDomain: "eastvaalpotchefstroom.co.za", redirectUrl: "https://www.eastvaal.co.za/dealer/eastvaal-potchefstroom" },
            { oldDomain: "eastvaalsecunda.co.za", redirectUrl: "https://www.eastvaal.co.za/dealer/eastvaal-secunda" },
            { oldDomain: "eastvaaltoyota.co.za", redirectUrl: "https://www.eastvaal.co.za/dealer/eastvaal-potchefstroom-toyota" },
            { oldDomain: "eastvaaltrucks.co.za", redirectUrl: "https://www.eastvaal.co.za/dealer/eastvaal-witbank-tata-&-daewoo-trucks" },
            { oldDomain: "eastvaalwitbank.co.za", redirectUrl: "https://www.eastvaal.co.za/dealer/eastvaal-witbank" },
            { oldDomain: "gwmwitbank.co.za", redirectUrl: "https://www.eastvaal.co.za/dealer/eastvaal-witbank-gwm" },
            { oldDomain: "hondamiddelburg.co.za", redirectUrl: "https://www.eastvaal.co.za/dealer/eastvaal-middelburg-honda" },
            { oldDomain: "kohlerauto.com", redirectUrl: "https://www.eastvaal.co.za/dealer/köhler-auto-witbank" },
            { oldDomain: "middelburgford.co.za", redirectUrl: "https://www.eastvaal.co.za/dealer/eastvaal-middelburg-ford" },
            { oldDomain: "middelburghaval.co.za", redirectUrl: "https://www.eastvaal.co.za/dealer/eastvaal-middelburg-haval" },
            { oldDomain: "middelburghonda.co.za", redirectUrl: "https://www.eastvaal.co.za/dealer/eastvaal-middelburg-honda" },
            { oldDomain: "middelburghyundai.co.za", redirectUrl: "https://www.eastvaal.co.za/dealer/eastvaal-middelburg-hyundai" },
            { oldDomain: "middelburgkia.co.za", redirectUrl: "https://www.eastvaal.co.za/dealer/eastvaal-middelburg-kia" },
            { oldDomain: "middelburgrenault.co.za", redirectUrl: "https://www.eastvaal.co.za/dealer/eastvaal-middelburg-renault" },
            { oldDomain: "potchefstroomtoyota.co.za", redirectUrl: "https://www.eastvaal.co.za/dealer/eastvaal-potchefstroom-toyota" },
            { oldDomain: "qlwitbank.co.za", redirectUrl: "https://www.eastvaal.co.za/dealer/eastvaal-quick-lane" },
            { oldDomain: "quicklanewitbank.co.za", redirectUrl: "https://www.eastvaal.co.za/dealer/eastvaal-quick-lane" },
            { oldDomain: "secundaford.co.za", redirectUrl: "https://www.eastvaal.co.za/dealer/eastvaal-secunda-ford" },
            { oldDomain: "secundamazda.co.za", redirectUrl: "https://www.eastvaal.co.za/dealer/eastvaal-secunda-mazda" },
            { oldDomain: "toyotapotchefstroom.co.za", redirectUrl: "https://www.eastvaal.co.za/dealer/eastvaal-potchefstroom-toyota" },
            { oldDomain: "witbankcitroen.co.za", redirectUrl: "https://www.eastvaal.co.za/dealer/eastvaal-witbank-citroen" },
            { oldDomain: "witbankford.co.za", redirectUrl: "https://www.eastvaal.co.za/dealer/eastvaal-witbank-ford" },
            { oldDomain: "witbankgwm.co.za", redirectUrl: "https://www.eastvaal.co.za/dealer/eastvaal-witbank-gwm" },
            { oldDomain: "witbankhaval.co.za", redirectUrl: "https://www.eastvaal.co.za/dealer/eastvaal-witbank-haval" },
            { oldDomain: "witbankhyundai.co.za", redirectUrl: "https://www.eastvaal.co.za/dealer/eastvaal-witbank-hyundai" },
            { oldDomain: "witbankkia.co.za", redirectUrl: "https://www.eastvaal.co.za/dealer/eastvaal-witbank-kia" },
            { oldDomain: "witbankmazda.co.za", redirectUrl: "https://www.eastvaal.co.za/dealer/eastvaal-witbank-mazda" },
            { oldDomain: "witbankpeugeot.co.za", redirectUrl: "https://www.eastvaal.co.za/dealer/eastvaal-witbank-peugeot" },
            { oldDomain: "witbankrenault.co.za", redirectUrl: "https://www.eastvaal.co.za/dealer/eastvaal-witbank-renault" },
            { oldDomain: "witbanksuzuki.co.za", redirectUrl: "https://www.eastvaal.co.za/dealer/eastvaal-witbank-suzuki" },
            { oldDomain: "witbanktata.co.za", redirectUrl: "https://www.eastvaal.co.za/dealer/eastvaal-witbank-tata-&-daewoo-trucks" },
            
        ];

        let currentRedirectUrl = domains?.filter(x => x.oldDomain?.toLocaleLowerCase() == currentHost?.toLocaleLowerCase() ||
            "www." + x.oldDomain?.toLocaleLowerCase() == currentHost?.toLocaleLowerCase())[0];

        if (currentRedirectUrl && currentHost != eastvaalHost) {
            let redirectUrl = currentRedirectUrl?.redirectUrl + currentPathname;
            if (redirectUrl != window?.location?.href) {
                let metaContent = `0;url=${redirectUrl}`;
                document.querySelector('meta[name="redirect"]')?.setAttribute("content", metaContent);
            }
        }
      },[])

    return (
        <Router basename={domain}>
 
            <ScrollToTop />
            <FavouriteProvider>
                <CompareProvider>
                    <ThemeProvider theme={theme}>
                        <SnackbarProvider maxSnack={3} anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'center',
                        }}>
                            <CssBaseline />
                            <DealerProvider>
                            <Cookie />
                                <MakeProvider>
                                    <DashboardContainer />
                                </MakeProvider>
                            </DealerProvider>
                        </SnackbarProvider>
                    </ThemeProvider>
                </CompareProvider>
            </FavouriteProvider>
        </Router>
    );
}

export default App;
