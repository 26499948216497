import React, { useEffect, useContext, useState } from 'react'
import Carousel from './Carousel';
import CardCarousel from '../shared/CardCarousel';
import Card from '../shared/Card';
import ArticleSection from '../shared/ArticleSection';
import BrandList from '../shared/BrandList';
import Grid from '@material-ui/core/Grid';
import Button from '@mui/material/Button';
import Container from '@material-ui/core/Container';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@mui/material/Typography';
import { DealerContext } from '../shared/contexts/DealerContext';
import axios from 'axios';
import { BASE_URI } from '../shared/Constants'
import { useMediaQuery } from 'react-responsive'
import VehicleController from "../usedVehicles/VehicleController";
import Parts from '../shared/assets/parts.jpeg'
import BuyingCar from '../shared/assets/family-buying-guide-header.jpeg'
import Test from '../shared/assets/2023-VW-Amarok-usa.jpeg';
import ContactUs from '../shared/assets/MDH_0005.jpg';

import NewVehicles from '../shared/assets/New Vehicles.png'
import UsedVehicles from '../shared/assets/Used Vehicle.png';
import GetApproved from '../shared/assets/Get Approved.png';
import PartsServices from '../shared/assets/Parts & Services.png';
import { useHistory } from "react-router-dom";

const useStyles = makeStyles({
    container: {
        textAlign: 'center'
    },
    btn: {
        marginTop: '1rem!important'
    },
    cardHolder: {
        display: 'flex'
    }
})

export default function HomePage() {
    const { loading, error, vehicles, total } = VehicleController();

    let { globalDealer } = useContext(DealerContext);
    const [slides, setSlides] = useState([])
    const [desc, setDesc] = useState('')
    let currentDate = new Date()

    const getImageMeta = (url) => {
        return new Promise((resolve) => {
            const img = new Image();
            img.addEventListener("load", function () {
                resolve({
                    width: this.naturalWidth,
                    height: this.naturalHeight
                })
            });
            img.src = url;
        })

    }

    const getSlides = async () => {
        try {
            const params = {
                pageNumber: 0,
                pageSize: 100,
                status: 'active',
                dealerId: globalDealer.id
            }

            const result = await axios({
                method: 'GET',
                url: `${BASE_URI}/slide`,
                params,

            })

            let slides = await Promise.all(result.data.list.map(async (itm) => ({
                img: itm.slideImageUrl,
                imgMeta: await getImageMeta(itm.slideImageUrl),
                content: <div vehiclename={itm.make} name={itm.name} tagline={itm.cta} price={""} url={itm.url} calltoaction={itm.buttonText} category={itm.category} endDate={itm.endDate} startDate={itm.startDate} />
            })))

            // let filteredSlides = slides.sort((a, b) => a.content.props.category - b.content.props.category)
            let filterd = slides.filter(d => d.content.props.startDate <= currentDate.toISOString() && d.content.props.endDate >= currentDate.toISOString())

            setSlides(filterd);


        } catch (error) {

        }
    }

    useEffect(() => {
        getSlides()
        axios.get(`${BASE_URI}/Dealers/DealerContactDetails/${globalDealer.id}`, {
        }).then((result) => {
            setDesc(result.data.description)
        });
    }, [])



    const classes = useStyles();
    const isMobile = useMediaQuery({ query: '(max-width: 624px)' })
    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 912px)' })
    const history = useHistory();

    return (
        <>
            {slides && slides.length > 0 && <Carousel
                naturalSlideWidth={slides[0].imgMeta.width}
                naturalSlideHeight={isMobile ? 90 : isTabletOrMobile ? 33 : slides[0].imgMeta.height}
                totalSlides={slides.length}
                isPlaying={true}
                infinite={slides.length > 1}
                slides={slides} />
            }

            <Grid container className={classes.cardHolder}>
                <Grid item xs={12} sm={6} md={3}>
                    <Card align="center" image={NewVehicles} heading="New Vehicles" ctatext="VIEW MORE" ctatextlink="/our-vehicles" />
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                    <Card align="center" image={UsedVehicles} heading="Used Vehicles" ctatext="VIEW MORE" ctatextlink="/used-vehicles" />
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                    <Card align="center" image={GetApproved} heading="Get Approved" ctatext="VIEW MORE" ctatextlink="/apply-for-finance" />
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                    <Card align="center" image={PartsServices} heading="Parts & Services" ctatext="VIEW MORE" ctatextlink="/service" />
                </Grid>
            </Grid>

            <Container className={classes.container}>

                <ArticleSection margin='4rem 0' image={BuyingCar} heading="Welcome to Eastvaal Motor Group" body={desc} cardType="Simple" />

                <ArticleSection margin='4rem 0' image={Test} heading="About Us" subheading="" body="Eastvaal Motors was founded in 1968. Eastvaal motors first got listed on the Johannesburg Stock Exchange in 1988. Majority shareholding was purchased and the company de-listed in 2004. Eastvaal Motors since expanded to a footprint of 20 franchises, with 300 staff members, in Mpumalanga and the North West Province of South Africa.

Our Toyota dealership has been Toyota Dealer of the year three times and we have the largest Ford non-metro dealers." direction="row-reverse" cardType="Simple" />

                <Button variant="contained" onClick={() => { history.push(`/our-vehicles`) }}>BROWSE NEW VEHICLES</Button>

                <BrandList />

                <Typography gutterBottom variant="h2" component="div">Pre-Loved Vehicles</Typography>

                <CardCarousel height="100%" align="left" vehicles={vehicles} cardType="Vehicle" ctatextlink="/stock" />

                <Button className={classes.btn} variant="contained" onClick={() => { history.push(`/used-vehicles`) }}>VIEW MORE</Button>

                <ArticleSection margin='4rem 0' direction="row-reverse" image={Parts} heading="Parts & Services" body="With dedicated teams of qualified part sales executives at our dealerships, Eastvaal Motor Group is committed to keeping you on the road for longer. Their job is to make sure we can supply you or fit your vehicle with only the best – keeping your vehicle in pristine condition.

We only fit and supply authentic and authorized Eastvaal Motor Group certified replacement parts and Eastvaal Motor Group accessories to your car, bakkie or commercial vehicle.

Whatever part you're looking for – we always strive to have all parts available – but if we don't have the required part in stock – we will do our very best to source the part, and have it available for collection or delivery as soon as possible." ctatext="Parts" ctatext2="Services" cardType="Complex" />

                <ArticleSection margin='4rem 0' body=" " image={ContactUs} cardType="Form" heading="Contact Us" />

            </Container>


        </>
    )
}
