export const BASE_URI = "https://c2-marketdealer-leadcentre.azurewebsites.net/api"
//export const BASE_URI = "http://localhost:56746/api"
export const SELF_URI = getSelfUri()
export const WEPAY4CARS_URI = "https://c2-wepay4cars-marketdealer.azurewebsites.net/api";
export const PAGE_SIZE= 20;
export const HAS_MULTIPLE_DEALERSHIPS = false
export const QUALIFYING_FINANCE_PERCENTAGE = 0.33;

export const DEALERID = 271
export const MASTER_DEALERID = 271
export const MASTER_CONFIGURATOR_DEALERID = 286
export const MASTER_DEALER_NAME = "Eastvaal Master"
export const MOTORGROUPID = 38
export const SECONDARYCOLOR = "white"
export const BASECOLOR = "white"

export const VIRTUAL_URI_RANGE = `https://configurator.${SELF_URI.replace("http://", "").replace("https://", "").replace("www.", "")}/${DEALERID}/range/:leadId`
export const VIRTUAL_URI_DERIVATIVE = `https://configurator.${SELF_URI.replace("http://", "").replace("https://", "").replace("www.", "")}/${DEALERID}/:modelId/builder/:leadId`
export const VIRTUAL_URI_APPLICATION = `https://configurator.${SELF_URI.replace("http://", "").replace("https://", "").replace("www.", "")}/${DEALERID}/1/vehicle/finance/0/:leadId`

export const FINAPP_URI_BASE = "https://dz6rhlcy38qhy.cloudfront.net";
export const FINAPP_URI_APPLICATION = `${FINAPP_URI_BASE}/${DEALERID}/1/vehicle/finance/0/:leadId`;

function getSelfUri() {
  return window.location.origin;
}