import React, { useContext, useEffect,useState } from 'react';
import Container from '@material-ui/core/Container';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import { NavLink as RouterLink, useParams } from 'react-router-dom';
import Link from '@material-ui/core/Link';
import { ImageContainer } from '../shared/ImageContainer'
import ContactComponent from '../shared/ContactComponentService'
import { DealerContext } from '../shared/contexts/DealerContext'
import { FinanceContext } from '../shared/contexts/FinanceContext'
import { MakeContext } from '../shared/contexts/MakeContext'
import axios from 'axios';
import ServicesImage from '../shared/assets/service.jpg';
import BreakeServices from '../shared/assets/Brake-Specials-5232-1.jpg';
import MultiCheckPointServices from '../shared/assets/Multi-Point-Check-5231-1.jpg';
import OilChangeServices from '../shared/assets/Oil-Change-Service-5233-1.jpg';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import VehicleController from '../ourVehiclesPage/VehicleController';

const useStyles = makeStyles((theme) => ({
    content: {
        backgroundColor: theme.palette.background.paper,
        padding: theme.spacing(10, 0, 6),
        '@media (max-width: 1024px)': {
            padding: theme.spacing(10, 0, 6),
        },
    },
    dividerTitle: {
        backgroundColor: ({ primaryColor }) => `${primaryColor}`,
        width: '33%',
        marginBottom: '0px',
        height: '3px',
        margin: '20px auto'
    },
    bold: {
        fontWeight: 700
    },
    card: {
        margin: "20px 0px"
    },
    url: {
        color: '#7c7c7c',
        textDecoration: 'none',
        '&:hover': {
            color: '#1f2532',
            textDecoration: 'none',

        }
    },
    xlbuttonFix: {
        paddingRight: '65px !important',
        fontWeight: 'lighter !important',
        textTransform: 'capitalize !important', backgroundColor: ({ primaryColor }) => `${primaryColor} !important`,

    },
    link: {
        backgroundColor: '#E20A17',
        marginBottom: '1rem',
        color: '#ffffff',
        fontWeight: '700',
        '&:hover': {
            textDecoration: 'none',
            opacity: 0.8
        }
    },
    ServiceHolder:{
        display:'flex',
        flexWrap: 'wrap',
        alignItems: 'center',
        '@media (max-width: 780px)': {
            flexFlow: 'column',
        }
    }
}));

const Service = () => {
    const { websiteColors } = useContext(DealerContext)
    const classes = useStyles({ ...websiteColors });
    const {setGlobalModel } = useContext(FinanceContext)
    const [groupedMakes, setGroupedMakes] = useState()
    const [models, setModels] = useState([])
    const { globalDealer } = useContext(DealerContext)
    const { loading, error, vehicles, total } = VehicleController()
    const { make, setActiveGlobalMake, makeColors } = useContext(MakeContext)
    const findMake = (make) => {

        if (!globalDealer.makes) {
            return;
        }

        let foundMakes = globalDealer.makes.filter(itm => itm.name.toLowerCase() == make.toLowerCase());

        return foundMakes.length > 0;
    }

    useEffect(() => {
        let filteredVehicles = vehicles.filter(itm => findMake(itm.make));
        let makes = groupBy(filteredVehicles, 'make');
    
        setGroupedMakes(makes);
    }, [vehicles])

    const groupBy = (xs, key) => {
        return xs.reduce(function (rv, x) {
            (rv[x[key]] = rv[x[key]] || []).push(x);
            return rv;
        }, {});
    };

    useEffect(() => {
        setGlobalModel({})
    }, [])
    
    useEffect(() => {
        if(globalDealer.id == 210 ){
            window.location.href = "https://renaultcarcare.co.za/book-a-service?dealership=08cbba27d8f6436399e413c11143eada";
        }
        if(globalDealer.id == 213){
            window.location.href = "https://renaultcarcare.co.za/book-a-service?dealership=f5eae88a17af4ee8bc713571fe4abdd8";
        }
        if(globalDealer.id == 201){
            window.location.href = "https://renaultcarcare.co.za/book-a-service?dealership=ae4ebec67c7f49f58ccb566d8061838d";
        }
    }, [globalDealer])
    
    return (
        <Container maxWidth="lg">
            <Box>
                {/*<ImageContainer aspectRatio={(16/6)} height='100%' src={contactUsImage} alt='Contact Us' />*/}
            </Box>
            <div className={classes.content}>
                <Typography gutterBottom variant="h5" align="center" color="textPrimary" fontWeight="500">
                    Book a service
                </Typography>
                <Divider className={classes.dividerTitle} />
                <br />

                <Grid item xs={12} md={2}></Grid>
                <Grid container spacing={2}>
                    <Grid item xs={12} md={4}>
                        <ContactComponent leadTypeId={5} />

                    </Grid>
                    <Grid item xs={12} md={7}>
                    <Grid item xs={12} md={12}>
                    <Typography gutterBottom variant="h5" align="center" color="textPrimary" fontWeight="500">
                    We service the following brands
                 
                    </Typography>
                    </Grid>
                    
                    <Container className={classes.ServiceHolder} maxWidth="lg">
                    {groupedMakes && Object.keys(groupedMakes).map((makeName, index) => {

                        let models = groupedMakes[makeName];
                        let tmpMake = globalDealer?.makes?.find((m) => {
                            return m?.name?.toLowerCase() === makeName?.toLowerCase()
                        })

                        return (
                            <>

                                <Grid item xs={12} sm={6} md={3} key={index} className={classes.card}>
                                    {tmpMake?.logo && <Box><img className={classes.image} width='100px' height="fit-content" src={tmpMake?.logo} /> </Box>}
                                
                                </Grid>
                            </>)
                        })}
                       </Container> 
                    </Grid>
                 
                </Grid>
            </div>
        </Container>
    );
}

export default Service;