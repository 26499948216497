import React from 'react'
import Button from '@mui/material/Button';
import { makeStyles } from '@material-ui/core/styles';
import Link from '@mui/material/Link';
import { NavLink as RouterLink } from 'react-router-dom';

const useStyles = makeStyles({
    root:{
     textAlign:'right',
     textDecoration:'none!important',
     top:'0px',
     zIndex:30,

    
    },
    btn:{
        margin:'0.5rem!important',
        textDecoration:'none!important',
        fontSize:'12px!important',
        padding: '5px 5px!important',
          margin:'10px 5px!important',
    }
})

export default function TopBar(props) {
    const classes = useStyles();
    return (
        <div className={classes.root}>
             <Link variant="button" color="textSecondary"
                      component={RouterLink} to='/contact-us'
                     >
             <Button 
            variant="outlined"
            className={classes.btn}
            >
                     {props.link1Text}
            </Button>
            </Link>
            <Link variant="button" color="textSecondary"
                      component={RouterLink} to='/service'
                     >
             <Button 
            variant="outlined"
            className={classes.btn}
            >
                     {props.link2Text}
            </Button>
            </Link>
             <Link variant="button" color="textSecondary"
                      component={RouterLink} to='/parts'
                     >
             <Button 
            variant="outlined"
            className={classes.btn}
            >
                     {props.link3Text}
            </Button>
            </Link>
      
        </div>
    )
}
