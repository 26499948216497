import React, { useState, useEffect, useContext } from 'react';
import Container from '@material-ui/core/Container';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import NewVehicleController from '../ourVehiclesPage/VehicleController';
import { DealerContext } from '../shared/contexts/DealerContext'
import { MakeContext } from '../shared/contexts/MakeContext'
import { MASTER_DEALERID } from "./Constants"

const useStyles = makeStyles((theme) => ({
    content: {
        backgroundColor: theme.palette.background.paper,
        padding: theme.spacing(8, 0, 6),
    },
    testParam: {
        backgroundColor: ({ primaryColor }) => `${primaryColor} !important`,
    },
    dividerTitle: {
        backgroundColor: ({ primaryColor }) => `${primaryColor} !important`,
        width: '33%',
        marginBottom: '30px',
        height: '3px',
        margin: '20px auto'
    },
    bold: {
        fontWeight: 700
    },
    title: {
        fontSize: '1.5rem',
        '&>span': {
            fontWeight: 400
        }
    },
    vehicleContainer: {
        '& h5, & p': {
            color: '#7c7c7c',
        },
        '&:hover img, &:hover p, &:hover h5': {
            color: '#1f2532',
            cursor: 'pointer',
            textDecoration: 'none'
        }
    },
    btn: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    image: {
        width:"50%",
        '@media (max-width: 780px)': {
            width:"30%",
        },
        '& > div > div': {
            backgroundColor: '#ffffff !important'
        }
    },
    Carimage:{
        maxWidth:"350px",
        maxHeight:"350px",
        width:"350px",
        height:"350px",
        display:'block',
        margin:'0 auto',
        '@media (max-width: 780px)': {
            flexDirection: '100%'
        }
    },
    card: {
        textAlign: "center",
        fontWeight: 'bold',
        display:'flex',
        justifyContent:'center',
        minHeight:'200px',
        alignItems:'center',
        cursor:'pointer',
        '&:hover': {
            backgroundColor: 'aliceblue!important'
        }
    }
}));

const BrandList = () => {
    const { make, setActiveGlobalMake, makeColors } = useContext(MakeContext)
    let [colors,setColors]=useState()
    let classes = useStyles({ ...makeColors });
  
    const OUR_VEHICLES_PAGE_PATH = '/our-vehicles';

    const { loading, error, vehicles, total } = NewVehicleController()
    const [groupedMakes, setGroupedMakes] = useState()
    const [models, setModels] = useState([])
    const { globalDealer } = useContext(DealerContext)


    useEffect(() => {
        let filteredVehicles = vehicles.filter(itm => findMake(itm.make));
        let makes = groupBy(filteredVehicles, 'make');
        setGroupedMakes(makes);
        
    }, [vehicles, globalDealer.id])

    const cleanName = (dealerName) => {
        return dealerName.toLowerCase().replace(/\//g, '-').replace(/ /g, '-')
    }

    const handleMakeClick = (make, makeName) => {
        setActiveGlobalMake(make);
     
         if(globalDealer.name !== MASTER_DEALERID){

            window.location.href=`/dealer/${cleanName(globalDealer.name)}/our-vehicles`
             
         }else{
            window.location.href='/our-vehicles'
         }
   
    }

    const findMake = (make) => {

        if (!globalDealer.makes) {
            return;
        }

        let foundMakes = globalDealer.makes.filter(itm => itm.name?.toLowerCase() === make?.toLowerCase());

        return foundMakes.length > 0;
    }

    const groupBy = (xs, key) => {
        return xs.reduce(function (rv, x) {
            (rv[x[key]] = rv[x[key]] || []).push(x);
            return rv;
        }, {});
    };

    return (
        <Container maxWidth="lg">
            <div className={classes.content}>
               
             
                <Grid container spacing={2} style={{display:'flex',justifyContent:'center'}}>
                   

                        {groupedMakes && Object.keys(groupedMakes).map((makeName, index) => {

                            let models = groupedMakes[makeName];
                            let tmpMake = globalDealer?.makes?.find((m) => {
                                return m?.name?.toLowerCase() === makeName?.toLowerCase()
                            })

                            return (
                                <>

                                    <Grid item xs={12} sm={6} md={3} key={index} className={classes.card} onClick={() => { handleMakeClick(tmpMake, makeName) }}>
                                    {tmpMake?.logo && <Box style={{display:'flex',alignContent:'center',justifyContent:'center'}}><img className={classes.image} width='60px' height="fit-content" src={tmpMake?.logo} /> </Box>}

                                    </Grid>
                                </>)
                        })}
                   

              
                </Grid>
            </div>
        </Container>
    )
}

export default BrandList;