import React from 'react';
import Typography from '@material-ui/core/Typography';
import CardActionArea from '@material-ui/core/CardActionArea';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import { CurrencyValue } from '../shared/TextMask';

export const DealCard = ({ classes, image, title, subtitle, onClick}) => {
  return (
    <CardActionArea className={classes.actionArea} onClick={onClick}>
      <Card className={classes.card}>
        {/*classes={mediaStyles}*/}
        <CardMedia className={classes.image} image={image} />
        {/* <CardContent  className={classes.content}>
          <Typography className={classes.title} variant={'h2'}>
            {title}
          </Typography>
                  <Typography className={classes.subtitle}>From <CurrencyValue value={subtitle} />pm*</Typography>
        </CardContent> */}
      </Card>
    </CardActionArea>
  );
};