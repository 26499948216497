import React, { useState, useCallback, useRef,useEffect,useContext } from 'react';
import Grid from '@material-ui/core/Grid';
import GridList from '@material-ui/core/GridList';
import GridListTile from '@material-ui/core/GridListTile';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';
import { DealerContext } from '../shared/contexts/DealerContext'
import axios from 'axios';
import Divider from '@material-ui/core/Divider';
import Link from '@material-ui/core/Link';
// import Color from 'color';
import {DealCard} from './DealCard'
import DealsFilter from './DealsFilter'
import { FinanceContext } from '../shared/contexts/FinanceContext'
import { NavLink as RouterLink, useParams,useHistory } from 'react-router-dom';
import {CurrencyValue} from '../shared/TextMask'
import CircularProgress from '@material-ui/core/CircularProgress';

const useStyles = makeStyles((theme) => ({
  background: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(10, 0, 6),
    '@media (max-width: 1024px)': {
      padding: theme.spacing(10, 0, 6),
  },
  },
  actionArea: {
    borderRadius: 16,
    height: '100%',
    transition: '0.2s',
    '&:hover': {
      transform: 'scale(1.1)',
    },
  },
  card: ({ color }) => ({
    minWidth: 256,
    height: '100%',
    borderRadius: 5,
    boxShadow: 'none',
    '&:hover': {
      // boxShadow: `0 6px 12px 0 ${Color(color)
      //   .rotate(-12)
      //   .darken(0.2)
      //   .fade(0.5)}`,
    },
  }),
  dividerTitle: {
    backgroundColor: '#5490d9',
    width: '33%',
    marginBottom: '30px',
    height: '3px',
    margin: '20px auto'
},
  content: ({ color }) => {
    return {
      backgroundColor: '#39679f',
      padding: '1rem 1.5rem 1.5rem',
      textAlign: 'center',
      height: '100%'
    };
  },
  title: {
    fontSize: '2rem',
    color: 'white',
    textTransform: 'uppercase',
  },
  subtitle: {
      color: 'white',
    opacity: 0.87,
    marginTop: '1.2rem',
    fontWeight: 500,
    fontSize: 20,
  },
  image: {
    minHeight: 320,
    backgroundSize:"100% 100%",
    '@media(min-width: 0px) and (max-width: 425px)': {
      minHeight: 236,
  },
  '@media(min-width: 426px) and (max-width: 900px)': {
    minHeight: 350,
  },  
  },

  noDeals:{
    textAlign:'center',
    padding:'4rem 0rem'
  }
}));

const useGridStyles = makeStyles(({ breakpoints }) => ({
  root: {
    [breakpoints.up('md')]: {
      // justifyContent: 'center',
    },
  },
}));


export default function SpecialDealsComponent() {
  const classes = useStyles();
  const gridStyles = useGridStyles({ color: '#ff9900' });
  const history = useHistory();
  const [sortDirection, setSortDirection] = useState('Desc')
  const [pageNumber, setPageNumber] = useState(1)
  
  const {setGlobalModel } = useContext(FinanceContext)
  const{
    loading,
    deals,
    hasMore
  }= DealsFilter(pageNumber, sortDirection)
  const observer = useRef()
  const lastDealRef = useCallback(node => {
    // if (loading) return
    // if (observer.current) {
    //   observer.current.disconnect()
    // }
    // observer.current = new IntersectionObserver(entries => {
    //   if (entries[0].isIntersecting && hasMore) {
    //     setPageNumber(prevPageNumber => prevPageNumber + 1)
    //   }
    // })
    // if (node) {
    //   observer.current.observe(node)
    // }
  }, [loading, hasMore])

    const cleanModel = (model) => {

        return model.toLowerCase().replace('/', '-')
    }
    useEffect(() => {
      setGlobalModel({})
  }, [])
    
  return (
    <React.Fragment>
      <main>
        <Container maxWidth="lg">
          <div className={classes.background}>
        
            <Typography
                        gutterBottom
                        variant="h5"
                        align="center"
                        color="textPrimary"
                        fontWeight="500"
                    >
                        SPECIAL DEALS
                    </Typography>
                    <Divider className={classes.dividerTitle} />
            <Grid classes={gridStyles} container spacing={4}>
              {
                (!loading && deals.length > 0) &&
                  deals.map((deal) =>
                    <Grid item key={deal.id} xs={12} sm={12} md={6}  >
                          <DealCard ref={lastDealRef}
                              onClick={() => history.push(`special-deals/${cleanModel(deal.model)}/${deal.ownedModelId}/${deal.id}`)}
                                classes={classes}
                              
                               
                                image={deal.imageUrl}
                      />
                    </Grid>
                  )
              }
              {
                (deals.length === 0) &&
                  <Grid item xs={12}>
                    <Typography gutterBottom variant="h5" color="textPrimary" fontWeight="500" className={classes.noDeals}>
                      <Box  style={{ textTransform: 'capitalize' }}>We are busy reviewing our specials<br/>please check back soon to see what's on offer.</Box>
                    </Typography>
                  </Grid>
              }
              {
                loading &&
                <Grid item container justify='center' alignContent='center' xs={12}>
                  <CircularProgress size={80} />
                </Grid>
              }
            </Grid>
          </div>
        </Container>
      </main>
    </React.Fragment>
  );
}