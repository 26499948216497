export const bodyShops = [
    {
        city: 'Alberton',
        company: [
            {
                name: 'Randow Panelbeaters',
                contactPerson: 'Riaan Landman',
                contactNumber: '010 216 1600',
                address: '48 Radio Road, Alberton North'
            }
        ]
    },
    {
        city: 'Benoni',
        company: [
            {
                name: 'Cranbourne Panelbeaters',
                contactPerson: 'Mike Holtzhausen',
                contactNumber: '011 421 9445',
                address: '11 Cranbourne Avenue, Benoni'
            },
            {
                name: 'Danmar Autobody Benoni',
                contactPerson: 'Fernando Fernandes',
                contactNumber: '011 973 2878/9',
                address: '3 Goud Street, Rietpan, Benoni'
            }
        ]
    },
    {
        city: 'Boksburg',
        company: [
            {
                name: 'East Rand Motor Lab',
                contactPerson: 'Alicia Padiachy',
                contactNumber: '011 823 2699',
                address: 'Unit C, Centric Business Park, Romeo Road, Hughes, Boksburg'
            },
            {
                name: 'Imperial Autobody Jet Park',
                contactPerson: 'Craig Soares',
                contactNumber: '011 396 6900',
                address: '40 Springbok Road, Barlett, Boksburg'
            },
            {
                name: 'Italian Panelbeaters',
                contactPerson: 'Fabrizio Caluri',
                contactNumber: '011 914 2092',
                address: '32 All Black Road, Anderbolt, Boksburg'
            },
            {
                name: 'M F Autobody',
                contactPerson: 'Gloria da Silva',
                contactNumber: '011 823 3361',
                address: '38 North Rand Road, Hughes, Boksburg'
            }
        ]
    },
    {
        city: 'Brakpan',
        company: [
            {
                name: 'Chako Panelbeaters',
                contactPerson: 'Anel Lategan',
                contactNumber: '011 744 3520',
                address: '99 Hoy Avenue, Brakpan'
            }
        ]
    },
    {
        city: 'Edenvale',
        company: [
            {
                name: 'Excelsior Panelbeaters',
                contactPerson: 'Paul van Aarde',
                contactNumber: '011 609 3601',
                address: '23 Central Avenue, Eastleigh, Edenvale'
            }
        ]
    },
    {
        city: 'Germiston',
        company: [
            {
                name: 'CMC Auto Body',
                contactPerson: 'Amelda Garcao',
                contactNumber: '011 873 8009',
                address: '83 President Street, Germiston'
            }
        ]
    },
    {
        city: 'Honeydew',
        company: [
            {
                name: 'Auto Gallery Panelbeaters',
                contactPerson: 'Sarah Maio',
                contactNumber: '011 794 3705',
                address: '5 Star Office Park, Juice Street, Honeydew'
            }
        ]
    },
    {
        city: 'Isando',
        company: [
            {
                name: 'Denver Auto Body Repairers',
                contactPerson: 'Anita De Castro',
                contactNumber: '011 974 1177',
                address: '7 Electron Avenue, Isando'
            }
        ]
    },
    {
        city: 'Johannesburg',
        company: [
            {
                name: 'Danmar Autobody Ophirton',
                contactPerson: 'Mohamed Gaffoor',
                contactNumber: '011 377 0300',
                address: '39 Earp Street, Ophirton, Johannesburg'
            },
            {
                name: 'Great South Autobody',
                contactPerson: 'Claudia Voget',
                contactNumber: '011 493 3695',
                address: '10 Wagner Street, Booysens'
            },
            {
                name: 'Hofbro Auto Body',
                contactPerson: 'Larri Paiken',
                contactNumber: '011 493 8380',
                address: '37 Bosman Road, Ophirton'
            },
            {
                name: 'Motor Accident Group',
                contactPerson: 'Stephanie Hewetson',
                contactNumber: '011 493 9160',
                address: '80 Booysens Road, Selby'
            }
        ]
    },
    {
        city: 'Kempton Park',
        company: [
            {
                name: 'Action Panelbeaters',
                contactPerson: 'Lizette Walker',
                contactNumber: '011 975 0422',
                address: '126 Plane Road, Spartan, Kempton Park'
            }
        ]
    },
    {
        city: 'Krugersdorp',
        company: [
            {
                name: 'Barney\'s Panelbeaters & Truck Services',
                contactPerson: 'Leon Croucamp',
                contactNumber: '011 955 3561',
                address: '11 Wright Street, Factoria, Krugersdorp'
            },
            {
                name: 'Krugersdorp Panelbeaters',
                contactPerson: 'Nolene Evans',
                contactNumber: '011 660 2319',
                address: '23 Mill Street, Boltonia, West Krugersdorp'
            },
            {
                name: 'Rubicon Auto Body',
                contactPerson: 'Nick Mostert',
                contactNumber: '011 953 1978',
                address: '227 Luipaard Street, Krugersdorp'
            }
        ]
    },
    {
        city: 'Lenasia',
        company: [
            {
                name: 'FR Auto Panelbeaters',
                contactPerson: 'Fazil Hoosein / Jameel Ameer',
                contactNumber: '011 857 1471',
                address: 'Main Lawley Road, Anchorville, Lenasia'
            }
        ]
    },
    {
        city: 'Longmedow',
        company: [
            {
                name: 'Danmar Autobody Longmeadow',
                contactPerson: 'George Van Niekerk',
                contactNumber: '011 553 1300',
                address: 'Cnr Hereford & Modderfontein Roads, Longmeadow'
            }
        ]
    },
    {
        city: 'Meadowdale',
        company: [
            {
                name: 'Danmar Autobody Meadowdale',
                contactPerson: 'Tony Da Silva',
                contactNumber: '011 454 1920/ 3',
                address: '269A Flemming Road, Meadowdale'
            }
        ]
    },
    {
        city: 'Midrand',
        company: [
            {
                name: 'Cornright Motors',
                contactPerson: 'Peter Corna',
                contactNumber: '011 312 1111',
                address: '290 New Road, Midrand'
            },
            {
                name: 'Sanrose Motors & Panelbeaters',
                contactPerson: 'Jon Cooper-Baldo',
                contactNumber: '011 805 1501',
                address: '79 Capital Hill, Le Roux Avenue, Midrand'
            },
            {
                name: 'Lehlobo Autobody Repairers',
                contactPerson: 'Earl Glennister',
                contactNumber: '011 472 0404',
                address: '1040 Katrol Avenue, Robertville'
            }
        ]
    },
    {
        city: 'Robertville',
        company: [
            {
                name: 'S&E Panelbeaters',
                contactPerson: 'Earl Glennister',
                contactNumber: '011 472 0404',
                address: '1040 Katrol Avenue, Robertville'
            }
        ]
    },
    {
        city: 'Roodepoort',
        company: [
            {
                name: 'Danmar Autobody Westrand',
                contactPerson: 'Corrie Strydom',
                contactNumber: '011 472 3993',
                address: '1014 Anvil Road, Robertville'
            }
        ]
    },
    {
        city: 'Sandton',
        company: [
            {
                name: 'Danmar Autobody Sandton',
                contactPerson: 'Andre Kruger',
                contactNumber: '011 386 0300',
                address: '17 Lees Street, Wynberg'
            },
            {
                name: 'North City Panelbeaters',
                contactPerson: 'Gerrie Pretorius',
                contactNumber: '011 262 3400',
                address: '11 Dartfield Road, Sandton'
            }
        ]
    },
    {
        city: 'Springs',
        company: [
            {
                name: 'Mini/Maxi Panelbeaters',
                contactPerson: 'Eliso Miranda',
                contactNumber: '011 815 6470',
                address: '45 South Main Reef Road, New Era, Springs'
            }
        ]
    },
    {
        city: 'Strijdom Park',
        company: [
            {
                name: 'Denttech Panelbeaters',
                contactPerson: 'Jacques Aylward',
                contactNumber: '011 791 2291',
                address: '49 Fabriek Street, Strijdompark'
            },
            {
                name: 'Renew-It, Randburg',
                contactPerson: 'Gavin Naidoo',
                contactNumber: '011 792 2350',
                address: 'Cnr. Hammer Avenue & Gerhardus, Strijdompark'
            }
        ]
    },
    {
        city: 'Trojan',
        company: [
            {
                name: 'Trojan Panelbeaters',
                contactPerson: 'Armando Oliveira',
                contactNumber: '011 683 8138',
                address: '63 La Rochelle Road, Trojan, Johannesburg'
            }
        ]
    },
    {
        city: 'Wynberg',
        company: [
            {
                name: 'B B Cars',
                contactPerson: 'Warren Barwell',
                contactNumber: '011 887 3120',
                address: '18, 5th Street, Wynberg'
            },
            {
                name: 'Car World Panelbeating',
                contactPerson: 'Dion Scanlen',
                contactNumber: '011 887 3266',
                address: '321, 5th Street, Wynberg'
            },
            {
                name: 'Wynberg Panelbeaters',
                contactPerson: 'Desire Willis',
                contactNumber: '011 887 0357',
                address: '670 Main Pretoria Road, Wynberg'
            }
        ]
    }
]