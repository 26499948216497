import React from 'react'
import ArticleCard from '../shared/ArticleCard';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
    root:{
        background:props => props.color,
        display: 'flex!important',
        flexDirection:props => props.direction,
        margin:props => props.margin,
        alignItems:props => props.align,
    },
    imgHolder:{
        position:'relative',
        zIndex:'20px',
        margin:'0px -20px -50px -20px',
        '@media (max-width: 1024px)': {
            margin:'0px',
        },
    },
    img:{
        boxShadow: '0px 16px 16px rgba(33, 61, 143, 0.05), 0px 8px 8px rgba(33, 61, 143, 0.05), 0px 2px 2px rgba(33, 61, 143, 0.05), 0px 4px 4px rgba(33, 61, 143, 0.04)',
        borderRadius:'8px'
    }
})
export default function ArticleSection(props) {
    const classes = useStyles(props);
 
    return (
      
          
               <Grid container className={classes.root}>
                    <Grid item xs={12} sm={7} md={4} className={classes.imgHolder}>
                        {props.cardType === "Simple" && <ArticleCard align={props.align} heading={props.heading} body={props.body} subheading={props.subheading} ctatext={props.ctatext} cardType={props.cardType} modelInfo={props.modelInfo}/>}
                        {props.cardType === "Complex" && <ArticleCard align={props.align} heading={props.heading} body={props.body} subheading={props.subheading} ctatext={props.ctatext} ctatext2={props.ctatext2} cardType={props.cardType} button={props.button} />}
                        {props.cardType === "Form" && <ArticleCard align={props.align} heading={props.heading} body={props.body} subheading={props.subheading} ctatext={props.ctatext} ctatext2={props.ctatext2} cardType={props.cardType}/>}
                    </Grid>
                    <Grid item xs={12} sm={5} md={8}>
                        <img src={props.image} width="100%" alt="test" className={classes.img} />
                    </Grid>
                </Grid>
               
       
    )
}
