import React from 'react'
import { CarouselProvider, Slider, Slide, ButtonBack, ButtonNext, DotGroup, Image } from 'pure-react-carousel';
import 'pure-react-carousel/dist/react-carousel.es.css';
import { makeStyles } from '@material-ui/core/styles';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import SendIcon from '@mui/icons-material/ArrowRight';
import { NavLink as RouterLink } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
    root: {
        position: 'relative',
        maxHeight: "100vh"
    },
    carouselBack: {
        left: '10px',
        background: '#000000',
        color: 'white',
        height: '50px',
        opacity: '0.5',
        width: '50px',
        position: 'absolute',
        top: '50%',
        transform: 'translateY(-50%);',
        all: 'unset',
        cursor: 'pointer',

        '@media (max-width: 624px)': {
            height: '50px',
            width: '50px',
        },
    },
    carouselNext: {
        right: '10px',
        background: '#000000',
        opacity: '0.5',
        color: 'white',
        height: '50px',
        width: '50px',
        position: 'absolute',
        top: '50%',
        transform: 'translateY(-50%);',
        all: 'unset',
        cursor: 'pointer',

        '@media (max-width: 624px)': {
            height: '50px',
            width: '50px',
        },
    },
    carouselDots: {
        display: 'flex',
        justifyContent: 'center',
        marginTop: '15px',
        marginBottom: '15px',
        '& > button': {
            width: '15px',
            height: '15px',
            borderRadius: '50%',
            border: 'none',
            padding: '0',
            cursor: 'pointer',
            backgroundColor: '#EFDF00',
            marginRight: '10px',
        }
    },
    backgroundImage: {
        backgroundPosition: 'center center',
        backgroundSize: '100% 100%!important',
        '@media (max-width: 624px)': {
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'top',
            height: '100%'
        },
    },
    slide: {
        // paddingBottom: '9%!important',

    },
    cta: {
        position: 'absolute',
        bottom: '6rem',
        right: "6rem",
        display: 'flex',
        alignItems: 'center',
        '@media (max-width: 624px)': {

            display: 'none'
        },
    },
    ctaText: {
        margin: '0px 1rem'
    },
    ctabtn: {
        height: '100%',
        marginTop: '5rem !important'
    },
    endIcon: {
        marginRight: '0 !important',
        marginLeft: '0 !important'
    }

}));


const Carousel = ({ slides, innerClass, dots, ...props }) => {
    const classes = useStyles();
    console.log('Carousel:: PROPS', props, "slides", slides, 'innerClass', innerClass, 'dots', dots)
    return (
        <CarouselProvider
            {...props}
        >
            <div className={classes.root}>
                <Slider>
                    {
                        slides && slides.map((slide, index) =>
                            <Slide innerClassName={innerClass} className={classes.slide} key={index}>

                                {slide.content ?
                                    <>
                                        <div className={classes.cta}>
                                            <div className={classes.ctaText}>
                                                <Typography variant="h6" component="div">{slide.content.props.name}</Typography>
                                                <Typography variant="h3" component="div">{slide.content.props.tagline}</Typography>
                                            </div>
                                            <Button component={RouterLink} to={slide.content.props.url} className={classes.ctabtn} classes={{endIcon: slide.content.props.calltoaction.trim().length === 0 ? classes.endIcon : undefined}} endIcon={<SendIcon />} variant="contained">{slide.content.props.calltoaction.trim()}</Button>
                                        </div>
                                        <Image tag="div" isBgImage src={slide.img ? slide.img : slide} className={classes.backgroundImage} />
                                    </>
                                    :
                                    <Image src={slide.img ? slide.img : slide} />}

                            </Slide>
                        )
                    }
                </Slider>
                {dots && <DotGroup className={classes.carouselDots} disableActiveDots={false} />}
                {slides.length > 1 && <>
                    <ButtonBack className={classes.carouselBack}><ChevronLeftIcon style={{ fontSize: '3rem', }} /></ButtonBack>
                    <ButtonNext className={classes.carouselNext}><ChevronRightIcon style={{ fontSize: '3rem', }} /></ButtonNext>
                    </>
                }
            </div>
        </CarouselProvider>
    )
}

export default Carousel;