import React, { useState, useContext, useEffect } from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import { BASE_URI } from "../shared/Constants";
import axios from "axios";
import { useParams, useHistory } from "react-router-dom";
import Typography from "@material-ui/core/Typography";
import { useSnackbar } from "notistack";
import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import ApplySteps from '../shared/ApplySteps';
import { CircularProgress } from "material-ui";
import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider'

const useStyles = makeStyles((theme) => ({
    content: {
        backgroundColor: theme.palette.background.paper,
        padding: theme.spacing(10, 0, 6),
        '@media (max-width: 1024px)': {
            padding: theme.spacing(10, 0, 6),
        },
    },
    divider: {
        backgroundColor: '#39679f',
        width: '33%',
        marginBottom: '30px',
        height: '3px',
        margin: '20px auto'
    },
    success: {
        color: 'green',
        justifyContent: 'center'
    },
    blue: {
        color: '#39679f'
    },
    topHeader: {
        fontWeight: 700,
        textTransform: 'uppercase',
        textAlign: 'center',
        fontSize: '2.5rem',
        marginBottom: 0
    },
    arrow: {
        width: 0,
        height: 0,
        borderLeft: '30px solid transparent',
        borderRight: '30px solid transparent',
        borderTop: '30px solid #39679f',
        margin: '0 auto'
    },
    stepsIcon: {
        '& svg': {
            fontSize: '5rem',
            border: '2px solid #39679f',
            borderRadius: '1rem',
            padding: '0.4rem'
        }
    },
}));

export default function GetInfoContainer(props) {
    const classes = useStyles();
    const { a, i, leadId } = useParams();
    const { enqueueSnackbar } = useSnackbar();
    const isAuthorized = window.location.search.indexOf('status=Authorised') > 1
    const [isSuccess, setIsSuccess] = useState(true)

    const history = useHistory();

    useEffect(() => {

        if (!isAuthorized) {
            return;
        }

        const CancelToken = axios.CancelToken;
        const source = CancelToken.source();

        axios({
            method: 'GET',
            url: `${BASE_URI}/PostNBAuth/${leadId}/${a}/${i}`,
            data: {},
            cancelToken: source.token
        }).then((response) => {
         
            if (response?.data === true) {
                history.push(`/pqSuccess/${leadId}`)
            }
            else {
                setIsSuccess(false);
            }

        }).catch(error => {
            if (axios.isCancel(error)) return
            enqueueSnackbar(`Unable to complete request. ${error}`, { variant: 'error' });
        })
    }, []);

    const stepsState = {
        one: {
            active: false,
            completed: true
        },
        two: {
            active: true,
            completed: false
        },
        three: {
            active: false,
            completed: false
        }
    }

    return (

        <Container maxWidth="lg">
            <MuiThemeProvider>
                <div className={classes.content}>
                    <Box mb={4}>
                        <ApplySteps horizontal disabledStates={stepsState} />
                    </Box>
                    {isAuthorized && <>
                        {isSuccess && <>
                            <Box style={{ display: 'flex', justifyContent: 'center' }} mb={4}>
                                <CircularProgress />
                            </Box>
                            <Typography gutterBottom variant="h5" align="center" color="textPrimary" fontWeight="500">
                                We are waiting for feedback from Nedbank to see whether you qualify to continue the process...Please be patient.
                        </Typography>
                        </>
                        }
                        {!isSuccess && <>
                            <Typography gutterBottom variant="h5" align="center" color="textPrimary" fontWeight="500">
                                Unfortunately, You do not qualify to purchase this vehicle. Please try again in the future.
                            </Typography>
                        </>
                        }
                    </>
                    }
                    {!isAuthorized && <>
                        <Typography gutterBottom variant="h5" align="center" color="textPrimary" fontWeight="500">
                            You chose not to continue with the process. Please try again.
                        </Typography>
                    </>}
                </div>
            </MuiThemeProvider>
        </Container>
    );
}
