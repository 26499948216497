import React, { useContext, useEffect, useState } from 'react';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import Box from '@material-ui/core/Box';
import DriveEtaIcon from '@material-ui/icons/DriveEta';
import BuildIcon from '@material-ui/icons/Build';
import { NavLink as RouterLink, useParams, useHistory } from 'react-router-dom';
import { DealerContext } from '../shared/contexts/DealerContext'
import { MOTORGROUPID, BASE_URI, MASTER_DEALERID } from '../shared/Constants'
import axios from 'axios';
import Dealers from './Dealers';
import Button from '@material-ui/core/Button';
import Carousel from "../homePage/CarouselSingle";


const useStyles = makeStyles((theme) => ({
    content: {
        backgroundColor: theme.palette.background.paper,
        padding: theme.spacing(8, 0, 6),
        overflow: 'hidden'
    },
    black: {
        backgroundColor: '#d6ebf9',
        padding: theme.spacing(0.5, 0, 0.5),
        fontWeight: 'bold',
        color: 'white!important'
    },
    text: {
        width: "100%",
        color: 'black',
        '& .MuiButton-label':{
            display: "inline-block",
            overflow: "hidden",
            whiteSpace: "nowrap",
            textOverflow: "ellipsis",
        },
    },
    blacktext: {
        color: 'white',
        width: "100%",
        '& .MuiButton-label':{
            display: "inline-block",
            overflow: "hidden",
            whiteSpace: "nowrap",
            textOverflow: "ellipsis",
        },
    },
    yellow: {
        backgroundColor: ({ primaryColor }) => `${primaryColor}`,
        padding: theme.spacing(0.5, 0, 0.5),
        color: 'white!important',
    },
    dividerTitle: {
        backgroundColor: ({ primaryColor }) => `${primaryColor}`,
        width: '33%',
        marginBottom: '30px',
        height: '3px',
        margin: '20px auto'
    },
    dividerDealer: {
        backgroundColor: ({ primaryColor }) => `${primaryColor}`,
        width: '33%',
        marginBottom: '15px',
        marginTop: '10px',
        height: '2px',
    },
    fontSmall: {
        fontSize: '0.7rem'
    },
    textOverflow: {
        overflow: "hidden",
        whiteSpace: "nowrap",
        textOverflow: "ellipsis",
    },
    sideBorder: {
        maxWidth: '32%',
        padding: '20px',
        margin: '5px',
         width: '100%',
        boxShadow: '2px 0px 15px -2px #adadad',
        '@media(min-width: 0px) and (max-width: 425px)': {
            maxWidth: '100%',
             width: '100%',
        },
        '@media(min-width: 426px) and (max-width: 900px)': {
            maxWidth: '100%',
             width: '100%',
        },
        '@media(min-width: 900px) and (max-width: 1023px)': {
            maxWidth: '45%',
             width: '100%',
        },  

    },
    logoImage: {
        marginRight: "5px",
        width: "65px",
    },
    logoImageSlider: {
        marginRight: "5px",
        width: "65px",
        height: "auto",
    },
    logoContainer: {
        alignItems: "center",
        display: "flex",
        height: "55px",
        '& .slide': {
            top: "auto",
            bottom: "auto",
            marginBottom: "25px",
        },
        '& .carousel__slider--horizontal': {
            height: "55px",
        }
    },
    fontSmall: {
        display: 'flex',
        fontSize:'0.8rem'
    },
    numberHolder: {
        display: 'flex',
        '@media (max-width: 425px)': {
            flexDirection: 'column'
        }
    },
    mapholder: {
        display: 'flex',
        '@media (max-width: 425px)': {
            flexDirection: 'column'
        }
    }
}));

const SelectDealers = () => {
    const [dealerList, setDealerList] = useState([])
    const [location, setLocation] = useState()
    const [province, setProvince] = useState()
    const [district, setDistrict] = useState()
    const [make, setMake] = useState()
    const { dealerName } = useParams("");
    
    useEffect(() => {
        //TODO: Fix this shitty solution
        var promise1 = axios.get(`${BASE_URI}/Dealers/DealerContactDetailsByMotorgroup/${MOTORGROUPID}`, {});

        var promise2 = axios.get(`${BASE_URI}/Dealers/DealerLocations/${MOTORGROUPID}`, {});

        Promise.all([
            promise1,
            promise2
            
        ]).then(([res1, res2]) => {
            let cleaned = dealerName.replace("Dealers", '').trim()

          

            var filtered1 = res1.data.filter(i => i.name.includes(cleaned));
        
            var filtered2 = filtered1.filter(i => i.name !== cleaned);
        

            var combined = filtered1.map(itm => { return { ...itm, ...filtered2.filter(itm2 => itm2.id == itm.dealerId)[0] } });

            function removeDuplicates(data,key){
                return[
                    ...new Map(
                        data.map(x => [key(x),x])
                    ).values()
                ]
            }
            setDealerList(removeDuplicates(combined, it =>it.name))
          
            setLocation(res2.data)
        });
        
    }, [])

    function SortArray(x, y){
        if (x.name < y.name) {return -1;}
        return 0;
    }


    
    const getDealerAddress = (dealer) => {

        if (dealer && dealer.address) {
            return dealer.address.streetAddress
        }

        return ""
    }

    const { websiteColors } = useContext(DealerContext)
    const classes = useStyles({ ...websiteColors });

    const history = useHistory();

    const cleanName = (dealerName) => {
        return dealerName.toLowerCase().replace(/\//g, '-').replace(/ /g, '-')
    }

    const getBrandLogos = (brands) => {
        return brands.filter(x => !!x.logo).map(x => {return {...x, img: x.logo}});
    }

    return (
        <Container maxWidth="lg">
            <div className={classes.content}>
                <Typography gutterBottom variant="h5" align="center" color="textPrimary" fontWeight="500">
                    SELECT A DEALER
                </Typography>
                <Divider className={classes.dividerTitle} />
                <Box mb={6} mt={6}>

                </Box>
                <Grid container >
                    {dealerList.filter((dealer)=> {
                           let newArr = []
                        if(dealer.name === "Eastvaal Witbank Motor City"){
                           let idx = dealerList.indexOf(dealer)
                            let elt =  dealerList.splice(idx, 1)[0]
                            
                            newArr = dealerList.unshift(elt)
                           
                          
                        }
                        return newArr
                    }).map((dealer, index) => {
                        return (
                            <Grid item sm={12} md={4} key={index} className={classes.sideBorder}>
                                <Box >
                                    <Typography className={classes.textOverflow} variant="h6" >{dealer.name}</Typography>
                                    {getBrandLogos(dealer.relatedBrands) && (
                                        getBrandLogos(dealer.relatedBrands).length > 3 ? 
                                        <div className={classes.logoContainer}>
                                            <Grid xs={12} md={12}>
                                            <Carousel naturalSlideWidth={100}
                                                    naturalSlideHeight={45}
                                                    totalSlides={getBrandLogos(dealer.relatedBrands).length}
                                                    visibleSlides={4}
                                                    isPlaying={true}
                                                    infinite
                                                    hideButtons={true}
                                                    innerClass={`${classes.slideMargin} ${classes.logoImageSlider}`}
                                                    slides={getBrandLogos(dealer.relatedBrands)}
                                                />                
                                            </Grid>
                                        </div> : 
                                        <div className={classes.logoContainer}>
                                            {getBrandLogos(dealer.relatedBrands).map(brand => (
                                                <img src={brand?.logo} className={classes.logoImage} />
                                            ))}
                                        </div>
                                    )}
                                    <Divider className={classes.dividerDealer} />
                                    <Box fontWeight="fontWeightBold">Contact</Box>
                                    <Box className={classes.fontSmall}>{dealer?.newCarsPhoneNumber}</Box>
                                    <Box fontWeight="fontWeightBold">Address</Box>
                                    <Box className={classes.fontSmall}>{getDealerAddress(dealer)}</Box>
                                   
                                    <Box mt={2} fontWeight="fontWeightBold">Business Hours</Box>
                                    <Box className={classes.fontSmall}>Monday - Friday: 8:00 - 17:00</Box>
                                    <Box className={classes.fontSmall}>Saturday: 8:00 - 13:00</Box>

                                    <Box className={classes.black}>
                                        <Button className={classes.text} onClick={() => window.location.href = `/dealer/${cleanName(dealer.name)}/used-vehicles`}>View Used Vehicles From {dealer.name}</Button>
                                    </Box>
                                
                                    {dealer.name === "Eastvaal Middelburg AutoItalia" ? 
                                        <Box className={classes.yellow}>
                                        <Button className={classes.blacktext} onClick={() => window.location.href = `https://auto-italia.co.za/`}>Go To The {dealer.name} Website</Button>
                                    </Box>
                                    :
  <Box className={classes.yellow}>
  <Button className={classes.blacktext} onClick={() => window.location.href = `/dealer/${cleanName(dealer.name)}`}>Go To The {dealer.name} Website</Button>
</Box>
                                    }
                                    <Grid item sm={12} md={12} >
                                            <iframe src={`https://maps.google.com/maps?q=${dealer.addressLongitude},${dealer.addressLatitude}&via=0&z=16&output=embed`} height="100%" width="100%" frameBorder="none"></iframe>
                                        </Grid>
                                </Box>
                            </Grid>
                        )
                    })}
                </Grid>
            </div>
        </Container>
    )
}

export default SelectDealers;