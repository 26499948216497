import * as React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@mui/material/Button';
import ContactUs from './ContactComponent';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
const useStyles = makeStyles({
    card:{
        textAlign:"left",
        display: 'flex',
      flexDirection: 'column',
    
      padding:' 0.5rem'
    },
    headingHolder:{
      marginBottom:'2rem'
    },
    heading:{
      margin:'0.5rem 1rem 0rem 1rem!important'
    }
})

export default function ArticleCard(props) {

    const classes = useStyles(props);
  return (
    <>
    {props.body &&
    <Card   className={classes.card}>
      {props.cardType === "Form" &&
          <>
         <Typography  variant="h5" component="div" className={classes.heading}>
         {props.heading}
        </Typography>
        <ContactUs/>
        </>
      }
        {props.cardType !== "Form" && props.body &&
       <CardContent>
       <div className={classes.headingHolder}>
       <Typography  variant="h5" component="div">
         {props.heading}
       </Typography>
      
        <Typography gutterBottom variant="subtitle2" component="div">
        {props.subheading}
      </Typography>
        </div>
       <Typography variant="body2" color="text.secondary">
       {props.body}
       </Typography>
     {props.modelInfo && 
       <Grid item xs={12} md={12} container alignContent='center' justify='start'>
                                    
      
            {props.modelInfo?.brochures?.length > 0 && props.modelInfo.brochures.map((brochure, index) => (
                <Box mb={0} mt={3} className={classes.largeButton}>
                    <Button className={classes.largeButtonDownload} variant="outlined" gutterBottom onClick={() => {
                        window.open(brochure.document?.filePath, '_blank')
                    }} color="primary">
                        Download Brochure
                    </Button>
                </Box>
            ))}
            
     
    </Grid>
     }

       <Button size="small">{props.ctatext}</Button>
       <Button size="small">{props.ctatext2}</Button>
       
     </CardContent>
}

     
    </Card>
  }
  </>
  );
}
