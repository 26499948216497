
const Dealers = [

    {
        dealer: 'Bethal Dealers',
        address: 'Cnr. Wedge Park Road and William Nicol Drive, Bryanston, Gauteng',
        businessHours: {
            weekday: 'Monday - Friday: 8:00 - 17:00',
            weekend: 'Saturday: 8:00 - 13:00'
        },
        services: ['Sales', 'Service'],
        map: (
            <iframe src = "https://maps.google.com/maps?q=-26.45342833275221,29.46684728793749&via=0&z=16&amp;output=embed" height="244" width="100%" frameBorder="none"></iframe>
        )
    },
    {
        dealer: 'Middelburg Dealers',
        address: 'Cnr. Noon and Sunrise Boulevard, Fourways Crossing, Fourways, Gauteng',
        businessHours: {
            weekday: 'Monday - Friday: 8:00 - 17:00',
            weekend: 'Saturday: 8:00 - 13:00'
        },
        services: ['Sales', 'Service'],
        map: (
            <iframe src = "https://maps.google.com/maps?q=-25.769772334819354,29.465946245206485&via=0&z=16&amp;output=embed" height="244" width="100%" frameBorder="none"></iframe>
        )
    },
    {
        dealer: 'Potchefstroom Dealers',
        address: 'Cnr. Herman & Kuschke Road, Meadowdale, Gauteng, South Africa',
        businessHours: {
            weekday: 'Monday - Friday: 8:00 - 17:00',
            weekend: 'Saturday: 8:00 - 13:00'
        },
        services: ['Sales', 'Service'],
        map: (
            <iframe src = "https://maps.google.com/maps?q=-25.769772334819354,29.465946245206485&via=0&z=16&amp;output=embed" height="244" width="100%" frameBorder="none"></iframe>
        )
    },
    {
        dealer: 'Eastvaal Commercial',
        address: 'Beyers Naude Drive Northcliff Gauteng 2160 South Africa',
        businessHours: {
            weekday: 'Monday - Friday: 8:00 - 17:00',
            weekend: 'Saturday: 8:00 - 13:00'
        },
        services: ['Sales', 'Service'],
        map: (
            <iframe src = "https://maps.google.com/maps?q=-26.511614771993933,29.17474162721499&via=0&z=16&amp;output=embed" height="244" width="100%" frameBorder="none"></iframe>
        )
    },
    {
        dealer: 'Secunda Dealers',
        address: 'Corner K E Masinga & Florence Nzama Road, Stamford Hill, Durban',
        businessHours: {
            weekday: 'Monday - Friday: 8:00 - 17:00',
            weekend: 'Saturday: 8:00 - 13:00'
        },
        services: ['Sales', 'Service'],
        map: (
            <iframe src = "https://maps.google.com/maps?q=-25.876047268850574,29.24179438066899&via=0&z=16&amp;output=embed" height="244" width="100%" frameBorder="none"></iframe>
        )
    },
    {
        dealer: 'Witbank Dealers',
        address: '156 Josiah Gumede Rd, New Germany, Pinetown',
        businessHours: {
            weekday: 'Monday - Friday: 8:00 - 17:00',
            weekend: 'Saturday: 8:00 - 13:00'
        },
        services: ['Sales', 'Service'],
        map: (
            <iframe src = "https://maps.google.com/maps?q=-25.875472381906306,29.24168708481573&via=0&z=16&amp;output=embed" height="244" width="100%" frameBorder="none"></iframe>
        )
    },
 
]


export default Dealers;