import React, { useContext,useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Container from "@material-ui/core/Container";
import { BASE_URI, WEPAY4CARS_URI, DEALERID } from "./Constants"
import Typography from "@material-ui/core/Typography";
import { DealerContext } from '../shared/contexts/DealerContext';
import { FinanceContext } from '../shared/contexts/FinanceContext';
import { MakeContext } from '../shared/contexts/MakeContext'
import { SellYourVehicle } from '@hyperboliq/leadcentre'

const useStyles = makeStyles((theme) => ({
    root: {

        padding: theme.spacing(10, 0, 6),
        '@media (max-width: 768px)': {
            paddingTop: '10px'
        }
    },
    stepPadding: {

        marginTop: "2rem",
    },
    stepPaddingButton: {
        marginTop: "2rem",
    },
    card: {
        width: "100%"
    },
    button: {
        width: "100%",
  
    },
    backButton: {
        marginTop: "1rem",
        width: "100%",
       
    },
    activebtn:{
        background:"#39679f",
        color:'white',
        fontWeight:'bold',
        width:"100%",
        '&:hover': {
            background:'#39679fe6',
        },
    }
}));

export default function TradeInContainer(props) {

    const { makeColors } = useContext(MakeContext)
    const classes = useStyles({ ...makeColors });
    const {setGlobalModel } = useContext(FinanceContext)
    const { globalDealer, websiteColors,dealerList } = useContext(DealerContext)
    useEffect(() => {
        setGlobalModel({})
    }, [])
    return (
        <Container  className={classes.root}>
            <Grid item md={12} style={{ textAlign: 'center' }}>
                <Typography align={"center"} variant={"h5"}>
                    Please Tell us more about your vehicle, you wish to trade in.
                </Typography>
            </Grid>

            <SellYourVehicle base_uri={BASE_URI} wePay4Cars_uri={WEPAY4CARS_URI} dealerId={globalDealer.id || DEALERID} websiteColors={websiteColors} leadSourceId={1} dealerList={dealerList} />

        </Container>
    )
}
