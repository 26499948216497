import React, { useState, useContext, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import { BASE_URI, DEALERID, HAS_MULTIPLE_DEALERSHIPS,MASTER_DEALERID } from "./Constants"
import axios from "axios";
import Button from "@material-ui/core/Button";
import { useParams, useHistory } from "react-router-dom";
import * as Yup from "yup";
import { Formik, Form, Field } from "formik";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import { Switch } from 'formik-material-ui'
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Box from '@material-ui/core/Box';
import { useSnackbar } from "notistack";
import { CircularProgress } from "material-ui";
import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider'
import { NavLink as RouterLink } from 'react-router-dom'
import Link from '@material-ui/core/Link';
import MenuItem from "@material-ui/core/MenuItem";
import VehicleController from '../ourVehiclesPage/VehicleController';
import { DealerContext } from './contexts/DealerContext'
import Dealers from '../ourDealersPage/Dealers';


const useStyles = makeStyles((theme) => ({
    stepPadding: {
        marginLeft: "0.5rem",
        marginRight: "0.5rem",
        marginTop: "2rem",
    },
    stepPaddingBelow: {
        marginRight: "0.5rem",
        marginTop: "0.5rem",
    },
    stepPaddingButton: {
        marginTop: "0.5rem",
    },
    stepPaddingTitle: {
        marginTop: "1rem",
        marginBottom: "1rem",
    },
    card: {
        width: "100%"
    },
    flex: {
        display: "flex"
    },
    button: {
        width: "100%",
    },
    backButton: {
        marginTop: "2rem",
    },
    image: {
        minWidth: "100%",
        height: 550,
    },
    uppercase: {
        textTransform: 'uppercase'
    },
    activebtn:{
        background:"#BB2127",
        color:'white',
        fontWeight:'bold',
        width:"100%",
        '&:hover': {
            background:'#BB2127e6',
        },
    }
}));

export default function ContactContainer(props) {
    const { vehicles } = VehicleController()
    const { websiteColors, globalDealer,dealerList } = useContext(DealerContext)
    const classes = useStyles({ ...websiteColors });
    const [makes, setMakes] = useState([]);
    const [models, setModels] = useState([]);
    const [loading, setLoading] = useState(true);
    const [contactMe, setContactMe] = useState(false)
    const [tsAndCs, setTsAndCs] = useState(false)
    const { enqueueSnackbar } = useSnackbar();
    const [loader, setLoader] = useState(false)


    const initialFormValues = {
        name: '',
        phone: '',
        email: '',
        dealerId: globalDealer?.name ? globalDealer?.id : '',
        dealer: globalDealer?.name || ''
    }
    useEffect(() => {

        let filteredVehicles = vehicles.filter(itm => findMake(itm.make));
        let makes = groupBy(filteredVehicles, 'make');

        setMakes(makes);       
        setLoading(false);
        
    }, [vehicles]);

    let phoneRegExp = /^0(6|7|8){1}[0-9]{1}[0-9]{7}$/;

    let isCashBuyer = props.isCashBuyer;
    let leadTypeId = props.leadTypeId;

    const values = {
        name: '',
        phone: '',
        email: '',
        dealerId: globalDealer?.name ? globalDealer?.id : '',
        dealer: globalDealer?.name || ''
    }
  
    const validationSchema = Yup.object({
        name: Yup.string("Enter a name").required(
            "Name is required"
        ),
        email: Yup.string("Enter your email")
            .email("Enter a valid email")
            .required("Email is required"),
        phone: Yup.string("Enter your phone number")
            .required("Phone number is required")
            .matches(phoneRegExp, "Phone number is not valid")
    });

    const findMake = (make) => {

        if (!globalDealer.makes) {
            return;
        }

        let foundMakes = globalDealer.makes.filter(itm => itm.name.toLowerCase() == make.toLowerCase());

        return foundMakes.length > 0;
    }

    const groupBy = (xs, key) => {
        return xs.reduce(function (rv, x) {
            (rv[x[key]] = rv[x[key]] || []).push(x);
            return rv;
        }, {});
    };

    const checkValid = (validForm) => {
        return (validForm && contactMe && tsAndCs)
    }

    const cleanModel = (model) => {
        return model.toLowerCase().replace(/\//g, '-').replace(/ /g, '-')
    }

    const [activeVariantId, setActiveVariantId] = useState(0)

    const createVehicleLead = (values) => {

        let vehicleParam = {}
        vehicleParam.LeadId = values.leadId;
        vehicleParam.ModelId = props.modelId;
        vehicleParam.variantId = activeVariantId
        
        console.log("values",values)
        console.log("props",props)
        const CancelToken = axios.CancelToken;
        const source = CancelToken.source();

         axios({
             method: 'POST',
             url: `${BASE_URI}/leadVehicles`,
             data: vehicleParam,
             cancelToken: source.token
         }).then((responseSecond) => {
             setLoader(false);
             enqueueSnackbar(`Successfully sent information`, { variant: 'success' });

         }).catch(error => {
             if (axios.isCancel(error)) return
             enqueueSnackbar("Unable to get complete the request", { variant: 'error' });
             //setLoader(false);
         })
    }

    function createLead(values) {

        setLoader(true);

        let params = {}

        params.leadStatusId = 1
  
        params.dealerId = values.dealerId || globalDealer.id;
        params.name = values.name
        params.firstName = values.name;
        params.lastName = '';
        params.phoneNumber = values.phone
        params.emailAddress = values.email
        params.leadSourceId = 1
        params.leadTypeId = leadTypeId
        params.regNo = values.regNo
        params.modelId = values.modelId
        params.serviceDate = values.date
        params.notes = values.notes

        let m = new Date()
        m = m.getUTCFullYear() + "-" + (m.getUTCMonth() + 1) + "-" + m.getUTCDate() + " " + (('0' + m.getHours()).slice(-2)) + ":" + (('0' + m.getMinutes()).slice(-2)) + ":" + (('0' + m.getSeconds()).slice(-2));
        params.dateUpdated = new Date().toISOString();

        const CancelToken = axios.CancelToken;
        const source = CancelToken.source();
  
  
         axios({
             method: 'POST',
             url: `${BASE_URI}/leads`,
             data: params,
             cancelToken: source.token
         }).then((response) => {

             values.leadId = response.data.id;
             createVehicleLead(values);

         }).catch(error => {
             if (axios.isCancel(error)) return
             enqueueSnackbar("Unable to complete the request", { variant: 'error' });
             setLoader(false);
         })
    }

    const handleSelectChange = (e, value, setFieldValue, fieldName, fieldId) => {
        setFieldValue(fieldName, e.target.value)
        setFieldValue(fieldId, value.props.id)
    }

    const handleMakeChange = (makeName, setFieldValue) => {

        // Persist the change for the make selection
        setFieldValue("make", makeName);

        // Filter the options for the models dropdown
        var models = makes[makeName];
        
  
        setModels(models);

        // Reset the model selection (not working 100% at the moment on 2021/11/12)
        setFieldValue("modelId", 0);
        setFieldValue("model", null);
    };

    const handleMakesChange = (e, value, setFieldValue, fieldName, fieldId) => {
        setFieldValue(fieldName, e.target.value)
        setFieldValue(fieldId, parseInt(value.props.id))
        
        const CancelToken = axios.CancelToken;
        const source = CancelToken.source();
        
        // console.log("ownedModelId",value.props.ownedModelId)
            axios({
             method: 'GET',
             url: `${BASE_URI}/dealervariants?dealerModelId=${parseInt(value.props.ownedModelId)}`,
             cancelToken: source.token
         }).then((response) => {
            setActiveVariantId(response?.data?.list[0]?.variantId)
            // console.log(response.data.list[0])
         

         }).catch(error => {
            console.log(error)
         })
      
    }
    const cleanName = (dealerName) => {
        return dealerName.toLowerCase().replace(/\//g, '-').replace(/ /g, '-')
    }
    let dealer = localStorage.getItem('globalDealer')
    let dealerParsed = JSON.parse(dealer)
    return (
        <React.Fragment>
            <MuiThemeProvider>
                <Grid container direction="row">
                    <Grid item xs={12}>
                        <Card className={classes.content} elevation={0}>
                            <CardContent>
                                {
                                    loading && <CircularProgress/>
                                }
                                {
                                    !loading &&
                                    <Formik
                                        isInitialValid={false}
                                        initialValues={initialFormValues}
                                        enableReinitialize={true}
                                        validationSchema={validationSchema}
                                        onSubmit={async (values, actions) => {
                                            actions.resetForm()
                                            createLead(values)
                                        }}
                                    >
                                        {(props) => {
                                            let {
                                                values,
                                                touched,
                                                errors,
                                                handleBlur,
                                                handleChange,
                                                isValid,
                                                submitForm,
                                                setFieldTouched,
                                                setFieldValue
                                            } = props;
                                            return (
                                                <Form>
                                                    <Grid container className={classes.root} direction="row">
                                                        <Grid item className={classes.stepPaddingButton} xs={12} md={12}>
                                                            <TextField
                                                                id="name"
                                                                name="name"
                                                                label="Name"
                                                                value={values.name}
                                                                fullWidth
                                                                required
                                                                variant="outlined"
                                                                helperText={touched.name ? errors.name : ""}
                                                                error={touched.name && Boolean(errors.name)}
                                                                onChange={(event) => {
                                                                    setFieldValue("name", event.target.value)
                                                                    setFieldTouched("name", true, false);
                                                                }}
                                                            />
                                                        </Grid>
                                                        <Grid item className={classes.stepPaddingButton} xs={12} md={12}
                                                            lg={12}>
                                                            <TextField
                                                                id="email"
                                                                name="email"
                                                                type="email"
                                                                label="Email"
                                                                fullWidth
                                                                required
                                                                value={values.email}
                                                                variant="outlined"
                                                                helperText={touched.email ? errors.email : ""}
                                                                error={touched.email && Boolean(errors.email)}
                                                                onChange={(event) => {
                                                                    setFieldValue("email", event.target.value)
                                                                    setFieldTouched("email", true, false);
                                                                }}
                                                            />{" "}
                                                        </Grid>
                                                        <Grid item className={classes.stepPaddingButton} xs={12} md={12}
                                                            lg={12}>
                                                            <TextField
                                                                id=" phone"
                                                                name="phone"
                                                                type="tel"
                                                                label="Cellphone"
                                                                fullWidth
                                                                required
                                                                value={values.phone}
                                                                variant="outlined"
                                                                helperText={touched.phone ? errors.phone : ""}
                                                                error={touched.phone && Boolean(errors.phone)}
                                                                onChange={(event) => {
                                                                    setFieldValue("phone", event.target.value)
                                                                    setFieldTouched("phone", true, false);
                                                                }}
                                                            />
                                                        </Grid>
                                                        <Grid item className={classes.stepPaddingButton} xs={12} md={12}
                                                            lg={12}>
                                                            <TextField
                                                                id="regNo"
                                                                name="regNo"
                                                                label="
                                                                Registration No."
                                                                fullWidth
                                                                variant="outlined"
                                                                helperText={touched.regNo ? errors.regNo : ""}
                                                                error={touched.regNo && Boolean(errors.regNo)}
                                                                onChange={(event) => {
                                                                    setFieldValue("regNo", event.target.value)
                                                                    setFieldTouched("regNo", true, false);
                                                                }}
                                                            />
                                                        </Grid>

                                                        <Grid item className={classes.stepPaddingButton} xs={12} md={12}
                                                            lg={12}>
                                                            <Typography gutterBottom variant="p" color="textPrimary" fontWeight="500">
                                                                Preferred date
                                                            </Typography>
                                                            <TextField
                                                                id="date"
                                                                name="date"
                                                                variant="outlined"
                                                                fullWidth
                                                                id="Preferred date"
                                                                type="date"
                                                                helperText={touched.date ? errors.date : ""}
                                                                error={touched.date && Boolean(errors.date)}
                                                                onChange={(event) => {
                                                                    setFieldValue("date", event.target.value)
                                                                    setFieldTouched("date", true, false);
                                                                }}
                                                            >
                                                            </TextField>
                                                        </Grid>
                                                        <Grid item className={classes.stepPaddingButton} xs={12} md={12}
                                                            lg={12}>
                                                            <TextField
                                                                id="mileage"
                                                                name="mileage"
                                                                label="
                                                                Mileage"
                                                                fullWidth
                                                                variant="outlined"
                                                                helperText={touched.regNo ? errors.regNo : ""}
                                                                error={touched.regNo && Boolean(errors.regNo)}
                                                                onChange={(event) => {
                                                                    setFieldValue("mileage", event.target.value)
                                                                    setFieldTouched("mileage", true, false);
                                                                }}
                                                            />
                                                        </Grid>
                                                        <Grid item className={classes.stepPaddingButton} xs={12}>
                                                            <TextField
                                                                variant="outlined"
                                                                fullWidth
                                                                id="make"
                                                                select
                                                                //required
                                                                label="Which make is your car?"
                                                                value={values.make}
                                                                helperText={errors.make && touched.make ? errors.make : ''}
                                                                error={errors.make && touched.make}
                                                                onBlur={handleBlur("make")}
                                                                onChange={(e, child) => handleMakeChange(e.target.value, setFieldValue)}
                                                            >
                                                                {Object.keys(makes)?.map((makeName, index) => (
                                                                    <MenuItem key={makeName} id={makeName} value={makeName}>
                                                                    {makeName}
                                                                    </MenuItem>
                                                                ))}
                                                            </TextField>
                                                        </Grid>


                                                        {
                                                            models?.length > 0 &&
                                                         
                                                            <Grid item className={classes.stepPaddingButton} xs={12}>
                                                                <TextField
                                                                    variant="outlined"
                                                                    fullWidth
                                                                    id="modelId"
                                                                    select
                                                                    //required
                                                                    label="Which model is your car?"
                                                                    value={values.modelId}
                                                                    helperText={errors.modelId && touched.modelId ? errors.modelId : ''}
                                                                    error={errors.modelId && touched.modelId}
                                                                    onBlur={handleBlur("modelId")}
                                                                    onChange={(e, child) => handleMakesChange(e, child, setFieldValue, 'model', 'modelId')}
                                                                >
                                                                    {models.map((option) => (
                                                                        <MenuItem key={option.modelId} id={option.modelId} value={option.modelId} ownedModelId={option.ownedModelId}>
                                                                          
                                                                            {option.title}
                                                                        </MenuItem>
                                                                    ))}
                                                                </TextField>
                                                            </Grid>
                                                        }

                                                        
                                                     
<Grid item className={classes.stepPaddingButton} xs={12}>
                                                            <TextField
                                                             variant="outlined"
                                                             fullWidth
                                                             id="dealer"
                                                             select={globalDealer.id == MASTER_DEALERID}
                                                            readOnly={globalDealer.id != MASTER_DEALERID}
                                                            
                                                             label="Dealership"
                                                             value={values.dealer}
                                                             helperText={errors.dealer && touched.dealer ? errors.dealer : ''}
                                                             error={errors.dealer && touched.dealer}
                                                             onBlur={handleBlur("dealer")}
                                                             onChange={(e, child) => handleSelectChange(e, child, setFieldValue, 'dealer', 'dealerId')}
                                                         >
                                                            {dealerList?.map((option) => (
                                                                 <MenuItem key={option.dealerId} id={option.dealerId} value={option.dealerId}>
                                                             
                                                                     {option.name}
                                                                 </MenuItem>
                                                             ))}
                                                         </TextField>
                                                        </Grid>

                                                        <Grid item className={classes.stepPaddingButton} xs={12} md={12}
                                                            lg={12}>

                                                            <Typography gutterBottom variant="p" color="textPrimary" fontWeight="500">
                                                                Notes
                                                            </Typography>

                                                            <Box mb={2}>
                                                                <TextField
                                                                    id="notes"
                                                                    name="notes"
                                                                    type="text"
                                                                    fullWidth
                                                                    multiline
                                                                    rows={4}
                                                                    variant="standard"
                                                                    helperText={touched.notes ? errors.notes : ""}
                                                                    error={touched.notes && Boolean(errors.notes)}
                                                                    onChange={(event) => {
                                                                        setFieldValue("notes", event.target.value)
                                                                        setFieldTouched("notes", true, false);
                                                                    }}

                                                                />
                                                            </Box>
                                                        </Grid>
                                                        <Grid item className={classes.flex} xs={12} md={12}
                                                            lg={12}>
                                                            <FormControlLabel
                                                                control={
                                                                    <Field component={Switch}
                                                                        color="primary"
                                                                        type="checkbox"
                                                                        name="contactMe"
                                                                        onClick={() => {
                                                                            setContactMe(contactMe ? false : true)
                                                                        }}
                                                                    />
                                                                }

                                                            />
                                                            <span>I allow Eastvaal Motor Group to contact me</span>
                                                        </Grid>
                                                        <Grid item className={classes.flex} xs={12} md={12}
                                                            lg={12}>
                                                            <FormControlLabel
                                                                control={
                                                                    <Field component={Switch}
                                                                        color="primary"
                                                                        type="checkbox"
                                                                        name="popi"
                                                                        onClick={() => {
                                                                            setTsAndCs(tsAndCs ? false : true)
                                                                        }}
                                                                    />
                                                                }

                                                            />
                                                            <span>
                                                                By submitting this form I agree to the <Link component={RouterLink} to="/terms" className={classes.link}>terms and conditions</Link> and <Link component={RouterLink} to="/privacy" className={classes.link}>privacy policies.</Link>
                                                            </span>
                                                        </Grid>
                                                        <Grid container direction="row">
                                                            <Grid item className={classes.stepPaddingButton} xs={12} md={12}
                                                                lg={12}>

                                                                {!loader &&
                                                                    <Button className={classes.activebtn} variant={"contained"}
                                                                        
                                                                        disabled={!checkValid(isValid)}
                                                                        onClick={() => {
                                                                            submitForm();
                                                                        }}
                                                                    >
                                                                        {isCashBuyer ? 'Order Now' : 'Contact Me'}
                                                                    </Button>
                                                                }
                                                                {loader == true && (
                                                                    <Button className={classes.activebtn} variant={"contained"}
                                                                        
                                                                        disabled={true}
                                                                    >
                                                                        <CircularProgress />
                                                                    </Button>
                                                                )}
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                </Form>
                                            );
                                        }}
                                    </Formik>
                                }
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>
            </MuiThemeProvider>
        </React.Fragment>
    );
}