import React, { useContext, useEffect, useState } from 'react';
import Container from '@material-ui/core/Container';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import { NavLink as RouterLink, useParams, useHistory } from 'react-router-dom';
import Link from '@material-ui/core/Link';
//import contactUsImage from '../shared/assets/FURY_PMB_SUZUKI_MARCH_2021_CONTACT_PAGE_BANNER.jpg'
import { ImageContainer } from '../shared/ImageContainer'
import ContactComponent from '../shared/ContactComponent'
import { DealerContext } from '../shared/contexts/DealerContext'
import { FinanceContext } from '../shared/contexts/FinanceContext'
import axios from 'axios';
import { BASE_URI, DEALERID, MASTER_DEALERID } from '../shared/Constants';
import MenuItem from "@material-ui/core/MenuItem";
import TextField from "@material-ui/core/TextField";
import * as Yup from "yup";
import { Formik, Form, Field } from "formik";
const useStyles = makeStyles((theme) => ({
    content: {
        backgroundColor: theme.palette.background.paper,
        padding: theme.spacing(10, 0, 6),
        '@media (max-width: 1024px)': {
            padding: theme.spacing(10, 0, 6),
        },
    },
    dividerTitle: {
        backgroundColor: ({ primaryColor }) => `${primaryColor} `,
        width: '33%',
        marginBottom: '0px',
        height: '3px',
        margin: '20px auto'
    },
    bold: {
        fontWeight: 700
    },
    url: {
        color: '#7c7c7c',
        textDecoration: 'none',
        '&:hover': {
            color: '#1f2532',
            textDecoration: 'none'
        }
    },
    link: {
        backgroundColor: ({ primaryColor }) => `${primaryColor} `,
        marginBottom: '1rem',
        color: '#ffffff',
        fontWeight: '700',
        '&:hover': {
            textDecoration: 'none',
            opacity: 0.8
        }
    }
}));


const ContactUs = (props) => {

    let history = useHistory();
    let model = props.location.state

    const { websiteColors, globalDealer, dealerList } = useContext(DealerContext)
    const classes = useStyles({ ...websiteColors });
    const [dealer, setDealer] = useState()
    const [dealerInfo, setDealerInfo] = useState()
    const [selectedDealer, setSelectedDealer] = useState()
    const { setGlobalModel } = useContext(FinanceContext)

    useEffect(() => {
        
        axios.get(`${BASE_URI}/Dealers/DealerContactDetails/${globalDealer.id}`, {

        }).then((result) => {
            setDealer(result.data.address.streetAddress)

            setDealerInfo(result.data)
        });
        setGlobalModel({})
    }, [])



    const initialFormValues = {

        dealerId: globalDealer?.name ? globalDealer?.id : '',
        dealer: globalDealer?.name || ''
    }

    const handleSelectChange = (e, value, setFieldValue, fieldName, fieldId) => {
        setSelectedDealer(true)
        setFieldValue(fieldName, e.target.value)
        setFieldValue(fieldId, value.props.id)

        axios.get(`${BASE_URI}/Dealers/DealerContactDetails/${value.props.id}`, {

        }).then((result) => {
            setDealer(result.data.address.streetAddress)

            setDealerInfo(result.data)
        });
    }

    return (
        <Container maxWidth="lg">
            <Box>
                {/*<ImageContainer aspectRatio={(16/6)} height='100%' src={} alt='Contact Us' />*/}
            </Box>
            <div className={classes.content}>

                <Grid container spacing={2}>



                    <Grid item xs={12} md={6}>
                        <Typography gutterBottom variant="h5" align="center" color="textPrimary" fontWeight="500">
                            Dealer Specific Contact Details
                        </Typography>
                        <Divider className={classes.dividerTitle} />



                        {globalDealer.id == DEALERID && <>
                            <Box mb={3} mt={3}>
                                <Box textAlign="center" fontSize={'1.5rem'}>
                                    Please select a dealer to display there contact details
                                </Box>
                            </Box>
                            <Formik
                                isInitialValid={false}
                                initialValues={initialFormValues}
                                enableReinitialize={true}

                                onSubmit={async (values, actions) => {
                                    actions.resetForm()

                                }}
                            >
                                {(props) => {
                                    const {
                                        values,
                                        touched,
                                        errors,
                                        isValid,
                                        handleBlur,
                                        handleChange,
                                        setFieldValue
                                    } = props;
                                    return (
                                        <Form>
                                            <Grid item className={classes.stepPaddingButton} xs={12} style={{ paddingTop: globalDealer.id == DEALERID ? '24px' : '0px 0px' }}>
                                                <TextField
                                                    variant="outlined"
                                                    fullWidth
                                                    id="dealer"
                                                    select={globalDealer.id == MASTER_DEALERID}
                                                    readOnly={globalDealer.id != MASTER_DEALERID}

                                                    label="Dealership"
                                                    value={values.dealer}
                                                    helperText={errors.dealer && touched.dealer ? errors.dealer : ''}
                                                    error={errors.dealer && touched.dealer}
                                                    onBlur={handleBlur("dealer")}
                                                    onChange={(e, child) => handleSelectChange(e, child, setFieldValue, 'dealer', 'dealerId')}
                                                >
                                                    {dealerList?.map((option) => (
                                                        <MenuItem key={option.dealerId} id={option.dealerId} value={option.dealerId}>

                                                            {option.name}
                                                        </MenuItem>
                                                    ))}
                                                </TextField>
                                            </Grid>
                                        </Form>
                                    );
                                }}
                            </Formik>
                        </>}

                        {selectedDealer &&
                            <div style={{ padding: globalDealer.id == DEALERID ? '0px 0px' : '16px 0px' }}>
                                <Box fontWeight="fontWeightBold">General Enquiries</Box>
                                <Box mb={3}>{dealerInfo?.newCarsPhoneNumber}</Box>
                                <Box fontWeight="fontWeightBold">Address</Box>
                                <Box mb={3}>   {dealer}</Box>


                                {/* <Box fontWeight="fontWeightBold">Email</Box>
                    <Box mb={3}>
                        <a className={classes.url} href="mailto:customercare@renaultretail.co.za?Subject=Renault%20Virtual%20Contact" title="Drop us message on email">customercare@renaultretail.co.za</a>
                    </Box> */}
                                <Box fontWeight="fontWeightBold">Opening Hours</Box>
                                <Box>Monday - Friday 07:30 - 17:30</Box>
                                <Box>Saturday: 08:00 - 13:00</Box>
                                <Box mb={3}>Sunday: Closed</Box>

                                <Grid item sm={12} md={12} >
                                    <iframe src={`https://maps.google.com/maps?q=${dealerInfo?.addressLongitude},${dealerInfo?.addressLatitude}&via=0&z=16&output=embed`} height="400px" width="100%" frameBorder="none"></iframe>
                                </Grid>
                            </div>
                        }


                    </Grid>


                    <Grid item xs={12} md={6}>
                        <Typography gutterBottom variant="h5" align="center" color="textPrimary" fontWeight="500">
                            Our Customer Relations team are here to help you
                        </Typography>
                        <Divider className={classes.dividerTitle} />
                        <Box mb={3} mt={3}>
                            <Box textAlign="center" fontSize={'1.5rem'}>
                                Send us your details and we will call you
                            </Box>
                        </Box>
                        <ContactComponent leadTypeId={3} {...model} />
                    </Grid>

                </Grid>
            </div>
        </Container>
    );
}

export default ContactUs;