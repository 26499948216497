import * as React from 'react';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { makeStyles } from '@material-ui/core/styles';
import { CurrencyValue } from "./TextMask";
import Link from '@mui/material/Link';
import { NavLink as RouterLink } from 'react-router-dom';
import { useHistory } from "react-router-dom";
const useStyles = makeStyles({
    root:{
        textAlign:props => props.align,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        paddingTop:' 1rem',
        height:props => props.height
    },
    text:{
 
      padding:' 0rem',
  },
  year:{
  
    padding:' 0rem',
},
price:{
 
  padding:' 0.5rem 0rem',
  fontWeight:'bold!important'
},
body:{
 
  padding:'0.5rem'
},
heading:{
  margin:'0rem!important',
  padding:'0rem'
},
location:{
  display:'flex'
},
location:{
  display:'flex'
},
})

export default function ImgMediaCard(props) {

  function removeMake() {
    localStorage.removeItem("activeMake")

    if(localStorage.getItem("activeMake") === null){
      history.push(`${props.ctatextlink}`)
      window.location.reload()
    }
   
}

  const history = useHistory();
    const classes = useStyles(props);
  return (
    <Card  className={classes.root}>
        {props.image &&
      <img
        component="img"
        alt="img"
        height={props.cardType == "Vehicle" ? 230 : 80}
        
        src={props.image}
        className={classes.image}
      />
  }
      <CardContent style={{width:"100%",}}>
 
        {props.cardType == "Vehicle" &&
        <>
        <Typography gutterBottom variant="h6" component="div" className={classes.text}>
        {props.brand}
        </Typography>
        <Typography gutterBottom variant="h6" component="div">
        {props.model}
        </Typography>
        <Typography gutterBottom variant="subtitle2" component="div" className={classes.year}>
        {props.year} &nbsp;|&nbsp; {props.mileage} km &nbsp;| &nbsp;{props.newUsed}
        </Typography>
        <Typography variant="body2" className={classes.location}>
        <img src="https://img.icons8.com/external-flatart-icons-outline-flatarticons/20/000000/external-location-twitter-flatart-icons-outline-flatarticons.png"/>
        {props.dealershipName}
        </Typography>
        <Typography variant="h5" className={classes.price}>
        <CurrencyValue value={props.price} />
        </Typography>
        <Typography variant="body2" >
        R {Math.round(props.price / 60) .toFixed(0)} pm -10% deposit, 60 months, <br/>11% interest
        </Typography>
        </>
         }

        {props.cardType !== "Vehicle" &&
        <>
        {props.heading &&
          <Typography gutterBottom variant="h5" component="div" className={classes.heading}>
          {props.heading}
          </Typography>
            }
            {props.body &&
          <Typography gutterBottom variant="subtitle2" component="div" className={classes.body}>
          {props.body}
          </Typography>
            }
        </>
        }

      </CardContent>

      <CardActions>
        {props.ctatext &&  <Button size="small">
        <Link variant="button" color="textSecondary"
                       
                        onClick={()=>{removeMake()}}
                       className={classes.btnMenuItem}
                      >
                      {props.ctatext}
                   </Link>
         </Button>}
        {props.ctatext2 &&  <Button size="small">{props.ctatext2}</Button>}
      </CardActions>
    </Card>
  );
}
