import React, { useContext, useEffect, useState } from 'react';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import Box from '@material-ui/core/Box';
import DriveEtaIcon from '@material-ui/icons/DriveEta';
import BuildIcon from '@material-ui/icons/Build';
import { NavLink as RouterLink, useParams, useHistory } from 'react-router-dom';
import { DealerContext } from '../shared/contexts/DealerContext'
import { MakeContext } from '../shared/contexts/MakeContext'
import { FinanceContext } from '../shared/contexts/FinanceContext'

import { MOTORGROUPID, BASE_URI, MASTER_DEALERID } from '../shared/Constants'
import axios from 'axios';
import Dealers from './Dealers';
import Button from '@material-ui/core/Button';
import { Filter } from '@material-ui/icons';



const useStyles = makeStyles((theme) => ({
    content: {
        backgroundColor: theme.palette.background.paper,
        padding: theme.spacing(10, 0, 6),
        '@media (max-width: 1024px)': {
            padding: theme.spacing(10, 0, 6),
        },
        overflow: 'hidden'
    },
    black: {
        backgroundColor: '#d6ebf9',
        padding: theme.spacing(0.5, 0, 0.5),
        fontWeight: 'bold',
        color: 'white!important'
    },
    text: {

        color: 'black'
    },
    blacktext: {
        color: 'white'
    },
    yellow: {
        backgroundColor: ({ primaryColor }) => `${primaryColor}`,
        padding: theme.spacing(0.5, 0, 0.5),
        color: 'white!important'
    },
    dividerTitle: {
        backgroundColor: ({ primaryColor }) => `${primaryColor}`,
        width: '33%',
        marginBottom: '30px',
        height: '3px',
        margin: '20px auto'
    },
    dividerDealer: {
        backgroundColor: ({ primaryColor }) => `${primaryColor}`,
        width: '33%',
        marginBottom: '15px',
        marginTop: '15px',
        height: '2px',
    },
    fontSmall: {
        fontSize: '0.7rem'
    },
    sideBorder: {
        maxWidth: '32%',
        width: '100%',
        padding: '20px',
        margin: '5px',
        boxShadow: '2px 0px 15px -2px #adadad',
        '@media(min-width: 0px) and (max-width: 425px)': {
            maxWidth: '100%',
            width: '100%',
        },
        '@media(min-width: 426px) and (max-width: 900px)': {
            maxWidth: '100%',
            width: '100%',
        },
        '@media(min-width: 900px) and (max-width: 1023px)': {
            maxWidth: '45%',
            width: '100%',
        },  

    },
    fontSmall: {
        display: 'flex',
        fontSize:'0.8rem'
    },
    numberHolder: {
        display: 'flex',
        '@media (max-width: 425px)': {
            flexDirection: 'column'
        }
    },
    mapholder: {
        display: 'flex',
        '@media (max-width: 425px)': {
            flexDirection: 'column'
        }
    }
}));

const OurDealers = () => {
    let makes = JSON.parse(localStorage.getItem("globalDealer")).makes 
    // const [dealerList, setDealerList] = useState([])
    const [location, setLocation] = useState()
    const [province, setProvince] = useState()
    const [district, setDistrict] = useState()
    const { make } = useContext(MakeContext)
    const [roadSide, setRoadSide] = useState(makes)
    const {setGlobalModel } = useContext(FinanceContext)

    const masterIds =[289,290,291,292,293,257,256]

    // useEffect(() => {
    //     setRoadSide(makes)
    //     //TODO: Fix this shitty solution
    //     var promise1 = axios.get(`${BASE_URI}/Dealers/DealerContactDetailsByMotorgroup/${MOTORGROUPID}`, {});

    //     var promise2 = axios.get(`${BASE_URI}/Dealers/DealerLocations/${MOTORGROUPID}`, {});

    //     Promise.all([
    //         promise1,
    //         promise2
            
    //     ]).then(([res1, res2]) => {

    //         var filtered1 = res1.data.filter(itm => masterIds.includes(itm.dealerId) );


    //         var filtered2 = res2.data.dealers.filter(itm => masterIds.includes(itm.id));
         

    //         var combined = filtered1.map(itm => { return { ...itm, ...filtered2.filter(itm2 => itm2.id == itm.dealerId)[0] } });

    //         setDealerList(combined)
          
    //         setLocation(res2.data)
    //         console.log(res1)
    //     });
        
    // }, [])

    function SortArray(x, y){
        if (x.name < y.name) {return -1;}
        return 0;
    }


    
    const getDealerAddress = (dealer) => {

        if (dealer && dealer.address) {
            return dealer.address.streetAddress
        }

        return ""
    }

    const { websiteColors,dealerList } = useContext(DealerContext)
 
    const classes = useStyles({ ...websiteColors });

    const history = useHistory();

    const cleanName = (dealerName) => {
        return dealerName.toLowerCase().replace(/\//g, '-').replace(/ /g, '-')
    }
useEffect(() => {
    setGlobalModel({})
}, [])

    return (
        <Container maxWidth="lg">
            {/* <div>
                {dealerInfo.map((info,index)=>{
                          <select>
                          <option>1</option>
                          <option>2</option>
                          <option>3</option>
                          <option>4</option>
                          <option>5</option>
                          </select>
                })}
          

                <select>
                <option>1</option>
                <option>2</option>
                <option>3</option>
                <option>4</option>
                <option>5</option>
                </select>

                <select>
                <option>1</option>
                <option>2</option>
                <option>3</option>
                <option>4</option>
                <option>5</option>
                </select>
            </div> */}
            <div className={classes.content}>
                <Typography gutterBottom variant="h5" align="center" color="textPrimary" fontWeight="500">
                    OUR DEALER NETWORK
                </Typography>
                <Divider className={classes.dividerTitle} />
                <br /><br />
                <Typography variant="h6" marked="left" gutterBottom>
                    Roadside Assistance
                    <Divider className={classes.dividerDealer} />
                </Typography>

                <Grid container>
                 
                 {roadSide?.map((m,index)=>{
                     return(
                        <Grid item xs={6} sm={6} md={3} lg={3}>

                        <Typography className={classes.footerLink}>
                            <span style={{ fontWeight: 'bold' }}>{m?.name}:</span> 0{m?.roadSideAssistTel}

                        </Typography>

                    </Grid>
                     )
                 })}
                 

                </Grid>
           
                <Box mb={6} mt={6}>

                </Box>
                <Grid container >
                    {Dealers?.sort(SortArray)?.map((dealer, index) => {
                        return (
                        
                            <Grid item sm={12} md={4} key={index} className={classes.sideBorder}>
                                   
                                <Box >
                               

                                {dealer?.dealer !== null &&
                                    <Typography variant="h6" >{dealer?.dealer}</Typography>
                                    }
                                    <Divider className={classes.dividerDealer} />
                            

                                    {dealer?.dealer == "Quick Lane Dealers" && <Box className={classes.black}>
                                        <Button className={classes.text} onClick={() => window.location.href = `/dealer/${cleanName(dealer?.dealer)}/used-vehicles`}>View Used Vehicles From {dealer?.dealer}</Button>
                                    </Box> }

                                    {dealer?.dealer == "Quick Lane Dealers" && <Box className={classes.yellow}>
                                    <Button className={classes.blacktext} onClick={() => window.location.href = `/dealer/${cleanName(dealer?.dealer)}`}>go the the {dealer?.dealer} website</Button>
                                    </Box> }

                                    {dealer?.dealer !== "Quick Lane Dealers" && dealer?.dealer !== "Köhler Auto" &&
                                    <Box className={classes.yellow}>
                                        <Button className={classes.blacktext} onClick={() => window.location.href = `/our-dealers/${dealer?.dealer}`}>View {dealer?.dealer}</Button>
                                    </Box>}

                                    {dealer?.dealer == "Köhler Auto" && <Box className={classes.black}>
                                        <Button className={classes.text} onClick={() => window.location.href = `/dealer/${cleanName(dealer?.dealer)}/used-vehicles`}>View Used Vehicles From {dealer?.dealer}</Button>
                                    </Box> }

                                    {dealer?.dealer == "Köhler Auto" && <Box className={classes.yellow}>
                                    <Button className={classes.blacktext} onClick={() => window.location.href = `/dealer/${cleanName(dealer?.dealer)}`}>go the the {dealer?.dealer} website</Button>
                                    </Box> }


                             
                                </Box>
                            </Grid>
                        )
                    })}
                </Grid>
            </div>
        </Container>
    )
}

export default OurDealers;