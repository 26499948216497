import React, { useState, useCallback, useRef,useEffect,useContext } from 'react';
import Grid from '@material-ui/core/Grid';
import GridList from '@material-ui/core/GridList';
import GridListTile from '@material-ui/core/GridListTile';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';
import { DealerContext } from '../shared/contexts/DealerContext'
import axios from 'axios';
import Divider from '@material-ui/core/Divider';
import Link from '@material-ui/core/Link';
// import Color from 'color';
import {DealCard} from './DealCard'
import DealsFilter from './DealsFilter'
import ContactComponent from '../shared/ContactComponent'
import { NavLink as RouterLink, useHistory } from 'react-router-dom';
import {
  useParams
} from "react-router-dom";
import {CurrencyValue} from '../shared/TextMask'
import CircularProgress from '@material-ui/core/CircularProgress';
import carouselImage from './../shared/assets/home-slide-1.jpg';
import Button from '@material-ui/core/Button';
const useStyles = makeStyles((theme) => ({
  holder: {
    display:'flex',
    padding: theme.spacing(10, 0, 6),
    '@media (max-width: 780px)': {
      flexDirection: 'column',
      padding: theme.spacing(10, 2, 6),
  }
  },

  image: {
    minHeight: 320,
    backgroundSize:"100% 100%"
  },
  paymentDetails: {
    '& > div': {
      borderBottom: '1px solid #cccccc',
      padding: '.8rem .5rem',
      display: 'flex',
      justifyContent: 'space-between'
    }
  }
}));

const useGridStyles = makeStyles(({ breakpoints }) => ({
  root: {
    [breakpoints.up('md')]: {
      // justifyContent: 'center',
    },
  },
}));


export default function SpecialDealsComponentDetailed(props) {
  const [pageNumber, setPageNumber] = useState(1)
  const [sortDirection, setSortDirection] = useState('Desc')  
  const{
    loading,
    deals,
    hasMore
  }= DealsFilter(pageNumber, sortDirection) 
  const classes = useStyles();
  const gridStyles = useGridStyles({ color: '#ff9900' });
  const history = useHistory();

  const [dealData, setDealData] = useState(deals)
  const [activeDeal, setActiveDeal] = useState([])
  const [cashDeal, setCashDeal] = useState(false)
  const [isService, setisService] = useState(false)
  const observer = useRef()
  const lastDealRef = useCallback(node => {

  }, [loading, hasMore])

    const cleanModel = (model) => {

        return model.toLowerCase().replace('/', '-')
    }

    const { carId, modelId, dealId } = useParams();
 
    useEffect(() => {
      if(deals == null && dealId == null){

      }else{
        console.log("deals",deals)
        console.log("dealid",dealId)
      setDealData(deals)
      let results = deals.find(deal => deal.id == dealId)
      setActiveDeal(results)
    
     
    
    }
    }, [deals,dealId])

    useEffect(() => {
      if(activeDeal?.leadType == "Service" || activeDeal?.leadType == "Part"){
          setisService(true)
      }else{
        setisService(false)
      }
    }, [activeDeal])

 
    var format = /[ (]/;
    

  return (
    <React.Fragment>
      <main>
        <Container maxWidth="lg" className={classes.holder}>
       
    
        
            <Grid item xs={12} sm={12} md={7} lg={7} >
            <Typography
                        gutterBottom
                        variant="h5"
                        align="left"
                        color="textPrimary"
                        fontWeight="500"
                >
                      {activeDeal?.headline}
            </Typography>
            {/* <Typography
                        gutterBottom
                        variant="h6"
                        align="left"
                        color="textPrimary"
                        fontWeight="500"
                >
                     Only Valid Until: {activeDeal?.dateTo?.slice(0,10)}
            </Typography> */}
              <img src={activeDeal?.imageUrl} width="100%"/>
              {!isService && 
              <>
              <Typography
                        gutterBottom
                        variant="h5"
                        align="left"
                        color="textPrimary"
                        fontWeight="500"
                >
                      {activeDeal?.make} { activeDeal?.model}
            </Typography> 

            <Typography
                        gutterBottom
                        variant="h5"
                        align="left"
                        color="textPrimary"
                        fontWeight="500"
                >
               
                      { activeDeal?.variant?.substring(0, activeDeal?.variant?.indexOf("(") - 1)}
            </Typography> 
            </>
            }
            <Typography
                        gutterBottom
                        variant="p"
                        align="left"
                        color="textPrimary"
                        fontWeight="500"
                >
                      { activeDeal?.introParagraph}
            </Typography>
            <Grid className={classes.paymentDetails} item xs={12} >
            {activeDeal?.isCashDeal == false &&
                  <React.Fragment>
                  <Box display="flex" mt={2} pb={1}>
                      <Typography variant="h5" color="textPrimary" >
                          <strong>
                              QUICK SUMMARY
                          </strong>
                      </Typography>
                   </Box>
                  <Box display="flex"  pb={1}>
                    <Box width={200}><Typography><strong>Purchase Price</strong></Typography></Box>
                    <Typography><CurrencyValue value={activeDeal?.price}/></Typography>
                  </Box> 
                  <Box display="flex"  pb={1}>
                    <Box width={200}><Typography><strong>Monthly Installment</strong></Typography></Box>
                    <Typography><CurrencyValue value={activeDeal?.monthlyInstallment}/></Typography> 
                  </Box> 
                  <Box display="flex"  pb={1}>
                    <Box width={200}><Typography><strong>Term (Months)</strong></Typography></Box>
                    <Typography>{activeDeal?.term}</Typography> 
                  </Box> 
                  <Box display="flex"  pb={1}>
                    <Box width={200}><Typography><strong>Linked Interest Rate</strong></Typography></Box>
                    {activeDeal?.rate == 0 &&
                    <Typography>0 %</Typography> 
                  } 
                    {activeDeal?.rate !== 0 &&
                      <Typography>{activeDeal?.rate}%</Typography> 
               
                    }
            
                  </Box> 
                  <Box display="flex"  pb={1}>
                    <Box width={200}><Typography><strong>Deposit</strong></Typography></Box>
                    {activeDeal?.deposit == 0 &&
                    <Typography>R0</Typography> 
                  } 
                  {activeDeal?.deposit !== 0 &&
                <CurrencyValue value={activeDeal?.deposit}/>
                  }
                   
                  </Box> 
                  <Box display="flex"  pb={1}>
                    <Box width={200}><Typography><strong>Balloon Payment</strong></Typography></Box>
                    {activeDeal?.balloonPayment == 0 &&
                    <Typography>0%</Typography> 
                  } 
                  {activeDeal?.balloonPayment !== 0 &&
                  <Typography>{activeDeal?.balloonPayment}% </Typography> 
           
                  }
                  </Box> 
                </React.Fragment>
            }
            </Grid>
            <Typography
                        gutterBottom
                        variant="p"
                        align="left"
                        color="textPrimary"
                        fontWeight="500"
                        style={{fontSize:"0.8rem",padding:"1rem 0px",display:'block'}}
                >
                      { activeDeal?.termsAndConditions}
            </Typography>
            </Grid>

            <Grid item xs={12} sm={12} md={5} lg={4}>
            <Typography
                        gutterBottom
                        variant="h5"
                        align="left"
                        color="textPrimary"
                        fontWeight="500"
                        style={{padding:'0px 1rem'}}
                >
                       Request A Call Back
            </Typography>
            <ContactComponent leadTypeId={3} />
            <Box mb={3} mt={1}>
                                            <Link href='/apply-for-finance' style={{ margin: '0px 1rem', }}>
                                                <Button className={classes.largeButtonDownload} variant="contained" color="primary" style={{ width: '93%' }}>
                                                    Apply Now
                                                </Button>
                                            </Link>
                                        </Box>
                </Grid>
          
        
        </Container>
      </main>
    </React.Fragment>
  );
}