import { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import { BASE_URI, PAGE_SIZE, DEALERID,MOTORGROUPID } from '../shared/Constants'
import { DealerContext } from '../shared/contexts/DealerContext'

export default function DealsFilter(pageNumber, sortDirection) {
    const [loading, setLoading] = useState(true)
    const [error, setError] = useState(false)
    const [deals, setDeals] = useState([])
    const [hasMore, setHasMore] = useState(false)
    let { globalDealer } = useContext(DealerContext);
    let currentDate = new Date() 
    let params = {}

    useEffect(() => {
        setDeals([])
    }, [sortDirection])


    useEffect(() => {
        let cancel
        setLoading(true)
        setError(false)


        if(globalDealer.id !== DEALERID){
            params = {
                pageNumber,
                pageSize: PAGE_SIZE,
                status: 'active',
                dealerId: globalDealer.id,
            }

        }else{
            params = {
                pageNumber,
                pageSize: PAGE_SIZE,
                status: 'active',
                motorGroupId : MOTORGROUPID
            }
        }

    

        axios({
            method: 'GET',
            url: `${BASE_URI}/Offers`,
            params,
            cancelToken: new axios.CancelToken(c => cancel = c)
        }).then((response) => {
            setHasMore(response.data?.list.length > 0)
            setLoading(false)
            let filterd = response.data?.list.filter(d => d.dateTo >= currentDate.toISOString() && d.dateFrom <= currentDate.toISOString())

            if(globalDealer.id !== DEALERID){
                setDeals(filterd)
            }else{
                setDeals(removeDuplicates(response.data?.list, it =>it.metaDescription).sort((a,b)=> Math.random() - 0.5))
        
                function removeDuplicates(data,key){
                    return[
                        ...new Map(
                            data.map(x => [key(x),x])
                        ).values()
                    ]
                }
                console.log("removeDuplicates",removeDuplicates(response.data?.list, it =>it.metaDescription))
            }
                
           
           
   
        }).catch(error => {
            if (axios.isCancel(error)) return
            setLoading(false)
        })
        return () => cancel()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pageNumber, sortDirection])

    return { loading, error, deals, hasMore }
}