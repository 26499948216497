import React, { useState, useCallback, useRef, useEffect, useContext } from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardProfile from '../shared/Card';
import CardMedia from '@material-ui/core/CardMedia';
import Box from '@material-ui/core/Box';
import * as tmpDeals from './tmpDeals'
import PaymentDetails from '../shared/PaymentDetails'
import FinanceOptionsComponent from './FinanceOptionsComponent'
import { ImageContainer } from '../shared/ImageContainer'
import Carousel from '../homePage/CarouselSingle'
import { SpecificationsComponent } from '../shared/SpecificationsComponent'
import { useParams, useHistory } from "react-router-dom";
import { useSnackbar } from 'notistack';
import axios from 'axios';
import CircularProgress from '@material-ui/core/CircularProgress';
import { BASE_URI } from '../shared/Constants';
import Link from '@material-ui/core/Link';
import Container from '@material-ui/core/Container';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import { CurrencyValue } from '../shared/TextMask';
import { FinanceContext } from '../shared/contexts/FinanceContext';
import CheckIcon from '@material-ui/icons/Check';
import ApplySteps from '../shared/ApplySteps';
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import { Formik, Form } from "formik";
import { MakeContext } from '../shared/contexts/MakeContext'
import { DealerContext } from '../shared/contexts/DealerContext'
import CarProfileBanner from '../shared/CarProfileBanner'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';

import ArticleSection from '../shared/ArticleSection';
import 'react-tabs/style/react-tabs.css';
import { border } from '@material-ui/system';


const useStyles = makeStyles((theme) => ({
    singleHeader: {
        padding: '10rem 0'
    },
    background: {
        backgroundColor: theme.palette.background.paper,
        padding: theme.spacing(10, 0, 6),
        '@media (max-width: 1024px)': {
            padding: theme.spacing(10, 0, 6),
        },
    },
    largeButton: {
        display: 'flex',
        flexDirection: 'column',
        alignContent: 'center',
        justifyContent: 'center',
        width: '100%',
        '& h6': {
            fontSize: '28.8px'
        }
    },
    buttonIconLg: {
        fontSize: '5rem',
        color: '#39679f'
    },
    sectionPadding: {
        margin: '3rem 0'
    },
    paragraphPadding: {
        padding: '1.5rem 3rem !important'
    },
    heading: {
        borderBottom: ({ primaryColor }) => `5px solid ${primaryColor} !important`,
        textTransform: 'capitalize',
        paddingBottom: '.5rem',
        fontWeight: '700',
        display: 'inline-block'
    },
    primaryButton: {
        backgroundColor: ({ primaryColor }) => `${primaryColor} !important`,
    },
    yellowBorder: {
        borderBottom: ({ primaryColor }) => `5px solid ${primaryColor} !important`,
    },
    secondaryHeading: {
        textTransform: 'uppercase',
        paddingBottom: '1rem',
        fontWeight: '400'
    },
    slideMargin: {
        width: 'calc(100% - 20px)',
        height: 'calc(100% - 20px)',
        left: '10px',
        top: '10px',
    },
    topHeader: {
        fontWeight: 700,
        textTransform: 'uppercase',
        textAlign: 'center',
        fontSize: '2.5rem'
    },
    yellow: {
        color: ({ primaryColor }) => `${primaryColor} !important`
    },
    specs: {
        '& .specs': {
            borderBottom: '0 !important',
            marginTop: '-25px'
        },
    },
    formControl: {
        minWidth: 400,
        '@media (max-width: 450px)': {
            minWidth: '100%'
        }
    },
    headerLine: {
        height: '1.5px',
        width: '50%',
        backgroundColor: ({ primaryColor }) => `${primaryColor} !important`,
        padding: '0 !important'
    },
    smallHeading: {
        fontSize: '1.3rem'
    },
    stepPaddingColours: {
        margin: "0em",
        display: 'flex',
        width: '100%',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'relative',
        flexDirection: 'column',
    },

    stepPaddingColoursName: {

    },
    colorsContainer: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
    },
    colourName: {
        textTransform: 'uppercase',
        fontSize: '0.8rem',
        margin: '0rem 0.6rem',
        width: '90%',
        textAlign: 'center',
    },
    colourPrice: {
        fontWeight: 700
    },
    stepOneButtons: {
        display: 'flex',
        '@media (max-width: 500px)': {
            flexDirection: 'column',
            alignItems: 'center',
            '& > a': {
                marginLeft: '0 !important',
                marginRight: '0 !important'
            }
        }
    },
    topDetailSection: {
        '@media screen and (max-width: 959px)': {
            display: 'flex',
            flexDirection: 'column',
            '& > div:first-child': {
                order: 2,
                marginTop: '2rem'
            },
            '& > div:nth-child(2)': {
                order: 3
            },
            '& > div:nth-child(3)': {
                order: 4
            },
            '& > div:nth-child(4)': {
                marginTop: '-1.5rem'
            }
        }
    },
    largeButtonHolder: {
        display: 'flex',
        flexDirection: 'row',
        width: '100%!important',
    },
    largeButtonDownload: {
        margin: '5px',
        height: '100%',
        opacity: '100%',
        backgroundColor: ({ secondaryColor }) => `${secondaryColor} !important`,
        color: "white!important"
    },
    largeButtonDownloadD: {
        margin: '5px',
        height: '100%',
        opacity: '100%',


    },
    largeButton: {

        color: "white!important",
        '& :hover': {
            opacity: '100%',
            backgroundColor: ({ secondaryColor }) => `${secondaryColor} !important`,
        },
    },
    activebtn: {
        background: "#39679f",
        color: 'white',
        fontWeight: 'bold',
        '&:hover': {
            background: '#39679fe6',
        },
    },
    cardHolder: {
        display: 'flex'
    },
    steps: {
        marginRight: '1.5rem',
        padding: '1rem',
        '@media (max-width: 768px)': {
            marginRight: '0rem',
        },
    },
    cardHolderInner: {
        overFlox: 'hidden',
        display: 'flex',
        marginTop: '2rem ',
        alignItems: 'center',
        marginBottom: '4rem ',

        boxShadow: '0px 17px 22px -2px #375c8a8c',
        '@media (max-width: 768px)': {
            flexDirection: "column!important",

        }
    },
    flex: {
        display: "flex!important",
        justifyContent: 'space-between',

        '@media (max-width: 768px)': {
            flexDirection: "column!important",
        }
    },
    flexPriceHolder: {
        display: "flex!important",
        justifyContent: 'space-between',

        '@media (max-width: 768px)': {
            flexDirection: "column-reverse",
        }
    },
    flexPrice: {
        display: "flex!important",
        justifyContent: 'space-between',


    },
    flexColors: {
        display: "flex",
        justifyContent: 'space-between',
    },
    tab: {
        height: '100%'
    }
}));

export default function CarProfileContainer(props) {

    const { makeColors,make } = useContext(MakeContext)
    const classes = useStyles({ ...makeColors });

    let { carId, modelId, dealId } = useParams();
    let tmpCarId = (carId).toLowerCase().replace(/ /gi, "%");

    const history = useHistory();

    const [carProfile, setCarProfile] = useState({});
    const [colors, setColours] = useState({});
    const [loading, setLoading] = useState(true);
    const mountedRef = useRef(true)
    const { enqueueSnackbar } = useSnackbar();
    const [financeOptions, setFinanceOptions] = useState([])
    const [financeDetails, setFinanceDetails] = useState({})
    const [headline, setHeadline] = useState('')
    const [variantData, setVariantData] = React.useState([]);
    const [modelInfo, setModelInfo] = useState(null);
    const [modelData, setModelData] = useState(null);
    const [isTata, setIsTata] = useState(false);
    const [isDaewoo, setIsDaewoo] = useState(false);
    const [variantId, setVariantId] = React.useState(0);
    const [colorData, setColorData] = React.useState([]);
    const [selectedColor, setSelectedColor] = React.useState();
    let { globalDealer } = useContext(DealerContext);

    
    const [selectedModel, setSelectedModel] = React.useState();
    const [colorId, setColorId] = React.useState(0);

    let { setGlobalFinance, setGlobalVehicle, setGlobalColor, setGlobalVariantId, setGlobalModel, globalColor } = useContext(FinanceContext);

    const values =
    {
        model: "",
        variantId: ""
    };

    const handleModelChange = async (e, value, setFieldValue) => {
        setFieldValue("model", e.target.value);
        setFieldValue("variantId", value.props.id);
        setVariantId(parseInt(value.props.id));

        var model = variantData.filter(itm => itm.id == value.props.id)[0];

        var tmpFinanceDetails = { ...financeDetails, originalPrice: model?.priceRange || 0, purchasePrice: model?.priceRange || 0 };

        let monthly = calculateMonthly(tmpFinanceDetails);

        tmpFinanceDetails = { ...tmpFinanceDetails, monthlyInstallment: monthly };

        setFinanceDetails(tmpFinanceDetails);
        setGlobalFinance(tmpFinanceDetails);
        setGlobalModel(model);

        setSelectedModel(model)
    };

    const calculateMonthly = (data) => {
        var deposit = data?.purchasePrice * (data?.deposit / 100);
        var totalPrice = data?.purchasePrice;
        var initFee = 0;
        var onTheRoad = 5137;       // Eastvaal's static fee
        var admin = 1207.50;        // Eastvaal's static fee
        var principal = totalPrice - deposit + initFee + onTheRoad + admin;

        var balloonPerc = data?.balloonPayment || 0;

        var balloonAmt = totalPrice * balloonPerc / 100;

        var interestRate = 11;
        var interestPM = interestRate / 100 / 12;

        var repaymentPeriod = data?.term || 0;
        var days = 1;

        var v = 1 / (1 + interestPM);
        var d = 1 - v;
        var y = Math.pow(v, repaymentPeriod - 1);
        var comp = (1 - y) / d;
        var fp = principal * (Math.pow(1 + interestPM, (days / (365 / 12).toFixed())));
        var monthly = 0;

        if (parseInt(balloonAmt) > 0) {
            var comp = (1 - (Math.pow(v, repaymentPeriod - 1))) / d;
            monthly = (fp - (balloonAmt * Math.pow(v, repaymentPeriod - 1))) / comp;
        }
        else {
            var comp = (1 - (Math.pow(v, repaymentPeriod))) / d;
            monthly = fp / comp;
        }

        return round(monthly) + initFee;
    }

    function round(x) {
        return Math.round(x * 100) / 100;
    }

    useEffect(() => {

        if (colorId == 0) {
            return
        }

        var tmpFinanceDetails;
        var color = colorData.filter(itm => itm.id == colorId)[0];

        if (color.price > 0) {
            tmpFinanceDetails = { ...financeDetails, purchasePrice: color.price };
        }
        else {
            tmpFinanceDetails = { ...financeDetails, purchasePrice: modelData.price };
        }

        let monthly = calculateMonthly(tmpFinanceDetails);

        tmpFinanceDetails = { ...tmpFinanceDetails, monthlyInstallment: monthly };

        setFinanceDetails(tmpFinanceDetails);
        setGlobalFinance(tmpFinanceDetails);

        setGlobalColor(color);
        setSelectedColor(color);

    }, [colorId]);

    useEffect(() => {

        if (!modelId) {
            setLoading(false);
            return;
        }

        const CancelToken = axios.CancelToken;
        setLoading(true)
        const source = CancelToken.source();

        var getVariantData = axios.get(`${BASE_URI}/dealervariants?dealerModelId=${modelId}`, {
            cancelToken: source.token
        }).then(result => {

            let data = result.data.list
            setVariantData(data);
            setVariantId(data[0].id);
            setModelData({ price: data[0].modelPrice, id: data[0].modelId });
        });

        var getModelInfo = axios.get(`${BASE_URI}/DealerModelInformation/GetForDealerModel/${modelId}`, {
            cancelToken: source.token
        }).then(result => {

            if (result?.data) {
               
                setModelInfo(result?.data);
            }

        });

        Promise.allSettled([
            getVariantData,
            getModelInfo
        ]).then(([res1, res2]) => {

            setLoading(false);
        }).catch(error => {
            setLoading(false);
        });

    }, [carId]);

    useEffect(() => {

        if (!variantId || variantId == 0) {
            return;
        }

        setGlobalVariantId(variantId);

        const CancelToken = axios.CancelToken;
        const source = CancelToken.source();

        const result = axios.get(`${BASE_URI}/colours/exteriors?dealerVariantId=${variantId}`, {
            cancelToken: source.token
        }).then(result => {

            let data = result.data.list

            setColorData(data);

            setColours(data?.map(itm => { return { img: itm.image, color: itm }; }));

        });

    }, [variantId])

    useEffect(() => {

        if (!modelData || !modelInfo)
            return;

        const CancelToken = axios.CancelToken;
        setLoading(true)
        const source = CancelToken.source();
        const getOffer = async () => {
            try {
                setLoading(false)
                const result = await axios.get(`${BASE_URI}/Offers/${dealId}`, {
                    cancelToken: source.token
                })
                let data = result.data

                setHeadline(result.data.headline)
                let tmpFinanceOptions = []

                tmpFinanceOptions.push({
                    variant: result.data.variant,
                    variantId: result.data.variantId,
                    monthlyInstallment: result.data.monthlyInstallment,
                    id: result.data.id,
                    model: result.data.model
                })

                setFinanceOptions(tmpFinanceOptions)

                let tmpFinanceDetails = {
                    purchasePrice: data.price,
                    monthlyInstallment: data.monthlyInstallment,
                    term: data.term,
                    rate: data.rate,
                    deposit: data.deposit,
                    balloonPayment: data.balloonPayment,
                    termsAndConditions: data.termsAndConditions,
                }

                setFinanceDetails(tmpFinanceDetails)
                setGlobalFinance(tmpFinanceDetails)

            } catch (error) {
                setLoading(false)
                enqueueSnackbar("Unable to get offer details", { variant: 'error' });
            }
        }

        setGlobalVehicle(modelInfo)

        if (dealId) {

            getOffer().then(() => {

                window.scrollTo(0, 0);

                setLoading(false)

                if (!mountedRef.current) return null
            })
        } else {



            let financeDetails = {
                deposit: 10,
                rate: 11,
                balloonPayment: 35,
                term: 72,
                purchasePrice: modelData.price
            };

            let monthly = calculateMonthly(financeDetails);

            financeDetails.monthlyInstallment = monthly || 0

            let financeOptions = {
                id: 0,
                modelId: modelData.id,
                monthlyInstallment: monthly || 0
            };

            setFinanceDetails(financeDetails)
            setGlobalFinance(financeDetails)
            setFinanceOptions(financeOptions)

            setLoading(false)
        }

        return () => {
            mountedRef.current = false
            source.cancel();
        };
    }, [modelData, modelInfo])

    const padZero = (str, len) => {
        len = len || 2;
        let zeros = new Array(len).join('0');
        return (zeros + str).slice(-len);
    }

    const invertColor = (hex) => {
        if (hex.indexOf('#') === 0) {
            hex = hex.slice(1);
        }
        // convert 3-digit hex to 6-digits.
        if (hex.length === 3) {
            hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2];
        }
        if (hex.length !== 6) {
            throw new Error('Invalid HEX color.');
        }
        // invert color components
        let r = (255 - parseInt(hex.slice(0, 2), 16)).toString(16),
            g = (255 - parseInt(hex.slice(2, 4), 16)).toString(16),
            b = (255 - parseInt(hex.slice(4, 6), 16)).toString(16);
        // pad each with zeros and return
        return '#' + padZero(r) + padZero(g) + padZero(b);
    }

    const stepsState = {
        one: {
            active: true,
            completed: false
        },
        two: {
            active: true,
            completed: false
        },
        three: {
            active: true,
            completed: false
        }
    }

    const getMainImage = () => {

        var image = modelInfo?.contentImages?.find(img => img.imageName.toLowerCase().trim() == "main")?.image?.imageUrl;

        if (selectedColor) {
            image = selectedColor.image;
        }

        return image;
    }

    const getSmallImage = () => {

        var image = modelInfo?.contentImages?.find(img => img.imageName.toLowerCase().trim() == "small")?.image?.imageUrl;

        return image;

    };

    const getMedium1Image = () => {

        var image = modelInfo?.contentImages?.find(img => img.imageName.toLowerCase().trim() == "medium1")?.image?.imageUrl;

        return image;

    };

    const getMedium2Image = () => {

        var image = modelInfo?.contentImages?.find(img => img.imageName.toLowerCase().trim() == "medium2")?.image?.imageUrl;

        return image;

    };

    const getGalleryImages = () => {

        var images = [];
        var galleryCIs = modelInfo?.galleryImages;

        if (galleryCIs && galleryCIs.length > 0) {
            galleryCIs.forEach(ci => images.push(ci.image?.imageUrl || null));
        }

        return images.filter(img => img != null);

    };

    const getModelName = () => {

        return variantData[0]?.modelName || carId.toUpperCase().replace(/\-/g, ' ').trim();
    };
    const leadTypeId = props?.location?.state?.leadTypeId
    const vehicle = selectedModel ? selectedModel : props?.location?.state;

    const CheckTata = () => {
        if(globalDealer.id == 230 || make.name == "TATA" ){
            setIsTata(true)
        }else
        {
            setIsTata(false)
        }
    };

    const CheckDaewoo = () => {
        if(globalDealer.id == 230 || make.name == "Daewoo" ){
            setIsDaewoo(true)
        }else
        {
            setIsDaewoo(false)
        }
    };


    useEffect(() => {
        CheckTata()
        CheckDaewoo()
    }, [])
    
    return (
        <React.Fragment>
            <main>
                <CarProfileBanner image={modelInfo?.contentImages[0]?.image?.imageUrl} />
                <Grid container className={classes.cardHolder}>

                    <Grid item xs={6} sm={6} md={3}>
                        <CardProfile height="150px" align="center" heading={modelInfo?.features[0]?.title} body={modelInfo?.features[0]?.description} />
                    </Grid>

                    <Grid item xs={6} sm={6} md={3}>
                        <CardProfile align="center" height="150px" image='' heading={modelInfo?.features[1]?.title} body={modelInfo?.features[1]?.description} />
                    </Grid>
                    <Grid item xs={6} sm={6} md={3}>
                        <CardProfile align="center" height="150px" heading={modelInfo?.features[2]?.title} body={modelInfo?.features[2]?.description} />
                    </Grid>
                    <Grid item xs={6} sm={6} md={3}>
                        <CardProfile align="center" height="150px" heading={modelInfo?.features[3]?.title} body={modelInfo?.features[3]?.description} />
                    </Grid>


                </Grid>

                <Container maxWidth="lg" className={classes.cardHolderInner} >

                    <Grid item xs={12} md={6}>
                
                        <Card className={classes.steps}>
                        {!isTata &&  !isDaewoo &&
                            <>
                            <Typography variant="h4" align="center">Apply online in <span style={{ fontSize: '2rem' }} className={classes.yellow}>3</span> easy steps</Typography>
                            <ApplySteps disabledStates={stepsState} />
                            </>
                        }
                            {isTata &&  isDaewoo &&
                                          <Box mb={3} mt={1} >
                                        <Typography >
                                        {modelInfo?.introductionDesc}
                                        </Typography>
                                           </Box>}
                        </Card>
                       
                          
                     
                    </Grid>

                    <Grid item xs={12} md={6} >
                        <Formik
                            initialValues={values}
                            isInitialValid={false}
                            onSubmit={(values, actions) => {
                                setTimeout(() => {

                                    actions.setSubmitting(true);
                                }, 100);
                            }}
                        >
                            {(props) => {
                                const { values, touched, errors, handleBlur, handleChange, isSubmitting, handleReset, setFieldValue } = props;
                                return (
                                    <Form>


                                        <Box style={{ marginTop: 24 }}>
                                            <TextField
                                                fullWidth
                                                id="model"
                                                variant="outlined"
                                                select
                                                required
                                                label="Select a model"
                                                value={values.model}
                                                helperText={errors.model && touched.model ? errors.model : ""}
                                                error={errors.model && touched.model}
                                                onBlur={handleBlur("make")}
                                                onChange={(e, child) => {
                                                    handleModelChange(e, child, setFieldValue);
                                                   
                                                }}>
                                                {variantData && variantData.map((option) => (
                                                    <MenuItem key={option.id} id={option.id} value={option.title}>
                                                        {option.title} (From <CurrencyValue value={option.priceRange} />)
                                                    </MenuItem>
                                                ))}
                                            </TextField>
                                        </Box>
                                        {isTata &&
                                          <Box mb={3} mt={1} >
                                        <Typography >
                                        For price enquires feel free to submit your details and one of our highly trained,professional sales staff will make contact with you shortly
                                        </Typography>
                                           </Box>}
                                        {!isTata &&
                                       <>
                                        <PaymentDetails displayPrice={false} details={financeDetails} />
                                       
                                       <Box mb={0} mt={3} className={classes.stepOneButtons} style={{ width: 'initial', display: 'flex' }}>
                                           {colorId === 0 &&
                                               <Typography >
                                                   Please select a color
                                               </Typography>
                                           }
                                       </Box>
                                       </>
                                         }
                                        <Box mb={3} mt={0} className={classes.stepOneButtons} style={{ width: 'initial', display: 'flex' }}>
                                        {!isTata &&
                                            <span style={{ margin: '0 10px 10px 0' }} className={classes.largeButton}>
                                                <Button activeClassName={classes.largeButtonDownload} className={classes.largeButtonDownloadD} variant="contained" gutterBottom onClick={() => { history.push(`/apply-for-finance`, { ...vehicle, leadTypeId: leadTypeId }) }} disabled={values.variantId == 0}>
                                                    Pre-Qualify Now
                                                </Button>
                                            </span>
                                        }
                                            <Link style={{ margin: '0 10px 10px 0' }} className={classes.largeButton}>
                                                <Button activeClassName={classes.largeButtonDownload} className={classes.largeButtonDownloadD} variant="contained" gutterBottom onClick={() => { history.push(`/contact-us`, { ...vehicle, leadTypeId: leadTypeId }) }} disabled={values.variantId == 0}>
                                                    Request Call back
                                                </Button>
                                            </Link>
                                        </Box>
                                        <Box>
                                            <Typography style={{ marginTop: '.5rem' }} variant="caption" color="textSecondary">
                                                {financeDetails.termsAndConditions}
                                            </Typography>
                                        </Box>
                                    </Form>
                                );
                            }}
                        </Formik>
                    </Grid>
              

                </Container >

                <Container maxWidth="lg" className={classes.flex} style={{ paddingBottom: '2rem' }}>
                    <Grid container spacing={4} style={{ marginBottom: '1rem' }}>
                        <Grid item xs={12} md={12}>
                            <Box style={{ padding: ' 0 0.6rem' }}>
                                {selectedColor ? <ImageContainer aspectRatio={(16 / 6)} src={getMainImage()} alt='MainImage' /> : <ImageContainer aspectRatio={(16 / 6)} src={getMainImage()} alt='MainImage' />}
                            </Box>

                        </Grid>


                    </Grid>
                    {colorData && colorData.length > 0 &&


                        <Grid item xs={12} md={3} className={classes.colorsContainer}>

                            {colorData.map(color => {
                                return <Box className={classes.stepPaddingColours} key={`color-${color.id}`}>
                                    <Button
                                        style={{
                                            backgroundColor: `${color.colour}`,
                                            height: '60px',
                                            maxwidth: '200px',
                                            width: '100%',
                                            minWidth: '200px',
                                            borderRadius: '0%',
                                        }}
                                        onClick={(v) => {
                                            setColorId(color.id);
                                        }}
                                        variant={"contained"}
                                    >
                                        {colorId === color.id &&
                                            <CheckIcon style={{ color: invertColor(`${color.colour}`) }} />
                                        }
                                    </Button>

                                    <div className={classes.stepPaddingColoursName}>
                                        <Typography className={classes.colourName}>{color.title}</Typography>
                                        <Typography className={classes.colourPrice}>
                                            {/* <CurrencyValue value={colour.price} /> */}
                                        </Typography>

                                    </div>

                                </Box>
                            })
                            }
                        </Grid>

                    }

                </Container>
                <Container maxWidth="lg">
                    <Tabs>
                        <div className={classes.flexPriceHolder}>
                            <TabList>
                                <Tab>HIGHLIGHT</Tab>
                                <Tab>INTERIOR</Tab>
                                <Tab>EXTERIOR</Tab>

                            </TabList>
                            <div  >
                                <div className={classes.flexPrice}>

                                    <div >
                                        <Typography className={classes.smallHeading}>
                                            {getModelName()}
                                        </Typography>
                                        <Typography variant="h6" color="textPrimary">
                                            <strong>from <CurrencyValue value={financeDetails.purchasePrice} /></strong>
                                        </Typography>

                                    </div>

                                    <div>
                                        <Box mb={3} mt={0} className={classes.stepOneButtons} style={{ width: 'initial', display: 'flex' }}>
                                        {!isTata &&  
                                            <Link style={{ margin: '0 10px 10px 10px' }} className={classes.largeButton}>
                                                <Button className={classes.largeButtonDownload} variant="contained" gutterBottom onClick={() => { history.push(`/apply-for-finance`, vehicle) }} disabled={colorId <= 0}>
                                                    Apply Now
                                                </Button>
                                            </Link>
                                        }
                                        </Box>
                                    </div>


                                </div>
                            </div>
                        </div>
                        <TabPanel>
                            <ArticleSection margin='2rem 0' align="start" image={modelInfo?.contentImages[2]?.image?.imageUrl} heading={modelInfo?.contentTitle2} body={modelInfo?.contentDesc2} cardType="Simple" modelInfo={modelInfo} />




                        </TabPanel>

                        <TabPanel>
                            <ArticleSection margin='2rem 0' align="center" image={modelInfo?.contentImages[3]?.image?.imageUrl} heading={modelInfo?.contentTitle3} body={modelInfo?.contentDesc3} cardType="Simple" />

                        </TabPanel>

                        <TabPanel className={classes.tab}>
                            <ArticleSection margin='2rem 0' align="center   " image={modelInfo?.contentImages[1]?.image?.imageUrl} heading={modelInfo?.contentTitle1} body={modelInfo?.contentDesc1} cardType="Simple" />

                        </TabPanel>


                    </Tabs>
                </Container >
                <Container maxWidth="lg">
                    {
                        loading &&
                        <Grid item container justifyContent='center' heading={modelInfo?.features[0]?.title} justify='center' alignContent='center' xs={12}>
                            <CircularProgress size={80} />
                        </Grid>
                    }
                    {
                        !loading && !modelInfo &&
                        <Typography gutterBottom variant="h4" color="textPrimary" fontWeight="500" className={classes.singleHeader}>
                            <Box mb={5} className={classes.topHeader}>
                                {(modelId > 0 ? "Please load brochure information in DASH" : "No linked dealer model found")}
                            </Box>
                        </Typography>
                    }
                    {
                        !loading && modelInfo &&
                        <>


                        </>
                    }
                </Container>
            </main>
        </React.Fragment>
    );
}