import React, { useContext, useEffect } from 'react';
import Container from '@material-ui/core/Container';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { NavLink as RouterLink, useParams } from 'react-router-dom';
import Link from '@material-ui/core/Link';
import { DealerContext } from '../shared/contexts/DealerContext'
import axios from 'axios';

const useStyles = makeStyles((theme) => ({
    content: {
        backgroundColor: theme.palette.background.paper,
        padding: theme.spacing(10, 0, 6),
        '@media (max-width: 1024px)': {
            padding: theme.spacing(10, 0, 6),
        },
        'li': {
            display: 'block;'
        },
        'li: before': {
            content: 'counters(item, ".") ". "',
            counterIncrement: 'item'
        },
        'ol': {
            counterReset: 'item'
        },
        'li strong': {
            display: 'inline-block',
            margin: '10px 0'
        }
    },
    link: {
        color: 'blue',
        textDecoration: 'none',
        '&:hover': {
            color: 'blue',
            textDecoration: 'none'
        }
    },
    linkHolder:{
        marginTop:'10px',
    }
}));

const Terms = () => {
    const classes = useStyles();

    return (
        <Container maxWidth="lg">
            <div className={classes.content} >
                <Typography gutterBottom variant="h4" color="textPrimary" fontWeight="500" >
                    PRIVACY POLICY
                </Typography>
                <p>
                <strong>Please read this Privacy Policy carefully. By using Conexa, which is owned by Market Dealer Holdings (Pty) Limited (MDH) you are acknowledging that you understand and agree to the terms of this Privacy Policy and how your Personal Information is used and treated. Please also review the Terms and Conditions which govern your use of Conexa and the services provided by Conexa.</strong>
                <br/>
                <br/>
                <ol>
                    <li>
                        <strong>Introduction</strong>
                        <ol>
                            <li>
                      
Eastvaal Motors (Pty) Ltd respect the right privacy and confidentiality of personal information and we are committed to protecting that privacy by ensuring that all personal information is collected and used properly, lawfully, and transparently.   

                            </li>
                            <li>Considering this, we have developed and adopted this Privacy Statement which applies to Eastvaal Motors (Pty) Ltd and all its subsidiary companies, employees, suppliers, service providers and all living persons be it a natural or juristic person. </li>
                            <li>We use your personal data to provide and improve our service. By using the service, you agree to the collection and use of information in accordance with this Privacy Statement. </li>
                       
                        </ol>
                    </li>
                    <li>
                        <strong>Purpose </strong>
                        <ol>
                            <li>This statement will help our customers understand how Eastvaal Motors (Pty) Ltd and all our subsidiary companies use and protect the personal information provide to us when a customer: </li>
                            <li>
                            Purchase a vehicle 
                            </li>
                            <li>
                            We either collect Personal Information directly from our customer or through an intermediary to assist us with the licensing and registration of the vehicle being purchased by the customer. 
                            </li>
                            <li>Finance and insure a vehicle </li>
                            <li>The personal information that we collect from our customers relates solely to the information we need for our customers to finance a vehicle or enter into an insurance contract which we are licensed for.  This information is obtained direct from our customer and in some instances from an intermediary and only used for the purposes noted above. </li>
                        </ol>
                    </li>
                    <li>
                        <strong>Service a vehicle </strong>
                        <ol>
                            <li>
                            The customer provides us with selected personal information to book the vehicle in for the service; invoicing purposes and in selected instances, where the customer requires information on special offering relating to products that might be beneficial specifically relating to vehicle services.  In this instance, we do obtain the customer’s consent prior to distribution of such information to the customer. 
                            </li>
                           
                        </ol>
                    </li>
                    <li>
                        <strong>Purchase vehicle parts </strong>
                        <ol>
                            <li>
                            As part of our customer take-on process, we require certain information for due diligence and invoicing purposes.  This information will not be used for any other purpose.  
                            </li>
                            
                        </ol>
                    </li>
                    <li>
                        <strong>Visiting our website </strong>
                        <ol>
                            <li>
                            When customers visit our website, we automatically collect certain information about the customer’s device, including information about the customer’s web browser, IP address, time zone, and some of the cookies that are installed on the customer’s device. 
                            </li>
                            <li>
                            Additionally, as the customer browse the site, we collect information about the individual web pages or products that the customer view, what websites or search terms referred the customer to the site, and information about how the customer interact with the site. This is done to better our customer’s browsing experience on our website and we refer to this automatically collected information as “Device Information.” 
                            </li>
                            <li>
                            “Device Information” is collected by using Cookies.  “Cookies” are data files that are placed on your device or computer and often include an anonymous unique identifier.  Cookies are necessary for the functionality of the website. 
                            </li>
                            <li>
                            Please visit http://www.allaboutcookies.org for more information about cookies, and how to disable cookies. 
                            </li>
                        </ol>
                    </li>

                    <li>
                        <strong>Receive marketing material </strong>
                        <ol>
                            <li>
                            We provide marketing material via Facebook, LinkedIn, SMS, Email, and websites.  These notifications are only sent to our customers who consent to receiving such information.  Our customers can opt out of receiving these notifications by clicking on the Opt Out button at any given time. 
                            </li>
                           
                        </ol>
                    </li>

                    <li>
                        <strong>Quality Control Calls </strong>
                        <ol>
                            <li>
                            We are forever striving to provide an exceptional service to our customers, and you may be contacted by our selected service provider to establish whether you have any complaints, queries or compliments relating to the service that we have provided to you. 
                            </li>
                           
                        </ol>
                    </li>
                    
                    <li>
                        <strong>Types of personal data we collect</strong>
                        <ol>
                            <li>
                            While using our service or communicating with our staff, we may ask you to provide us with certain personal data that can be used to contact or identify you. The personal data that we collect from you, may include, but is not limited to:
                            </li>
                           
                        </ol>
                    </li>

                    <ul>
                            <li>
                            Email address 
                            </li>
                            <li>
                            First name and last name
                            </li>
                            <li>
                            Name of legal entity
                            </li>
                            <li>
                            Authorised signatories
                            </li>
                            <li>
                            Occupation
                            </li>
                            <li>
                            Gender
                            </li>
                            <li>
                            Phone number
                            </li>
                            <li>
                            Physical and/or postal Address
                            </li>
                            <li>
                            Usage Data
                            </li>
                            <li>
                            Company Registration Number
                            </li>
                            <li>
                            Company VAT number
                            </li>
                            <li>
                            Background checks
                            </li>
                            <li>
                            Banking Information
                            </li>
                            <li>
                            Tax identification
                            </li>
                            <li>
                            Header and Footer information in email messages 
                            </li>
                            <li>
                            Internet protocol
                            </li>
                            <li>
                            Details of financial institutions
                            </li>
                            <li>
                            Financial services provider registration number
                            </li>
                            <li>
                            Credit provider registration number
                            </li>
                            <li>
                            Record of Education
                            </li>
                        </ul>
                    

                        <li>
                        <strong>Data processing and sharing </strong>
                        <ol>
                            <li>
                            The processing of personal information is carried out using computers and/or IT-enabled tools, following company procedures strictly related to the purposes we have mentioned. In some cases, the  information will be accessible to all persons involved within our operations, including platform providers and their staff. 
                            </li>

                            <li>
                            We may share your personal information in the following situations:
                            </li>
                           
                        </ol>
                    </li>

                    
                    <ul>
                            <li>
                            With Service Providers: we may share your personal data with service providers to monitor and analyse the use of our service, to contact you.
                            </li>
                            <li>
                            For business transfers: we may share or transfer your personal data in connection with, or during negotiations of, any merger, sale of company assets, financing, or acquisition of all or a portion of our business to another company.
                            </li>
                            <li>
                            With Affiliates: we may share your personal data with our affiliates, in which case we will require those affiliates to honour this privacy statement. Affiliates include our parent company and any other subsidiaries, joint venture partners or other companies that we control or that are under common control with us.
                            </li>
                            <li>
                            With business partners: we may share your personal data with our business partners to offer you certain products, services, or promotions.
                            </li>
                            <li>
                            With other users: when you share personal data or otherwise interact in the public areas with other users, such information may be viewed by all users and may be publicly distributed outside. if you interact with other users or register through a third-party social media service, your contacts on the third-party social media service may see your name, profile, pictures, and description of your activity. Similarly, other users will be able to view descriptions of your activity, communicate with you and view your profile.
                            </li>
                            <li>
                            With Your consent: we may disclose your personal data for any other purpose with your consent.
                            </li>
                    </ul>

                    <li>
                        <strong>Data retention </strong>
                        <ol>
                            <li>
                            The personal information collected will be retained for the period necessary for achieving the purposes mentioned above or longer if we are required to do so by law. 
                            </li>

                            <li>
                            We take appropriate security measures to prevent loss of personal information, unauthorised access, disclosure, modification, or unauthorised destruction of the data. We store all the personal information in secured environments. 
                            </li>
                           
                        </ol>
                    </li>
                    
                    <li>
                        <strong>Children’s privacy</strong>
                        <ol>
                            <li>
                            Our service does not address anyone under the age of 18. We do not knowingly collect personal data from anyone under the age of 18. If you are a parent or guardian and you are aware that your child has provided us with personal data, please contact us. If we become aware that we have collected personal data from anyone under the age of 18 without verification of parental consent, we take steps to remove that information from our servers.
                            </li>

                            <li>
                            If we need to rely on consent as a legal basis for processing your information and your country requires consent from a parent, we may require your parent's consent before we collect and use that information.
                            </li>
                           
                        </ol>
                    </li>

                    <li>
                        <strong>Security of your personal information</strong>
                        <ol>
                            <li>
                            The security of your personal data is important to us but remember that no method of transmission over the internet, or method of electronic storage is 100% secure. While we strive to use commercially acceptable means to protect your personal data, we cannot guarantee its absolute security.
                            </li>

                            <li>
                            We will take all appropriate, reasonable, technical, and organisational measures to safeguard your Personal Information to prevent loss of, damage to or unauthorised destruction of your Personal Information or unlawful access to or processing of your Personal Information. [Our technical and organizational personal data security measures include: 
                            </li>

                            <ul>
                            <li>
                        the storage of your personal data under password-secured databases; 
                            </li>

                            <li>
                        the restriction of access to your personal data to specific staff/personnel who are designated and mandated to manage your personal data;  
                            </li>
                            <li>
                        the restriction of access to all our premises where your personal data is stored in physical or digital form; and  
                            </li>
                            <li>
                        the binding of any third parties who handle your personal data to confidentiality obligations with respect to the same.
                            </li>
                        </ul>

                        <li>
                        The Company employs the following security measures to safeguard the data in the possession of or in control of the Company. 
                            </li>
                            
                            
                        <li>
                        firewall software and antivirus software;  
                            </li>

                            
                        <li>
                        SSL/TLS inspection rules;
                            </li>

                            
                        <li>
                        password protection; 
                            </li>

                            
                        <li>
                        access control; 
                            </li>
                        </ol>
                       
                    </li>

                    <li>
                        <strong>Links to other websites</strong>
                        <ol>
                            <li>
                            Links to other websites
                            </li>

                            <li>
                            We have no control over and assume no responsibility for the content, privacy policies or practices of any third-party sites or services
                            </li>
                           
                        </ol>
                    </li>

                    
                    <li>
                        <strong>Our customer’s rights </strong>
                        <ul>
                            <li>
                            We will only use the personal information of our customers for the purposes mentioned under number 2 above.  Our customers have the right to: 
                            </li>

                          
                           
                        </ul>

                        <li>
                        Object to processing of their personal information for any reasons. 
                            </li>
                            <li>
                            Request to review the personal information contained by us at any time. 
                            </li>
                            <li>
                            Request us to update the personal information contained by us at any time. 
                            </li>
                            <li>
                            Request copies of personal information contained by us at any time. 
                            </li>
                            <li>
                            Request us to remove personal information contained by us, which we will do, unless we are legally obligated to retain the information.
                            </li>
                            <li>
                            Submit a complaint to the Information Regulator
                            </li>
                    </li>
                </ol>

                </p>
            </div>
            
            <div>
                <Typography gutterBottom variant="h5" color="textPrimary" fontWeight="500">
                To exercise your rights in terms of paragraph 14 above, please contact: 
                </Typography>
                    <div><>Information Office :</> <> Jaco Taljaard </></div>
                    <div><>Physical address :</> <> C/O Cowen Ntuli & Morkel Street 
	 	 	 	 	 	 	Middelburg 
	 	 	 	 	 	 	1095</></div>
                    <div><>Postal address :</> <> P.O. Box 974 	 	 
	 	 	 	 	 	 	Middelburg 
	 	 	 	 	 	 	1050 </></div>
                    <div><>Telephone number :</> <> (013) 282 4681 </></div>
                    <div><>Email Address :</> <> jacot@eastvaal.co.za  </></div>
                </div>

                <div>
                <Typography gutterBottom variant="h5" color="textPrimary" fontWeight="500">
                To exercise your rights in terms of number 20 above, please contact:
                </Typography>
                    <div><>Information Office :</> <> Jaco Taljaard </></div>
                    <div><>Physical address :</> <> JD House 	 	 	 	 
	 	 	 	 	 	27 Stiemens Street 	 	 	 
	 	 	 	 	 	Johannesburg   	 	 	 
	 	 	 	 	 	2001 </></div>
                    <div><>Postal address :</> <> P.O. Box 31533 
Braamfontein 
Johannesburg 
2017 </></div>
            
                    <div><>Email Address :</> <> inforeg@justice.gov.za </></div>
                    <div><>Website :</> <>  www.justice.gov.za  </></div>
                </div>
                
            <div className={classes.linkHolder}>
                <Typography gutterBottom variant="h5" color="textPrimary" fontWeight="500">
                    Additional Links
                </Typography>
                    <ul>
                        <li>
                            <Link component={RouterLink} to="/requestergws" className={classes.link}>Personal information request form</Link>
                        </li>
                        <li>
                            <Link component={RouterLink} to="/privacy" className={classes.link}>Policies</Link>
                        </li>
                        <li>
                            <Link component={RouterLink} to="/cookies" className={classes.link}>Cookie Policy</Link>
                        </li>
                        <li>
                            <Link component={RouterLink} to="/terms" className={classes.link}>Terms & Conditions</Link>
                        </li>
                    </ul>
                </div>
                <br/>
                
        </Container>
    )
}

export default Terms